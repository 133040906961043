import { isUndefined } from 'lodash';
import axios, { postRequest } from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class OrganizationApi {
  async getAll(
    page: number,
    pageSize: number,
    isActivated: boolean,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/organization?page=${page}&pageSize=${pageSize}${isActivated !== null ? `&isActive=${isActivated}` : ''}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response.data;
            resolve(res);
          } else {
            // reject(new Error(i18next.t("invalid_server_response")));
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async bulkDeactivate(checkedIds: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload: any = {
        list: checkedIds,
      };
      axios
        .patch(
          `${process.env.REACT_APP_ORGANIZATION_URL}/bulk-deactivate`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            // const res = response.data;
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async create(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        // reject(new Error(error));
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/organization`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/organization/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            // const res = response.data;
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getAllProjects(page: number, pageSize: number, search?: string, columns?:string, sortVal?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        page,
        pageSize,
        search,
        column: columns,
        sort: sortVal === 'asc' ? '1' : '-1',
        isVisible: true,
      };
      if (search === '') {
        delete payload.search;
      }
      if (columns === undefined || columns === '') {
        delete payload.column;
        delete payload.sort;
      }

      if (sortVal === '') {
        delete payload.sort;
      }
      console.log('payload', payload);
      axios
        .get(`${process.env.REACT_APP_CASES}/project`, { params: payload })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllProjectbyOrg(id: String, page: number, pageSize: number, search: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/project?organizationId=${id}&page=${page}&pageSize=${pageSize}&name=${search}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async deleteProjByOrg(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/project/projectId?projectId=${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async deleteProjById(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/project/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_CASES}/organization/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateStatusById(id: number) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/organization/${id}/isActive`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            // const res = response.data;
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async deleteOrg(id: String) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_ORGANIZATION_URL}/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            // const res = response.data;
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async createProject(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/project`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOrganizationType(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/organization/type`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getContactsByOrg(orgId: string, isActivated: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/contact?organizationId=${orgId}&${isActivated !== null && `isActive=${isActivated}`}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getOrgSummary(orgId: string,): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/organization/${orgId}/overview`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const organizationApi = new OrganizationApi();
