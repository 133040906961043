// import PDFViewer from 'pdf-viewer-reactjs';
// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import React from 'react';
import Modal from 'react-modal';
import CancelIcon from '@material-ui/icons/Cancel';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

const DocumentViewer = ({ documentUrl, isOpen, onClose }:any) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      width: '50vw',
      zIndex: '1222'
    },
  };

  let primaryColor = '#EE3235';

  const whiteLabel: any = JSON.parse(localStorage.getItem('white_label'));
  primaryColor =
    whiteLabel?.primaryColor?.length > 0 ? whiteLabel?.primaryColor : '#EE3235';

  const closeButtonStyle: any = {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    cursor: 'pointer',
    color: primaryColor,
    zIndex: '122',
  };
  // const docs = [
  //   { uri: 'https://backupdbaccount.blob.core.windows.net/it22-erp-attachments/e9b810d5-a7a3-4285-bcc3-96a22eee70d0.pdf?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-12T22:37:31Z&st=2023-10-12T14:37:31Z&spr=https&sig=tzMKDGsr3kniC9JbeOGcrKn3h98vpbHSUdVshqGJcYg%3D' }, // Remote file
  // ];
  // const headers = {
  //   'X-Access-Token': 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJhMnozbktTOVNlRHEwUjVpaUVsaEhSNzlmZGh4bndITGw2T2hObzRDNlFvIn0.eyJleHAiOjE3MDEwOTU5NDksImlhdCI6MTcwMTA5MjM0OSwianRpIjoiYTllOTBkNTItYjRhZi00MGNmLWJmYjMtMzQxOThmMTBlMWQwIiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay1pdDIyLWxvZ2luLmFwcHMuYWxwaGEuaXQyMi5jbG91ZC9hdXRoL3JlYWxtcy9JbnRlcm5hbC1Vc2VyIiwic3ViIjoiZTYxMjIzMTYtZTJmMS00YmU3LTlmN2ItZWVjODczMDUwNDZkIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiSW50ZXJuYWwtVXNlci1DbGllbnQiLCJzZXNzaW9uX3N0YXRlIjoiYmVlNmNjODYtNGI5YS00NDdlLWFhN2QtOTllMTRmOGEzNzVlIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIqIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJpdDIyX2FkbWluIl19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJzaWQiOiJiZWU2Y2M4Ni00YjlhLTQ0N2UtYWE3ZC05OWUxNGY4YTM3NWUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkphaGFuemViIGFraHRhciBubGQiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJqLmFraHRhckBpdDIyLm5sZCIsImdpdmVuX25hbWUiOiJKYWhhbnplYiBha2h0YXIiLCJmYW1pbHlfbmFtZSI6Im5sZCIsImVtYWlsIjoiai5ha2h0YXJAaXQyMi5ubGQifQ.FYu7_Yi-RZBNL6DSi77Lnr5htomBzq6tr3b1XtNmeWgk6-wUTkwvUJHuboVUhoWUXH-_1-1XqfCNfM2S7ni1FF0bNj961peldL9SvPoN1WqlwgHQjzLpuqOKQGASDLcCjNmfN4KGYDaN3gqWGCTmSfbCXKUnfVxffhPwB6CJEzQS3GVKClQ5e6D9tHwpqAkD3HMNPm9b-KLzK06TJwFrXUO94xqbqiDYqzb7awyznIbHShPlGsS5UrzDJ-k8aHOovuyDJ_BOBvBH8ohUndGtlFxwJih1SngMHaDSvTuR8hRNFwrf8-hu08FN7Scz7W2XU1Gr6H71E-2tqasj9w31wg',

  // };
  const isPdf = documentUrl.includes('.pdf');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
    >
      {/* <PDFViewer
        document={{
          url: documentUrl,
        }}
      /> */}
      <div style={{ position: 'relative', maxHeight: '75vh' }}>
        <CancelIcon
          onClick={onClose}
          style={closeButtonStyle}
        />
        {isPdf ? (
          <iframe
            title="This is a unique title"
            width="100%"
            height="1000px"
            src={documentUrl}
          />
        ) : (
          <>
            <p>This document is not a PDF. You can download it:</p>
            <a href={documentUrl}>Download</a>
          </>
        )}
        {/* <DocViewer
          documents={docs}
          initialActiveDocument={docs[1]}
          pluginRenderers={DocViewerRenderers}
          requestHeaders={headers}
        /> */}

        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer fileUrl="/public/22adeb83-904f-40d5-a227-949a3e0d3949%20(1).pdf" />
      </Worker> */}
      </div>
    </Modal>
  );
};

export default DocumentViewer;
