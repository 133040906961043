import styled from '@emotion/styled';
import { Box, useTheme } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useEffect, useRef, useState } from 'react';
import {
  Menu,
  menuClasses,
  MenuItem,
  MenuItemStyles,
  Sidebar,
} from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useAppDispatch } from 'src/hooks/useRedux';
import AccountIcon from 'src/icons/Account';
import ReportingIcon from 'src/icons/Reporting';
import DashboardIcon from 'src/icons/Dashboard';
import LogoutIcon from 'src/icons/Logout';
import ProjectsIcon from 'src/icons/Projects';
import CasesIcon from 'src/icons/Cases';
import Users from 'src/icons/Users';
import {
  getAllFavouriteProjects,
  getAllProjects,
} from 'src/store/slices/projectsSlice';
import { getAllRoles } from 'src/store/slices/rolesSlice';
import { SidebarHeader } from './sidebarcomponents/SidebarHeader';
import useHasPermissions from 'src/hooks/useHasPermission';
import { APPROVALS, CLIENT_CASES, CLIENT_PROJECTS, CLIENT_USERS, REPORTING } from 'src/constants/constants';

const DashboardSidebar: React.FC = () => {
  const checkPermissions = useHasPermissions();
  const theme = useTheme();
  const StyledSidebar = styled(Sidebar)({
    position: 'relative',
    '& .activeTabIndicator': {
      position: 'absolute',
      left: 0,
      width: 3,
      height: '24px',
      backgroundColor: theme.palette.primary.main,
      transition: 'top 0.5s ease',
    },
  });

  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const { logout } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const itemRefs = useRef([]);
  const [indicatorStyle, setIndicatorStyle] = useState({ top: 0 });
  const dispatch = useAppDispatch();
  const whiteLabel: any = JSON.parse(localStorage.getItem('white_label'));

  const getActiveTabIndex = () => {
    switch (true) {
      case pathname.includes('/dashboard'):
        return 0;
      // case pathname.includes('/analytics'):
      //   return 1;
      case pathname.includes('/projects'):
        return 2;
      case pathname.includes('/cases'):
        return 3;
      // case pathname.includes('/reporting'):
      //   return 4;
      case pathname.includes('/contacts'):
        return 5;
      // case pathname.includes('/settings'):
      //   return 6;
      case pathname.includes('/account'):
        return 9;
      case pathname.includes('/approvals'):
        return 10;
      case pathname.includes('/reporting'):
        return 11;
      default:
        return 0;
    }
  };
  const [activeTabIndex, setActiveTabIndex] = useState(getActiveTabIndex());
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 600,
      marginTop: '10px',
      [`&.${menuClasses.menuItemRoot}`]: {
        marginBottom: '22px',
        color: '#425466',
        padding: '0',
        '& a': {
          height: '24px',
          '&:hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
    icon: {},
    SubMenuExpandIcon: {},

    button: {
      [`&.${menuClasses.active}`]: {
        transition: 'all 0.5s ease',
        color: theme.palette.primary.main,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const menuIconClick = () =>
    collapsed ? setCollapsed(false) : setCollapsed(true);

  useEffect(() => {
    if (itemRefs.current[activeTabIndex]) {
      setIndicatorStyle({ top: itemRefs.current[activeTabIndex].offsetTop });
    }
  }, [activeTabIndex]);

  useEffect(() => {
    setIndicatorStyle({ top: itemRefs.current[getActiveTabIndex()]?.offsetTop });
    setActiveTabIndex(getActiveTabIndex());
  }, [pathname]);

  useEffect(() => {
    dispatch(getAllProjects());
    dispatch(getAllFavouriteProjects());
    dispatch(getAllRoles());
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        position: 'relative',
        '& .closemenu': {
          color: '#425466',
          background: '#FFFFFF',
          position: 'absolute',
          top: '75%',
          right: -20,
          zIndex: 999,
          borderRadius: '50%',
          fontWeight: 'bold',
          fontSize: 22,
          cursor: 'pointer',
        },
      }}>
      <StyledSidebar
        collapsed={collapsed}
        backgroundColor="#FFFFFF"
        transitionDuration={600}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        width="220px"
        breakPoint="md">
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <SidebarHeader style={{ margin: '28px 0' }} collapsed={collapsed} />
          <div style={{ flex: 1, marginBottom: '32px' }}>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                component={<Link to="/dashboard" />}
                onClick={() => setActiveTabIndex(0)}
                ref={(el) => (itemRefs.current[0] = el)}
                icon={
                  <DashboardIcon
                    color={
                      activeTabIndex === 0
                        ? theme.palette.primary.main
                        : undefined
                    }
                  />
                }
                active={activeTabIndex === 0}>
                Dashboard
              </MenuItem>
              {checkPermissions([CLIENT_PROJECTS]) && <MenuItem
                component={<Link to="/projects" />}
                onClick={() => setActiveTabIndex(2)}
                ref={(el) => (itemRefs.current[2] = el)}
                icon={
                  <ProjectsIcon
                    color={
                      activeTabIndex === 2
                        ? theme.palette.primary.main
                        : undefined
                    }
                  />
                }
                active={activeTabIndex === 2}>
                Projects
              </MenuItem>}
              {checkPermissions([CLIENT_CASES]) && <MenuItem
                component={<Link to="/cases" />}
                onClick={() => setActiveTabIndex(3)}
                ref={(el) => (itemRefs.current[3] = el)}
                icon={
                  <CasesIcon
                    color={
                      activeTabIndex === 3
                        ? theme.palette.primary.main
                        : undefined
                    }
                  />
                }
                active={activeTabIndex === 3}>
                Cases
              </MenuItem>}
              {(whiteLabel?.isApprovalRequired && checkPermissions([APPROVALS])) && (
                <MenuItem
                  component={<Link to="/approvals" />}
                  onClick={() => setActiveTabIndex(10)}
                  ref={(el) => (itemRefs.current[10] = el)}
                  icon={
                    <CasesIcon
                      color={
                        activeTabIndex === 10
                          ? theme.palette.primary.main
                          : undefined
                      }
                    />
                  }
                  active={activeTabIndex === 10}>
                  Approvals
                </MenuItem>
              )}
              {checkPermissions([REPORTING]) && <MenuItem
                component={<Link to="/reporting" />}
                onClick={() => setActiveTabIndex(11)}
                ref={(el) => (itemRefs.current[11] = el)}
                icon={
                  <ReportingIcon
                    color={
                      activeTabIndex === 11
                        ? theme.palette.primary.main
                        : undefined
                    }
                  />
                }
                active={activeTabIndex === 11}>
                Reporting
              </MenuItem>}
              {/* <MenuItem
                component={<Link to="/reporting" />}
                icon={
                  <ReportingIcon
                    color={activeTabIndex === 4 ? theme.palette.primary.main : undefined}
                  />
                }
                onClick={() => setActiveTabIndex(4)}
                ref={(el) => (itemRefs.current[4] = el)}
                active={activeTabIndex === 4}>
                Reporting
              </MenuItem> */}
              {checkPermissions([CLIENT_USERS]) && <MenuItem
                component={<Link to="/contacts" />}
                ref={(el) => (itemRefs.current[5] = el)}
                onClick={() => setActiveTabIndex(5)}
                icon={
                  <Users
                    color={
                      activeTabIndex === 5
                        ? theme.palette.primary.main
                        : undefined
                    }
                  />
                }
                active={activeTabIndex === 5}>
                Users
              </MenuItem>}
            </Menu>
            {/* <Divider
              sx={{
                margin: collapsed ? '20px 0' : '20px 30px',
                transition: 'margin ease 0.6s',
              }}
            /> */}

            {/* <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                component={<Link to="/settings" />}
                icon={
                  <SettingsIcon
                    color={activeTabIndex === 6 ? theme.palette.primary.main : undefined}
                  />
                }
                active={activeTabIndex === 6}
                ref={(el) => (itemRefs.current[6] = el)}
                onClick={() => setActiveTabIndex(6)}>
                Settings
              </MenuItem>
              <MenuItem
                icon={<NotificationIcon />}
                suffix={<Badge variant="danger">6</Badge>}>
                Notification
              </MenuItem>
            </Menu> */}
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link to="/account" />}
              ref={(el) => (itemRefs.current[9] = el)}
              onClick={() => setActiveTabIndex(9)}
              active={activeTabIndex === 9}
              icon={
                <AccountIcon
                  color={
                    activeTabIndex === 9
                      ? theme.palette.primary.main
                      : undefined
                  }
                />
              }>
              Account
            </MenuItem>
            <MenuItem icon={<LogoutIcon />} onClick={logout}>
              Logout
            </MenuItem>
          </Menu>
          {/* <SidebarFooter /> */}
        </div>
        <div className="activeTabIndicator" style={indicatorStyle} />
      </StyledSidebar>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="closemenu"
        onClick={menuIconClick}>
        <ChevronRightIcon
          sx={{
            transition: 'transform ease 0.6s',
            transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      </Box>
      {/* <main>
        <div style={{ padding: '16px 24px', color: '#44596e' }}>
          <div style={{ padding: '0 8px' }}>
            <div style={{ marginBottom: 16 }}>
              <Switch
                id="collapse"
                checked={collapsed}
                onChange={() => setCollapsed(!collapsed)}
                label="Collapse"
              />
            </div>
          </div>
        </div>
      </main> */}
    </Box>
  );
};

export default DashboardSidebar;
