import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class DashboardApi {
  async getUpcomingMeetings(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/upcoming/meetings`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getUpcomingEvents(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/upcoming/events`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCasesData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/status/count`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getRecentCases(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/recent/cases`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTotalOrganizationsData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/organization/records`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCasesCountNPercentage(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/cases/weeklyCount`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCasesCountNPercentageV3(val: any): Promise<any> {
    let url = `${process.env.REACT_APP_CASES}/dashboard/cases/weeklyCount/v3`;

    if (Object.keys(val).length > 0) {
      url += `?from=${new Date(val.startDate).toISOString().split('T')[0]}&to=${
        new Date(val.endDate).toISOString().split('T')[0]
      }`;
    } else {
      url += ``;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const parsedResponse = parse(response);
          if (!isUndefined(parsedResponse?.data)) {
            resolve(parsedResponse);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getPreviousSprintData(): Promise<any> {
    const url = `${process.env.REACT_APP_CASES}/dashboard/cases/overAll`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const parsedResponse = parse(response);
          if (!isUndefined(parsedResponse?.data)) {
            resolve(parsedResponse);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getDailyCasesCountV3(val: any): Promise<any> {
    let isOverAll = true;
    let url = `${process.env.REACT_APP_CASES}/dashboard/cases/dailyCount/v3`;

    if (Object.keys(val).length > 0) {
      isOverAll = false;
      url += `?from=${val.startDate}&to=${val.endDate}&isOverAll=${isOverAll}`;
    } else {
      url += `?isOverAll=${isOverAll}`;
    }
    console.log('url', url);
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const parsedResponse = parse(response);
          if (!isUndefined(parsedResponse?.data)) {
            resolve(parsedResponse);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // async getDailyCasesCount(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${process.env.REACT_APP_CASES}/dashboard/cases/dailyCount`)
  //       .then((response) => {
  //         response = parse(response);
  //         if (!isUndefined(response?.data)) {
  //           resolve(response);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  async getTotalCasesCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/cases/totalCount`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getDailyCasesCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/cases/dailyCount`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async exportDailyCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/dashboard/cases/exportDailyCount`,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const dashboardApi = new DashboardApi();
