import { Box } from '@material-ui/core';
import React from 'react';
import toast from 'react-hot-toast';
import { userApi } from 'src/api/userApi';
import useAuth from 'src/hooks/useAuth';

export const IsActive = ({ rowDataObj, isActive, getContacts }: { isActive: boolean, rowDataObj: any, getContacts: any }) => {
  const { user } = useAuth();
  const handleIsActive = async (e) => {
    e.stopPropagation();
    try {
      if (rowDataObj.id !== user.id) {
        await userApi.updateStatus(rowDataObj.id, !isActive);
        toast.success("Updated Successfully!")
        getContacts()
      }
      else {
        toast.error("User Cannot Disable Itself")
        return;
      }
    } catch (error) {
      toast.error(error?.res?.data?.message || "Something Went wrong!")
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <Box
        style={{
          height: '10px',
          width: '10px',
          borderRadius: '50%',
          backgroundColor: isActive ? '#4AAE8C' : '#DB3737',
          marginRight: '10px',
        }}
      />
      <Box
        sx={{
          cursor: 'pointer',
          display: "inline-block",

        }}
        onClick={handleIsActive}
      >
        {isActive ? 'Active' : 'Inactive'}
      </Box>
    </Box >
  )
};
