const FilledStart = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.61077 15.4427C3.22433 15.6413 2.78677 15.2942 2.86457 14.8508L3.69429 10.1213L0.171963 6.76462C-0.157217 6.45092 0.0131827 5.87737 0.454172 5.81472L5.35265 5.11885L7.53677 0.792305C7.73352 0.402565 8.26648 0.402565 8.46323 0.792305L10.6474 5.11885L15.5458 5.81472C15.9868 5.87737 16.1572 6.45092 15.828 6.76462L12.3057 10.1213L13.1354 14.8508C13.2132 15.2942 12.7757 15.6413 12.3892 15.4427L8 13.1868L3.61077 15.4427Z"
      fill="#8492A6"
    />
  </svg>
);

export default FilledStart;
