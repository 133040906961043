export const SortZtoA = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.7749 12C2.49876 12 2.2749 11.7761 2.2749 11.5L2.2749 0.5C2.2749 0.223858 2.49876 2.30627e-07 2.7749 2.18557e-07C3.05105 2.06486e-07 3.2749 0.223858 3.2749 0.5L3.2749 11.5C3.2749 11.7761 3.05105 12 2.7749 12Z"
      fill="#8492A6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.421349 2.85355C0.226087 2.65829 0.226087 2.34171 0.421349 2.14645L2.42135 0.146447C2.61661 -0.0488148 2.93319 -0.0488149 3.12846 0.146447L5.12846 2.14645C5.32372 2.34171 5.32372 2.65829 5.12846 2.85355C4.93319 3.04882 4.61661 3.04882 4.42135 2.85355L2.7749 1.20711L1.12846 2.85355C0.933194 3.04882 0.616611 3.04882 0.421349 2.85355Z"
      fill="#8492A6"
    />
    <path
      d="M8.4389 11.6641L8.85687 10.293H10.6381L11.0561 11.6641H12.1772L10.3959 6.33203H9.16156L7.3725 11.6641H8.4389ZM9.77484 7.35156L10.4272 9.50781H9.07562L9.72797 7.35156H9.77484Z"
      fill="#8492A6"
    />
    <path
      d="M7.80219 5.33203H11.7358V4.46484H9.09125V4.41016L11.6577 0.691406V0H7.8725V0.867187H10.3803V0.921875L7.80219 4.64062V5.33203Z"
      fill="#8492A6"
    />
  </svg>
);
