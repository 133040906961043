import { Navigate } from 'react-router-dom';
import React from 'react';
import useHasPermissions from 'src/hooks/useHasPermission';

interface PermissionGuardProps {
    hasPermission: string;
    children: React.ReactNode;
}

const PermissionGuard: React.FC<PermissionGuardProps> = ({ hasPermission, children }) => {
    const checkPermissions = useHasPermissions()

    if (!checkPermissions([hasPermission])) {
        return <Navigate to="/404" />; // Redirect if permission is denied
    }

    return <>{children}</>;  // Render children if permission is granted
};

export default PermissionGuard;
