import React from 'react';

export const ClearIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.81816 5.45459C3.6735 5.45459 3.53476 5.51206 3.43247 5.61435C3.33017 5.71664 3.27271 5.85538 3.27271 6.00004C3.27271 6.14471 3.33017 6.28345 3.43247 6.38574C3.53476 6.48803 3.6735 6.5455 3.81816 6.5455H8.1818C8.32646 6.5455 8.4652 6.48803 8.56749 6.38574C8.66978 6.28345 8.72725 6.14471 8.72725 6.00004C8.72725 5.85538 8.66978 5.71664 8.56749 5.61435C8.4652 5.51206 8.32646 5.45459 8.1818 5.45459H3.81816Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12 9.31364 9.31364 12 6 12C2.68636 12 0 9.31364 0 6C0 2.68636 2.68636 0 6 0C9.31364 0 12 2.68636 12 6ZM10.9091 6C10.9091 6.64467 10.7821 7.28303 10.5354 7.87863C10.2887 8.47423 9.9271 9.0154 9.47125 9.47125C9.0154 9.9271 8.47423 10.2887 7.87863 10.5354C7.28303 10.7821 6.64467 10.9091 6 10.9091C5.35533 10.9091 4.71697 10.7821 4.12137 10.5354C3.52577 10.2887 2.9846 9.9271 2.52875 9.47125C2.0729 9.0154 1.7113 8.47423 1.46459 7.87863C1.21789 7.28303 1.09091 6.64467 1.09091 6C1.09091 4.69803 1.60812 3.44938 2.52875 2.52875C3.44938 1.60812 4.69803 1.09091 6 1.09091C7.30197 1.09091 8.55062 1.60812 9.47125 2.52875C10.3919 3.44938 10.9091 4.69803 10.9091 6Z"
      fill="black"
    />
  </svg>
);
