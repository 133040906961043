const ProjectsIcon = ({ color = '#A0AEC0' }: { color: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.49967C0 0.855674 0.522667 0.333008 1.16667 0.333008H12.1667C12.8107 0.333008 13.3333 0.855674 13.3333 1.49967V12.4997C13.3333 12.8091 13.2104 13.1058 12.9916 13.3246C12.7728 13.5434 12.4761 13.6663 12.1667 13.6663H1.16667C0.857247 13.6663 0.560501 13.5434 0.341709 13.3246C0.122916 13.1058 0 12.8091 0 12.4997V1.49967ZM1.16667 1.33301C1.12246 1.33301 1.08007 1.35057 1.04882 1.38182C1.01756 1.41308 1 1.45547 1 1.49967V12.4997C1 12.5917 1.07467 12.6663 1.16667 12.6663H12.1667C12.2109 12.6663 12.2533 12.6488 12.2845 12.6175C12.3158 12.5863 12.3333 12.5439 12.3333 12.4997V1.49967C12.3333 1.45547 12.3158 1.41308 12.2845 1.38182C12.2533 1.35057 12.2109 1.33301 12.1667 1.33301H1.16667Z"
      fill={color}
    />
    <path
      d="M4.49996 3.66666C4.36735 3.66666 4.24017 3.71934 4.1464 3.81311C4.05264 3.90688 3.99996 4.03406 3.99996 4.16666C3.99996 4.29927 4.05264 4.42645 4.1464 4.52022C4.24017 4.61399 4.36735 4.66666 4.49996 4.66666H9.49996C9.63256 4.66666 9.75974 4.61399 9.85351 4.52022C9.94728 4.42645 9.99996 4.29927 9.99996 4.16666C9.99996 4.03406 9.94728 3.90688 9.85351 3.81311C9.75974 3.71934 9.63256 3.66666 9.49996 3.66666H4.49996ZM3.33329 6.83333C3.33329 6.70072 3.38597 6.57355 3.47974 6.47978C3.5735 6.38601 3.70068 6.33333 3.83329 6.33333H8.16662C8.29923 6.33333 8.42641 6.38601 8.52018 6.47978C8.61394 6.57355 8.66662 6.70072 8.66662 6.83333C8.66662 6.96594 8.61394 7.09312 8.52018 7.18688C8.42641 7.28065 8.29923 7.33333 8.16662 7.33333H3.83329C3.70068 7.33333 3.5735 7.28065 3.47974 7.18688C3.38597 7.09312 3.33329 6.96594 3.33329 6.83333ZM5.16662 9C5.03401 9 4.90684 9.05268 4.81307 9.14644C4.7193 9.24021 4.66662 9.36739 4.66662 9.5C4.66662 9.63261 4.7193 9.75978 4.81307 9.85355C4.90684 9.94732 5.03401 10 5.16662 10H9.49996C9.63256 10 9.75974 9.94732 9.85351 9.85355C9.94728 9.75978 9.99996 9.63261 9.99996 9.5C9.99996 9.36739 9.94728 9.24021 9.85351 9.14644C9.75974 9.05268 9.63256 9 9.49996 9H5.16662Z"
      fill={color}
    />
  </svg>
);

export default ProjectsIcon;
