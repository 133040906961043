import * as React from 'react';
// import Box from '@mui/material/Box';
import type { DropzoneOptions, FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Box, Button } from '@material-ui/core';
import Upload from 'src/icons/Upload';
import useGenericStyles from 'src/styles/useGenericStyles';

export type File = FileWithPath;

export interface FileDropzoneProps extends DropzoneOptions {
  caption?: string;
  files?: File[];
  onRemove?: (file: File) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
}

export function FileDropzone({
  caption,
  ...props
}: FileDropzoneProps): React.JSX.Element {
  const { getRootProps, getInputProps } = useDropzone(props);
  const classes = useGenericStyles();

  return (
    <Box
      sx={{
        alignItems: 'center',
        border: '1px dashed var(--mui-palette-divider)',
        cursor: 'pointer',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        outline: 'none',
        // p: 6,
        borderRadius: '50%',
        // width: '100%',
        // aspectRatio: 1,
      }}
      {...getRootProps()}>
      <input {...getInputProps()} />
      <Button className={classes.button} startIcon={<Upload />}>
        Upload
      </Button>
    </Box>
  );
}
