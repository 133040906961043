import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  TextField
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EyeIcon from 'src/icons/Eye';
import EyeOffIcon from 'src/icons/EyeOff';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { useTranslation } from 'react-i18next';

const LoginJWT: FC = (props) => {
  const mounted = useMounted();
  const { login } = useAuth() as any;
  const { t } = useTranslation(['toasts', 'login']);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const useStyles = makeStyles<Theme>(() =>
    createStyles({
      formLogin: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '9px',
        },
        '& button': {
          width: '10rem',
          margin: '40px auto',
          display: 'block',
          borderRadius: '9px',
          height: '49px',
        },
        '& .MuiIconButton-root': {
          width: 'fit-content',
        },
      },
      checkboxRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      },
      checkboxLabel: {
        fontSize: '16px',
        fontWeight: '400px',
      },
    })
  );
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required(t('login:login_required_email'))
          .email(t('login:login_invalid_email')),
        password: Yup.string()
          .required(t('login:login_password_required'))
          .min(2, t('login:login_password_min_chars'))
          .max(255, t('login:login_password_max_chars')),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
          className={classes.formLogin}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t('login:login_email_address')}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            // type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t('login:login_password')}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              classes: {
                root: classes.readOnly,
              },
              readOnly: false,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <div className={classes.checkboxRoot}>
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/forgot-password"
                variant="body2"
              >
                {t('login:login_forgot_password')}
              </Link>
            </div>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('login:login_log_in')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
