import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { userApi } from 'src/api/userApi';

interface DeclineDialogProps {
  open: boolean;
  onClose: () => void;
  onDecline: (reason: string) => void;
}

const DeclineDialog: React.FC<DeclineDialogProps> = ({
  open,
  onClose,
  onDecline,
}) => {
  const [reason, setReason] = useState<string>('');

  const handleDecline = () => {
    onDecline(reason); // Pass the reason for declining to the parent component
    setReason(''); // Clear the editor after submitting
  };

  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const image = await userApi.uploadAttachments(formData);
            resolve({
              default: `${image?.data?.blob_url}`,
            });
          });
        }),
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      uploadAdapter(loader);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to decline this case? Please provide a reason for declining:
        </DialogContentText>
        <div data-color-mode="light" className="editorMarkup">
          <CKEditor
            editor={ClassicEditor}
            data={reason}
            onChange={(event, editor) => {
              const data = editor.getData();
              setReason(data);
            }}
            config={{
              placeholder: 'Enter your reason for declining',
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'blockQuote',
                'uploadImage',
              ],
              extraPlugins: [uploadPlugin],
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDecline} color="primary" disabled={!reason}>
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeclineDialog;
