import React, { useEffect, useState, CSSProperties } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import axios from 'axios'; // Import Axios
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import toast from 'react-hot-toast';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

interface FileUploadDragDropProps {
  // Define any props your component might accept here
  setFilePath?: any
  filePath?: any
  key?: any
}

// Define types for styles as CSSProperties
const thumbsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};
const crossBtn: CSSProperties = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
};
const thumb: CSSProperties = {
  display: 'inline-flex',
  borderRadius: 4,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  padding: 10,
  boxSizing: 'border-box',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center'
};

const thumbInner: CSSProperties = {
  // display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img: CSSProperties = {
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  objectFit: 'cover',
};

const FileUploadDragDrop: React.FC<FileUploadDragDropProps> = ({ setFilePath, filePath, key }: FileUploadDragDropProps) => {
  const [files, setFiles] = useState<any>([]);
  const theme = useTheme();
  const { t } = useTranslation(['cases', 'toasts']);
  const isPdfTxtDocFile = (fileName) => fileName.toLowerCase().endsWith('.pdf') || fileName.toLowerCase().endsWith('.txt') || fileName.toLowerCase().endsWith('.doc') || fileName.toLowerCase().endsWith('.docx');
  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    axios
      .post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        },
      })
      .then((response) => {
        if (response?.status === 201) {
          toast.success(t('toasts:case_attachment_uploaded_successfully'));
          setFilePath((prev) => [...prev, `${response?.data?.blob_url}`]);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  function customValidator(file) {
    if (file.size >= 2097152) {
      return {
        code: 'file_size_too_large',
        message: t('cases_create_attachment_uploadFile_rejectedFile_sizeError')
      };
    }
    if (files.find((itm) => (itm?.name === file?.name) && (itm?.path === file?.path) && (itm?.type === file?.type) && (itm?.size === file?.size))) {
      return {
        code: 'file_already_present',
        message: t('cases_create_attachment_uploadFile_rejectedFile_duplicateError'),
      };
    }
    return null;
  }
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png, .pdf, .doc, .txt, .docx, .gif, .bmp, .tiff, .webp, .svg, .ico, .txt',
    onDrop: (acceptedFiles) => {
      const filesWithPreviews = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file), id: file?.size }));
      setFiles([...files, ...filesWithPreviews]);
      acceptedFiles.forEach((file) => {
        uploadImage(file);
      });
    },
    validator: customValidator
  });
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <>
      <div key={file.name}>
        <h6 style={{ margin: '10px 0px 0px 0px', }}>
          {t('cases_create_attachment_uploadFile_rejectedFile_fileName_label')}
          :
          {' '}
          {file?.name}
        </h6>
        {errors.map((e) => (
          <p
            key={e.code}
            style={{ margin: '0px', }}
          >
            {e.message}
          </p>
        ))}
      </div>
    </>
  ));
  useEffect(() =>
  // eslint-disable-next-line implicit-arrow-linebreak
    () => files.forEach((file) => URL.revokeObjectURL(file.preview)), [files, filePath]);
  const removeImage = (file: any, indexToRemove: string) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
    const newFilePath = [...filePath.slice(0, indexToRemove), ...filePath.slice(indexToRemove + 1)];
    setFilePath(newFilePath);
    URL.revokeObjectURL(file.preview);
  };
  return (
    <section className="fileDropZoneRoot">
      <div
        {...(getRootProps({ className: 'dropzone' }) as DropzoneRootProps)}
        style={{ cursor: 'pointer' }}>
        <input {...(getInputProps() as DropzoneInputProps)} />
        <p style={{ fontSize: 14 }}>
          Drag & Drop, paste, or browse to upload files
        </p>
      </div>
      <aside style={thumbsContainer}>
        {fileRejections?.length !== 0 && (
          <>
            <div style={{ display: 'block', marginBottom: '12px' }}>
              <h4 style={{ margin: '0px', color: theme.palette.primary.main }}>
                {t('cases_create_attachment_uploadFile_rejectedFiles_label')}
              </h4>
              {fileRejectionItems}
            </div>
          </>
        )}
        {files.map((file, index) => (
          <div style={thumb} key={key || file.name}>
            <div style={thumbInner}>
              {isPdfTxtDocFile(file.name) ? (
                <InsertDriveFileIcon sx={{ fontSize: '50px' }} />
              ) : (
                <img
                  src={file.preview}
                  alt=""
                  style={img}
                  // Revoke data URI after the image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              )}
            </div>
            <CancelIcon
              onClick={() => removeImage(file, index)}
              style={crossBtn}
            />
          </div>
        ))}
      </aside>
    </section>
  );
};

export default FileUploadDragDrop;
