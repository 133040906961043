import {
  Box,
  Grid,
  Typography,
  TextField,
  // Select,
  // MenuItem,
  // CircularProgress,
  Autocomplete,
} from '@material-ui/core';
import React from 'react';
import useGenericStyles from 'src/styles/useGenericStyles';

interface FieldObj {
  label: string;
  name: string;
  placeholder: string;
  xs?: any;
  type: string;
  options?: Array<any>;
  loading?: boolean;
  limitTags?: number;
}

export const AccountInformationForm = ({
  formFields,
  rootStyles = {},
  formikValues,
  formikErrors,
  handleChange,
  setFieldValue,
}: {
  formFields: Array<FieldObj>;
  formikValues: any;
  handleChange: (newValue: any) => void;
  formikErrors: any;
  rootStyles?: any;
  setFieldValue: (fieldName: any, value: any) => void;
}) => {
  const classes = useGenericStyles();
  return (
    <Box
      sx={{
        margin: '40px 0',
        ...rootStyles,
      }}>
      <Grid container spacing={3}>
        {formFields.map((fieldObj) =>
          fieldObj.type === 'text' ? (
            <Grid item xs={fieldObj.xs ?? 6}>
              <Typography className={classes.inputLabel}>
                {fieldObj.label}
              </Typography>
              <TextField
                fullWidth
                name={fieldObj.name}
                value={formikValues[fieldObj.name]}
                onChange={handleChange}
                error={formikErrors[fieldObj.name]}
                helperText={formikErrors[fieldObj.name] ?? null}
                placeholder={fieldObj.placeholder}
                InputProps={{
                  classes: {
                    root: classes.inputField,
                    input: classes.placeholder,
                  },
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography className={classes.inputLabel}>
                {fieldObj.label}
              </Typography>

              <Autocomplete
                id={fieldObj.name}
                options={fieldObj.options}
                value={formikValues[fieldObj.name]}
                multiple
                limitTags={fieldObj.limitTags || -1}
                getOptionLabel={(option) =>
                  Object.keys(option).length !== 0 && option?.name
                }
                className={classes.autoComplete}
                onChange={(e, value) => setFieldValue(fieldObj.name, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={fieldObj.name}
                    error={formikErrors[fieldObj.name]}
                    helperText={formikErrors[fieldObj.name] ?? null}
                  />
                )}
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};
