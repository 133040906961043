import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HideMenuType {
  hideMenuItem: any;
}
const initialState: HideMenuType = {
  hideMenuItem: false
};
const counterSlice = createSlice({
  name: 'hideMenu',
  initialState,
  reducers: {
    hide: (state:any, action: PayloadAction<any>) => {
      state.hideMenuItem = action.payload;
      return state;
    }
  }
});

export const { hide } = counterSlice.actions;
export default counterSlice.reducer;
