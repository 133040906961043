import { isUndefined } from 'lodash';
import { getRequest, patchRequest, postRequest, updateRequest } from 'src/utils/axios';
import parse from 'src/utils/parse';
import logger from 'src/utils/logger';
import { ModuleUpdateByIdPayloadTypes } from 'src/types/settings/modules/edit.types';

class RolesApi {
  async create(payload:any):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async update(payload:any, id:any):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role/${id}`,
        payload,
        successFunction,
        errorFunction,
      };
      updateRequest(data);
    });
  }

  async getAll(
    page: number,
    pageSize: number,
    isActive:boolean | null,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role?page=${page}&pageSize=${pageSize}${isActive !== null ? `&isActive=${isActive}` : ''}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getById(
    id:string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role/${id}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async autoComplete(search:string):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      let queryParams = '';
      if (search && search !== '' && search !== null && search !== undefined) {
        queryParams = `?search=${search}`;
      }
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role/auto-complete${queryParams}`,
        successFunction,
        errorFunction
      };
      getRequest(data);
    });
  }

  async updateStatusById(id:string):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role/${id}/toggle`,
        successFunction,
        errorFunction
      };
      patchRequest(data);
    });
  }

  async updateById(id:string, payload:ModuleUpdateByIdPayloadTypes):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role/${id}`,
        payload,
        successFunction,
        errorFunction
      };
      updateRequest(data);
    });
  }

  async getAllRolesAutoComplete(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/auto-complete/roles`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getAllActiveRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_PERMISSIONS}/role/active?isExternal=true`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }
}

export const rolesApi = new RolesApi();
