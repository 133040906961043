import styled from '@emotion/styled';
import React from 'react';
import Logo from 'src/components/Logo';

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed: boolean;
}

const StyledSidebarHeader = styled.div<SidebarHeaderProps>`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  transition: all ease 0.5s;

  padding: ${({ collapsed }) => (collapsed ? '0 10px' : '0 30px')};

  > div {
    width: 100%;
    overflow: hidden;
  }

  > img {
    transition: height ease 1s;
  }
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  children,
  collapsed,
  ...rest
}) => (
  <StyledSidebarHeader collapsed={collapsed} {...rest}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: collapsed ? '47' : '55',
        width: collapsed ? '50' : '60',
      }}>
      <Logo />
    </div>
  </StyledSidebarHeader>
);
