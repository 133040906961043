const Users = ({ color = '#A0AEC0' }: { color: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 12C15 12 16 12 16 11C16 10 15 7 11 7C7 7 6 10 6 11C6 12 7 12 7 12H15ZM7.02235 11H14.9777C14.9811 10.9996 14.986 10.999 14.9919 10.998C14.9946 10.9975 14.9973 10.997 15 10.9965C14.9985 10.7325 14.8331 9.96691 14.2407 9.27579C13.6867 8.62936 12.7173 8 11 8C9.28269 8 8.31334 8.62936 7.75926 9.27579C7.16687 9.96691 7.00146 10.7325 7.00001 10.9965C7.00266 10.997 7.00538 10.9975 7.00815 10.998C7.01403 10.999 7.01888 10.9996 7.02235 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 5C12.1046 5 13 4.10457 13 3C13 1.89543 12.1046 1 11 1C9.89543 1 9 1.89543 9 3C9 4.10457 9.89543 5 11 5ZM14 3C14 4.65685 12.6569 6 11 6C9.34315 6 8 4.65685 8 3C8 1.34315 9.34315 0 11 0C12.6569 0 14 1.34315 14 3Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93593 7.27996C6.56813 7.16232 6.15954 7.07679 5.70628 7.03306C5.48195 7.01141 5.24668 7 5 7C1 7 0 10 0 11C0 11.6667 0.333333 12 1 12H5.21636C5.07556 11.7159 5 11.3791 5 11C5 9.98971 5.37724 8.95802 6.08982 8.09619C6.33327 7.80174 6.61587 7.52713 6.93593 7.27996ZM4.92004 8.00046C3.25249 8.01967 2.30468 8.63946 1.75926 9.27579C1.16424 9.96997 1 10.7393 1 11H4C4 9.95468 4.32256 8.91361 4.92004 8.00046Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3.5C1.5 1.84315 2.84315 0.5 4.5 0.5C6.15685 0.5 7.5 1.84315 7.5 3.5C7.5 5.15685 6.15685 6.5 4.5 6.5C2.84315 6.5 1.5 5.15685 1.5 3.5ZM4.5 1.5C3.39543 1.5 2.5 2.39543 2.5 3.5C2.5 4.60457 3.39543 5.5 4.5 5.5C5.60457 5.5 6.5 4.60457 6.5 3.5C6.5 2.39543 5.60457 1.5 4.5 1.5Z"
      fill={color}
    />
  </svg>
);

export default Users;
