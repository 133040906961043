import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import HelpLayoutSidebar from './HelpLayoutSidebar';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const HelpLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const HelpLayoutWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '76px',
  // marginTop: '100px',
  backgroundColor: '#E8EDF2',
}));

const HelpLayoutContainer = experimentalStyled('div')({
  margin: '30px 30px',
  padding: '30px 30px',
  paddingTop: '0',
  overflow: 'auto',
  backgroundColor: '#fff',
  width: '100%',
  borderRadius: '4px',
});

// const DashboardLayoutContent = experimentalStyled('div')({
//   flex: '1 1 auto',
//   height: '100%',
//   overflow: 'auto',
//   position: 'relative',
//   WebkitOverflowScrolling: 'touch',
// });

const HelpLayout: FC<DashboardLayoutProps> = () => (
  <HelpLayoutRoot>
    <DashboardNavbar />
    <HelpLayoutWrapper>
      {/* <DashboardLayoutContainer>
        <DashboardLayoutContent> */}
      {/* <h1>outlet</h1> */}
      <HelpLayoutSidebar />
      <HelpLayoutContainer><Outlet /></HelpLayoutContainer>
      {/* </DashboardLayoutContent>
      </DashboardLayoutContainer> */}
    </HelpLayoutWrapper>
  </HelpLayoutRoot>
);
export default HelpLayout;
