const LogoutIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.5C6 10.7761 6.22386 11 6.5 11H14.5C14.7761 11 15 10.7761 15 10.5V1.5C15 1.22386 14.7761 1 14.5 1L6.5 1C6.22386 1 6 1.22386 6 1.5L6 3.5C6 3.77614 5.77614 4 5.5 4C5.22386 4 5 3.77614 5 3.5V1.5C5 0.671573 5.67157 3.85903e-07 6.5 3.49692e-07L14.5 0C15.3284 -3.62117e-08 16 0.671573 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12H6.5C5.67157 12 5 11.3284 5 10.5V8.5C5 8.22386 5.22386 8 5.5 8C5.77614 8 6 8.22386 6 8.5V10.5Z"
      fill="#A0AEC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146447 6.35355C-0.0488155 6.15829 -0.0488155 5.84171 0.146447 5.64645L3.14645 2.64645C3.34171 2.45118 3.65829 2.45118 3.85355 2.64645C4.04882 2.84171 4.04882 3.15829 3.85355 3.35355L1.70711 5.5H10.5C10.7761 5.5 11 5.72386 11 6C11 6.27614 10.7761 6.5 10.5 6.5H1.70711L3.85355 8.64645C4.04882 8.84171 4.04882 9.15829 3.85355 9.35355C3.65829 9.54881 3.34171 9.54881 3.14645 9.35355L0.146447 6.35355Z"
      fill="#A0AEC0"
    />
  </svg>
);

export default LogoutIcon;
