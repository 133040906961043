const ReportingIcon = ({ color = '#A0AEC0' }: { color: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4444 7.66755e-08C12.8369 -0.000122031 13.2149 0.145605 13.5026 0.407968C13.7904 0.670332 13.9667 1.02994 13.9961 1.41471L14 1.52941V11.4706C14.0001 11.8564 13.8519 12.2281 13.5851 12.511C13.3182 12.7939 12.9525 12.9672 12.5611 12.9962L12.4444 13H1.55556C1.16311 13.0001 0.785115 12.8544 0.497352 12.592C0.209588 12.3297 0.0333227 11.9701 0.00388906 11.5853L7.79862e-08 11.4706V1.52941C-0.000124117 1.14356 0.148094 0.77192 0.414942 0.488993C0.68179 0.206066 1.04755 0.0327626 1.43889 0.00382369L1.55556 7.66755e-08H12.4444ZM3.88889 9.17647H1.55556V11.4706H3.88889V9.17647ZM12.4444 9.17647H5.44444V11.4706H12.4444V9.17647ZM3.88889 5.35294H1.55556V7.64706H3.88889V5.35294ZM12.4444 5.35294H5.44444V7.64706H12.4444V5.35294ZM3.88889 1.52941H1.55556V3.82353H3.88889V1.52941ZM12.4444 1.52941H5.44444V3.82353H12.4444V1.52941Z"
      fill={color}
    />
  </svg>
);

export default ReportingIcon;
