import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import setTitle from 'src/utils/setTitle';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
// import { employeesApi } from 'src/api/employeesApi';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import EyeIcon from 'src/icons/Eye';
import EyeOffIcon from 'src/icons/EyeOff';
import logger from 'src/utils/logger';
import * as Yup from 'yup';
import useSettings from 'src/hooks/useSettings';
import gtm from 'src/lib/gtm';
import { userApi } from 'src/api/userApi';
import useAuth from 'src/hooks/useAuth';
import { contactsApi } from 'src/api/contactsApi';
import { rolesApi } from 'src/api/rolesApi';
import { useTranslation } from 'react-i18next';
import { REGEX } from 'src/utils/constants';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: '#fff',
      color: '#black',
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },

    cardRoot: {
      margin: '27px 0 0px 0',
    },
    btnContainer: {
      paddingTop: '24px',
      paddingRight: '1rem',
      paddingBottom: '0',
      width: '100%',
      display: 'flex',
      gap: '10px',
      flexDirection: 'row',
    },
    loadingCircle: {
      color: theme.palette.primary.main,
      width: '40px !important',
      height: '40px !important',
      marginLeft: 5,
      marginRight: 5,
    },
    loadingWrapper: {
      textAlign: 'center',
    },
    imageUploadAvatar: {
      width: '50px',
      height: '50px',
    },
    imageUploadButton: {
      marginLeft: 10,
    },
  })
);
const Account: FC = () => {
  setTitle('ITSM | Account');
  const { t } = useTranslation('accountInfo');
  const { settings } = useSettings();
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  const { user } = useAuth();
  const handlePicture = async (e) => {
    try {
      setIsSubmitting(true);
      const fileType = e.target.files[0].type;
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        toast.dismiss();
        toast.error('Invalid File Type');
        setIsSubmitting(false);
        return null;
      }
      const image = await userApi.uploadFile(e.target.files[0], 'user');
      setIsSubmitting(false);
      const msg = 'Image Upload Successfully';
      toast.dismiss();
      toast.success(msg, {
        duration: 10000,
      });
      return image;
    } catch (err) {
      setIsSubmitting(false);
      logger(err, 'error');
      const errorMsg =
        err?.response?.status === 400
          ? 'Invalid File Type'
          : 'File Size Exceeded';
      toast.dismiss();
      toast.error(errorMsg, {
        duration: 10000,
      });
      return ''; // <--- cheapi
    }
  };
  const getAllRoles = async () => {
    try {
      const response = await rolesApi.getAllActiveRoles();
      if (response?.status === 200) {
        setRoles(response?.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      setRoles([]);
    }
  };
  const [showPassword, setShowPassword] = useState<Array<boolean>>([
    false,
    false,
    false,
  ]);
  const handleClickShowPassword = (psType: number) => {
    setShowPassword((prev) => {
      const arrCopy = [...showPassword];
      arrCopy[psType] = !prev[psType];
      return arrCopy;
      // return [...prev, prev[psType]: !prev[psType]]
    });
  };
  const INITIAL_VALUES = {
    name: '',
    firstName: '',
    lastName: '',
    designation: '',
    roles: [],
    email: '',
    contactNumber: '',
    avatar: '',
    info: '',
  };

  const [employeeData, setEmployeeData] = useState(INITIAL_VALUES);

  const handleOnSubmit = async (values: any) => {
    try {
      const filter = {
        name: values?.userName,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        avatar: values?.image,
        info: values?.description,
        contactNumber: values?.phoneNumber,
        oldPassword: values?.oldPassword.trim(),
        password: values?.confirmNewPassword.trim(),
        designation: values?.designation.trim(),
      };
      const response = await contactsApi.updateByIdWithPassword(filter);
      if (response?.status === 200) {
        toast.success('updated successfully');
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(`${error?.response?.data?.message}`);
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } else if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getLoggedInUserData = async () => {
    try {
      const response = await contactsApi.getById(user?.id);
      if (response?.status === 200) {
        setEmployeeData(response?.data);
      }
    } catch (error) {
      logger(error, 'error');
    }
  };

  useEffect(() => {
    getLoggedInUserData();
    getAllRoles();
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Formik
            enableReinitialize
            initialValues={{
              userName: employeeData?.name || '',
              firstName: employeeData?.firstName || '',
              lastName: employeeData?.lastName || '',
              designation: employeeData?.designation || '',
              role: employeeData?.roles || [],
              email: employeeData?.email || '',
              phoneNumber: employeeData?.contactNumber[0] || '',
              image: employeeData?.avatar || '',
              description: employeeData?.info || '',
              oldPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required(
                t('accountInfo_form_firstNameField_requiredError')
              ),
              lastName: Yup.string().required(
                t('accountInfo_form_lastNameField_requiredError')
              ),
              email: Yup.string()
                .email()
                .required(t('accountInfo_form_emailField_requiredError')),
              role: Yup.array()
                .nullable()
                .test(
                  'is-nonempty',
                  t('accountInfo_form_roleFiel_requiredError'),
                  (value) => value && value?.length > 0
                ),
              // role: Yup.string().required(
              //   t('accountInfo_form_roleFiel_requiredError')
              // ),
              image: Yup.string().required(
                t('accountInfo_form_imageField_requiredError')
              ),
              description: Yup.string().max(
                2000,
                t('accountInfo_form_bioField_maximumlengthError')
              ),
              oldPassword: Yup.string()
                .trim()
                .when('newPassword', {
                  is: (newPassword) => newPassword && newPassword.length > 0,
                  then: Yup.string().required('Old Password required'),
                  otherwise: Yup.string(),
                }),
              // .required(t('accountInfo_form_oldPasswordField_requiredError')),
              newPassword: Yup.string()
                .trim()
                .min(8, 'Minium 8 characters required')
                .max(20, 'Maximum 20 characters allowed')
                .matches(
                  REGEX.password,
                  'At least one uppercase, one lowercase, one number & one special character allowed'
                ),
              // .required(t('accountInfo_form_newPasswordField_requiredError')),
              confirmNewPassword: Yup.string()
                // .required(t('accountInfo_form_confirmNewPasswordField_requiredError'))
                // eslint-disable-next-line func-names
                .test(
                  'Passwords match',
                  t(
                    'accountInfo_form_confirmNewPasswordField_notMatchingError'
                  ),
                  function (value) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return value === this.parent.newPassword;
                  }
                ),
            })}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <form noValidate>
                <Card variant="outlined" className={classes.cardRoot}>
                  <CardHeader
                    title={t('accountInfo_form_heading_userDetails_label')}
                    className={classes.header}
                  />
                  <Divider />
                  <Grid container spacing={3} sx={{ px: '24px', py: '35px' }}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', alignItems: 'center' }}>
                      {isSubmitting ? (
                        <div className={classes.loadingWrapper}>
                          <CircularProgress className={classes.loadingCircle} />
                        </div>
                      ) : (
                        <Avatar
                          className={classes.imageUploadAvatar}
                          src="https://assets.it22.nl/fortes-energy/admin/dummy_image.png"
                        />
                      )}
                      <TextField
                        className={classes.imageUploadButton}
                        name="image"
                        type="file"
                        onChange={async (e) => {
                          setIsSubmitting(true);
                          const image = await handlePicture(e);
                          if (image) {
                            setFieldValue('image', image?.fileName);
                          }
                          setIsSubmitting(false);
                        }}
                        inputProps={{
                          accept: 'image/png, image/jpeg',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label={t('accountInfo_form_userNameField_label')}
                        placeholder={t('accountInfo_form_userNameField_label')}
                        name="userName"
                        value={values?.userName}
                        onBlur={handleBlur}
                        error={Boolean(touched?.userName && errors?.userName)}
                        helperText={touched?.userName && errors?.userName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_firstNameField_label')}
                        placeholder={t('accountInfo_form_firstNameField_label')}
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.firstName && errors?.firstName)}
                        helperText={touched?.firstName && errors?.firstName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_lastNameField_label')}
                        placeholder={t('accountInfo_form_lastNameField_label')}
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.lastName && errors?.lastName)}
                        helperText={touched?.lastName && errors?.lastName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_designationField_label')}
                        placeholder={t(
                          'accountInfo_form_designationField_label'
                        )}
                        name="designation"
                        value={values.designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched?.designation && errors?.designation
                        )}
                        helperText={touched?.designation && errors?.designation}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        disabled
                        fullWidth
                        error={Boolean(errors.role && touched.role)}>
                        <Autocomplete
                          id="role-name"
                          options={roles}
                          value={values.role}
                          multiple
                          getOptionLabel={(option) =>
                            Object.keys(option).length !== 0 && option?.name
                          }
                          disabled
                          onChange={(e, value) => setFieldValue('role', value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="role"
                              label="Role"
                              error={Boolean(errors?.role && touched?.role)}
                              helperText={touched?.role && errors?.role}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label={t('accountInfo_form_emailField_label')}
                        placeholder={t('accountInfo_form_emailField_label')}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.email && errors?.email)}
                        helperText={touched?.email && errors?.email}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_phoneNumberField_label')}
                        placeholder={t(
                          'accountInfo_form_phoneNumberField_label'
                        )}
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched?.phoneNumber && errors?.phoneNumber
                        )}
                        helperText={touched?.phoneNumber && errors?.phoneNumber}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_oldPasswordField_label')}
                        name="oldPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.oldPassword && errors.oldPassword
                        )}
                        helperText={touched.oldPassword && errors.oldPassword}
                        type={showPassword[0] ? 'text' : 'password'}
                        value={values?.oldPassword}
                        variant="outlined"
                        InputProps={{
                          // classes: {
                          //   root: classes.readOnly
                          // },
                          readOnly: false,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(0)}>
                                {showPassword[0] ? <EyeIcon /> : <EyeOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_newPasswordField_label')}
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.newPassword && errors.newPassword
                        )}
                        helperText={touched.newPassword && errors.newPassword}
                        type={showPassword[1] ? 'text' : 'password'}
                        value={values.newPassword}
                        variant="outlined"
                        InputProps={{
                          // classes: {
                          //   root: classes.readOnly
                          // },
                          readOnly: false,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(1)}>
                                {showPassword[1] ? <EyeIcon /> : <EyeOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label={t(
                          'accountInfo_form_confirmNewPasswordField_label'
                        )}
                        name="confirmNewPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.confirmNewPassword &&
                            errors.confirmNewPassword
                        )}
                        helperText={
                          touched.confirmNewPassword &&
                          errors.confirmNewPassword
                        }
                        type={showPassword[2] ? 'text' : 'password'}
                        value={values?.confirmNewPassword}
                        variant="outlined"
                        InputProps={{
                          // classes: {
                          //   root: classes.readOnly
                          // },
                          readOnly: false,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(2)}>
                                {showPassword[2] ? <EyeIcon /> : <EyeOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('accountInfo_form_bioField_label')}
                        placeholder={t('accountInfo_form_bioField_label')}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched?.description && errors?.description
                        )}
                        helperText={errors?.description}
                        multiline
                        rows={4}
                      />
                      <Box className={classes.btnContainer}>
                        <CustomButton
                          value={t('accountInfo_submitButton_label')}
                          color="secondary"
                          onClick={handleSubmit}
                          type="submit"
                        />
                        <CustomButton
                          value={t('accountInfo_cancelButton_label')}
                          onClick={() => navigate('/dashboard')}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Account;
