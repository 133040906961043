import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import { Header } from 'src/components/Header';
import CustomizedTable from 'src/components/tables/CustomizedTable';
import { CustomButton2 } from 'src/components/widgets/buttons/CustomButton2';
import useGenericStyles from 'src/styles/useGenericStyles';
import setTitle from 'src/utils/setTitle';
// import { NameWithAvatar } from 'src/components/tables/renderers/NameWithAvatar';
import { Priority } from 'src/components/tables/renderers/Priority';
// import { Status } from 'src/components/tables/renderers/Status';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import DatePicker from 'src/components/DatePicker';
import { Filters } from 'src/components/Filters';
import { BillingType } from 'src/components/tables/renderers/BillingType';
import SwitchButton from 'src/components/widgets/buttons/SwitchButton';
import BulkApproveDialog from 'src/components/widgets/Dialog/BulkApproveDialog';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import { useDialog } from 'src/hooks/useDialog';
import { ClearIcon } from 'src/icons/Clear';
import { RootState } from 'src/store/store';
import convertSecondsToTimeString from 'src/utils/convertSecondsToTimeString';
// import { isEmpty, isUndefined } from 'lodash';
import capitalize from 'src/utils/capitalize';


function UnApprovedCases() {
  setTitle('ITSM | Cases');
  const theme = useTheme();
  // states
  const [caseTypes, setCaseTypes] = useState([]);
  const [internalUsers, setAllInternalUsers] = useState([]);
  const [allData, setAllData] = useState<any>([]);
  const bulkApproveDialog = useDialog();
  const [page, setPage] = useState<any>(0);
  const [pageSize, setPageSize] = useState<any>(25);
  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };
  const [search, setSearch] = useState<any>('');
  const [fetchingData, setFetchingData] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [sort, setSort] = useState({ field: 'name', order: 'desc' });
  const handleSearch = (val) => {
    setSearch(val);
  };
  const [selectedRowObjects, setSelectedRowObjects] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hasAttachments, setHasAttachments] = useState(false);

  // hooks
  const navigate = useNavigate();
  const classes = useGenericStyles();
  const allProjects = useSelector(
    (state: RootState) => state.projects.allProjects
  );

  const getAllTypesAutoComplete = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      if (response.status === 200) {
        setCaseTypes(response.data);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching types');
      }
    }
  };

  const getAllInternalUsersAutoComplete = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(true);
      setAllInternalUsers(res.data);
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching users');
      }
    }
  };

  const handleClearFilters = () => {
    setFilters({});
    setSearch('');
    setHasAttachments(false);
    setStartDate(null);
    setEndDate(null);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const isoString = date.toISOString();
    return isoString.split('T')[0];
  };

  const getAllData = async () => {
    setFetchingData(true);
    try {
      const varr = {
        page,
        pageSize,
        search,
        caseType: filters.Type?.map((itm: any) => itm.value).toString() || null,
        // caseType: caseType?.map((itm) => itm.id).toString(),
        // priority: priority?.map((itm) => itm.id).toString(),
        // assignee: filters.Reporter?.value || null,
        // status: status?.map((itm) => itm.id).toString(),
        projectId:
          filters.Project?.map((itm: any) => itm.value).toString() || null,
        hasAttachments,
        createdBy:
          filters.Reporter?.map((itm: any) => itm.value).toString() || null,
        isApproved: 0,
        to: formatDate(endDate),
        from: formatDate(startDate),
        // column: columnValue,  // dont remove comment, might need later
        // sort: selectedSort?.value === 'asc' ? '1' : '-1',
      };
      const keys = Object.keys(varr);
      const values = Object.values(varr);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          // if (keys[i] === 'from' || keys[i] === 'to') {
          //   queryParams.push(`${keys[i]}=${values[i]}`);
          // } else {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
          // }
        }
      }
      const queryString = queryParams.join('&');
      let response = await casesApi.advanceSearch(queryString);
      if (response?.status === 200) {
        response = response?.data;
        setAllData(response?.data);
        setTotalCount(response?.totalRecords);
        // setFetchingData(false);
      }
      if (response.status === 204) {
        setAllData([]);
        setTotalCount(0);
      }

      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllData([]);
        setTotalCount(0);
      }
    }
  };

  const customColumns = [
    {
      field: 'caseKey',
      headerName: 'CASE#',
      width: '10%',
      maxWidth: '10%',
    },
    {
      field: 'summary',
      headerName: 'Title',
      maxWidth: '40%',
      width: '40%',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(data?.summary)}
          sx={{
            maxWidth: "100%",
            width: "100%",
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(data?.summary)}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Reporter',
      width: '10%',
      maxWidth: '10%',
      // render: (columnValue, data) => (
      //   <NameWithAvatar name={columnValue?.name ?? ''} rowDataObj={data} />
      // ),
      render: (columnValue) => (
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {columnValue?.name}
        </Typography>
      ),
      filterable: true,
    },
    {
      field: 'timeEstimate',
      headerName: 'Est.',
      maxWidth: '8%',
      width: '8%',
      render: (columnValue) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color:
              capitalize(
                columnValue ? convertSecondsToTimeString(columnValue) : ''
              ) === 'N/A'
                ? 'red'
                : 'initial',
          }}
          title={
            capitalize(convertSecondsToTimeString(columnValue)) === 'N/A'
              ? 'Hours not quoted'
              : capitalize(convertSecondsToTimeString(columnValue))
          }>
          {capitalize(
            columnValue ? convertSecondsToTimeString(columnValue) : ''
          )}
        </Box>
      ),
      // filterable: true,
    },
    {
      field: 'expense',
      headerName: 'Expense',
      maxWidth: '8%',
      width: '8%',
      render: (columnValue) =>
        columnValue ? <BillingType value={columnValue} /> : 'N/A',
    },
    {
      field: 'project',
      headerName: 'Project',
      render: (columnValue) => (
        <span
          style={{
            fontSize: '12px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(columnValue?.name ?? '')}
        </span>
      ),
      width: '14%',
      maxWidth: '14%',
      filterable: true,
    },
    {
      field: 'priority',
      headerName: 'PRIORITY',
      maxWidth: '10%',
      width: '10%',
      render: (columnValue) => (
        <Priority priority={capitalize(columnValue?.name ?? '')} />
      ),
    },
    // {
    //   field: 'isApproved',
    //   headerName: 'Approval Status',
    //   render: (columnValue, data) => (
    //     <Box
    //       sx={{
    //         fontSize: 12,
    //         fontWeight: 700,
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //       }}>
    //       {!isEmpty(data?.caseType) &&
    //       !isUndefined(data?.caseType) &&
    //       data?.caseType?.name === 'Application Feature Request' ? (
    //         columnValue ? (
    //           'Approved'
    //         ) : (
    //           'Not Approved'
    //         )
    //       ) : (
    //         <>Approval Not Required</>
    //       )}
    //     </Box>
    //   ),
    //   filterable: true,
    // },
    // {
    //   field: 'status',
    //   headerName: 'STATUS',
    //   render: (columnValue) => (
    //     <Status status={capitalize(columnValue?.name ?? '')} />
    //   ),
    // },
  ];

  const getFilterOptions = (column) => {
    switch (column) {
      case 'createdBy':
        return internalUsers.map((userObj) => ({
          label: userObj.name,
          value: userObj.id,
        }));
      case 'caseType':
        return caseTypes.map((typeObj) => ({
          label: typeObj.name,
          value: typeObj.id,
        }));
      case 'project':
        return allProjects.map((projectObj) => ({
          label: projectObj.name,
          value: projectObj.id,
        }));
      case 'isApproved':
        return [
          {
            label: 'Approved',
            value: true,
          },
          {
            label: 'Not Approved',
            value: false,
          },
        ];
      // Add more cases for other columns
      default:
        return [];
    }
  };

  const handleSort = (field) => {
    setSort((prevSort) => ({
      field,
      order:
        prevSort.field === field && prevSort.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleApplyFilter = (
    currentFilterColumn,
    filterValue,
    filterName,
    checked
  ) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (checked) {
        if (newFilters[currentFilterColumn]) {
          newFilters[currentFilterColumn].push({
            label: filterName,
            value: filterValue,
          });
        } else {
          newFilters[currentFilterColumn] = [
            {
              label: filterName,
              value: filterValue,
            },
          ];
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (newFilters[currentFilterColumn]) {
          newFilters[currentFilterColumn] = newFilters[
            currentFilterColumn
          ].filter((obj) => obj.value !== filterValue);

          // If no filters are left for the column, remove the column from filters
          if (newFilters[currentFilterColumn].length === 0) {
            delete newFilters[currentFilterColumn];
          }
        }
      }
      return newFilters;
    });
  };

  const handleRemoveFilter = (column) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[column];
      return newFilters;
    });
  };

  const handleSwitchChange = (event) => {
    setHasAttachments(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    // const formattedDate = formatDate(date);
    setStartDate(date);
    // console.log('Start Date:', formattedDate); // Display the formatted date in console or use it as needed
  };

  const handleEndDateChange = (date) => {
    // const formattedDate = formatDate(date);
    setEndDate(date);
    // console.log('End Date:', formattedDate); // Display the formatted date in console or use it as needed
  };

  const handleClickBulkApprove = (selectedRowsObjsFromTable: any) => {
    bulkApproveDialog.handleOpen();
    const casesWithQuotations: any = selectedRowsObjsFromTable?.filter(
      (item) => parseInt(item?.timeEstimate, 10) > 0
    );
    setSelectedRowObjects(casesWithQuotations);
  };
  useEffect(() => {
    getAllData();
  }, [
    page,
    pageSize,
    JSON.stringify(filters),
    search,
    // JSON.stringify(caseType),
    // JSON.stringify(priority),
    // JSON.stringify(assignee),
    // JSON.stringify(status),
    // JSON.stringify(project),
    hasAttachments,
    // isEscalated,
    // isFirstResponseTimeViolated,
    // isResolveTimeViolated,
    startDate,
    endDate,
    // date?.from,
    // date?.to,
    // autoRefresh,
    // sortData?.id,
    // sortData?.caseName,
    // sortData?.organization,
    // sortData?.createdBy,
    // sortData?.assignee,
    // sortData?.caseType,
    // sortData?.project,
    // sortData?.resolveTime,
    // sortData?.firstResponseTime,
    // sortData?.priority,
    // sortData?.status,
    // selectedSort?.key,
    // selectedSort?.value,
  ]);

  useEffect(() => {
    // getAllProjectsAutoComplete();
    getAllTypesAutoComplete();
    getAllInternalUsersAutoComplete();
  }, []);

  const whiteLabel: any = JSON.parse(localStorage.getItem('white_label'));
  if (!whiteLabel?.isApprovalRequired) {
    window.location.href = '/cases';
  }

  return (
    <>
      <Header title="Approvals">
        <CustomButton2
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={() => navigate('/cases/add')}>
          <AddIcon
            sx={{
              marginRight: '5px',
            }}
          />
          Create case
        </CustomButton2>
      </Header>
      <Container maxWidth={false} className={classes.container}>
        <Box
          sx={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <SearchBar
            onSearch={handleSearch}
            value={search}
            placeholderData="Search ..."
          />
          <Stack
            direction="row"
            spacing={2}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: '22px',
                  alignSelf: 'center',
                }}
              />
            }
            alignItems="center">
            <Box display="flex" alignItems="center" gap="10px">
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 600,
                  color: '#8492A6',
                }}>
                {' '}
                CASES WITH ATTACHMENTS
              </Typography>
              <SwitchButton
                checked={hasAttachments}
                onChange={handleSwitchChange}
              />
            </Box>
            <DatePicker
              startDate={startDate}
              setStartDate={handleStartDateChange}
              endDate={endDate}
              setEndDate={handleEndDateChange}
            />
            <Button
              sx={{
                backgroundColor: 'white',
                borderRadius: '6px',
                display: 'flex',
                gap: '5px',
              }}
              className={classes.button}
              onClick={handleClearFilters}>
              <ClearIcon />
              <span
                style={{
                  marginLeft: '5px',
                }}>
                Clear All
              </span>
            </Button>
          </Stack>
        </Box>
        {Object.keys(filters).length > 0 && (
          <Filters filters={filters} handleRemoveFilter={handleRemoveFilter} />
        )}
        <CustomizedTable
          heading="All Approvals"
          loading={fetchingData}
          columns={customColumns}
          page={page}
          limit={pageSize}
          handleLimit={handlePageSize}
          handlePage={handlePage}
          data={allData}
          totalRecords={totalCount}
          showCheckBoxes
          // checkBoxClassName={UnApprovedClasses.checkBoxStyle}
          getFilterOptions={getFilterOptions}
          handleSort={handleSort}
          sort={sort}
          handleApplyFilter={handleApplyFilter}
          filters={filters}
          navigateUrl="/cases"
          navigateToKey
          selectRowData
          actionButtonLabel="Bulk Approve"
          actionButtonCallback={handleClickBulkApprove}
        />
        {bulkApproveDialog.open && (
          <BulkApproveDialog
            isOpen={bulkApproveDialog.open}
            handleClose={bulkApproveDialog.handleClose}
            selectedRowsData={selectedRowObjects}
            getAllData={getAllData}
          />
        )}
      </Container>
    </>
  );
}

export default UnApprovedCases;
