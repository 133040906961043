import React from 'react';
import setTitle from 'src/utils/setTitle';

const Contacts = () => {
  setTitle('ITSM | Help - Contacts');

  return (
    <>
      <h1>Contacts</h1>
      <p>The contacts module houses all the users for your organization.</p>
      <img src="/static/help/contacts.png" alt="" />
      <br />
      <h2>Contact Classification</h2>
      <h3>Client Admins</h3>
      <p>
        Client admins role is configured by IT22 and this user has some added
        functionality for the application. For example, client admins can create
        new contacts.
      </p>
      <h3>Client Users</h3>
      <p>
        Client users are the people who need to have access to specific projects
        and need to create or access cases.
        <br />
        <br />
        Moreover, contact status reflects if the users are Active or Inactive.
        Contacts can be marked as inactive by client admins if the profile is no
        longer active.
      </p>

      <br />
      <br />

      <h2>Contact Creation</h2>
      <p>
        Client admin users can create new users by clicking the ‘Create Contact’
        button in the Contacts Module which opens up the following form:
      </p>
      <img
        src="/static/help/createcontactdrawer.png"
        alt=""
        style={{ border: '1px solid #ddd' }}
      />
      <p>
        Once all required fields are completed, a new user will be created and
        an email will be generated with a one-time access password for the new
        user.
      </p>
    </>
  );
};

export default Contacts;
