/* eslint-disable no-restricted-properties */
import { useEffect } from 'react';

const setTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, []);
};

export default setTitle;
