import useAuth from './useAuth';

type PermissionIdentifier = string;

const useHasPermissions = () => {
  // Get user permissions from Redux
  const { user } = useAuth();

  // Return a function that checks for specific permissions
  const checkPermissions = (
    requiredPermissions: PermissionIdentifier[]
  ): boolean =>
    requiredPermissions.every((permission) =>
      user?.permissions?.includes(permission)
    );

  return checkPermissions;
};

export default useHasPermissions;
