import i18n from 'i18next';

// Import your JSON files using a relative path from the public folder
import navBarTranslationEN from './locales/en/navBar.json';
import navBarTranslationNL from './locales/nl/navBar.json';
import dashboardTranslationEN from './locales/en/dashboard.json';
import dashboardTranslationNL from './locales/nl/dashboard.json';
import casesTranslationEN from './locales/en/cases.json';
import casesTranslationNL from './locales/nl/cases.json';
import tablesTranslationEN from './locales/en/tables.json';
import tablesTranslationNL from './locales/nl/tables.json';
import advanceSearchModalTranslationEN from './locales/en/advanceSearchModal.json';
import advanceSearchModalTranslationNL from './locales/nl/advanceSearchModal.json';
import projectsTranslationEN from './locales/en/projects.json';
import projectsTranslationNL from './locales/nl/projects.json';
import contactsTranslationEN from './locales/en/contacts.json';
import contactsTranslationNL from './locales/nl/contacts.json';
import accountInfoTranslationEN from './locales/en/accountInfo.json';
import accountInfoTranslationNL from './locales/nl/accountInfo.json';
import toastsEN from './locales/en/toasts.json';
import toastsNL from './locales/nl/toasts.json';
import forgetPasswordEN from './locales/en/forgetPassword.json';
import forgetPasswordNL from './locales/nl/forgetPassword.json';
import loginEN from './locales/en/login.json';
import loginNL from './locales/nl/login.json';

i18n.init({
  resources: {
    en: {
      navBar: navBarTranslationEN,
      dashboard: dashboardTranslationEN,
      cases: casesTranslationEN,
      tables: tablesTranslationEN,
      advanceSearchModal: advanceSearchModalTranslationEN,
      projects: projectsTranslationEN,
      contacts: contactsTranslationEN,
      accountInfo: accountInfoTranslationEN,
      forgetPassword: forgetPasswordEN,
      login: loginEN,
      toasts: toastsEN
    },
    nl: {
      navBar: navBarTranslationNL,
      dashboard: dashboardTranslationNL,
      cases: casesTranslationNL,
      tables: tablesTranslationNL,
      advanceSearchModal: advanceSearchModalTranslationNL,
      projects: projectsTranslationNL,
      contacts: contactsTranslationNL,
      accountInfo: accountInfoTranslationNL,
      forgetPassword: forgetPasswordNL,
      login: loginNL,
      toasts: toastsNL
    },
  },
  lng: 'en', // Set the default language (English)
  fallbackLng: 'en', // Fallback to English if a translation is missing
  debug: true, // Enable debugging mode
  ns: ['navBar', 'dashboard', 'cases', 'tables', 'advanceSearchModal', 'projects', 'contacts', 'accountInfo', 'forgetPassword', 'login', 'toasts'],
  interpolation: {
    escapeValue: false, // React already escapes HTML
  },
});
