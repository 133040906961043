import { isUndefined } from 'lodash';
import axios, { getRequest, patchRequest, postRequest, updateRequest } from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class ContactsApi {
  async getAll(
    page: number,
    limit: number,
    isActivated: string | boolean,
    search:any,
    columns?: any,
    sort?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        page,
        pageSize: limit,
        isActive: isActivated,
        search,
        column: columns,
        sort: sort === 'asc' ? '1' : '-1'
      };

      if (isActivated === 'all') {
        delete payload.isActive;
      }
      if (columns === undefined || columns === '') {
        delete payload.column;
        delete payload.sort;
      }
      if (search === '') {
        delete payload.search;
      }
      if (sort === '') {
        delete payload.sort;
      }
      axios
        .get(`${process.env.REACT_APP_CASES}/user`, { params: payload })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(error);
        });
    });
  }

  async bulkDeactivate(checkedIds: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload: any = {
        list: checkedIds,
      };
      axios
        .patch(
          `${process.env.REACT_APP_EXTERNAL_USER_URL}/users/bulk-deactivate?isExternal=true`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async create(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/user`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/user/${id}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async updateById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/user/${id}`,
        payload,
        successFunction,
        errorFunction,
      };
      updateRequest(data);
    });
  }

  async updateByIdWithPassword(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/user/update`,
        payload,
        successFunction,
        errorFunction,
      };
      patchRequest(data);
    });
  }

  async updateStatusById(id: number, status: boolean) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.REACT_APP_CASES}/user/${id}/${status}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }
}

export const contactsApi = new ContactsApi();
