import toast from 'react-hot-toast';
import deleteRequest from './deleteRequest';
import getRequest from './getRequest';
import patchRequest from './patchRequest';
import postRequest from './postRequest';
import updateRequest from './updateRequest';
import axios from 'axios';
import { authApi } from 'src/api/authApi';

const axiosInstance = axios.create();
// let requestCount = 0;
// Function to show/hide the loading indicator
// let loadingElement: HTMLElement | null = null;

// const showLoading = () => {
//   if (requestCount === 0) {
//     loadingElement = document.createElement('div');
//     loadingElement.id = 'loader-root';
//     const child = document.createElement('div');
//     child.id = 'inner';
//     const childTwo = document.createElement('div');
//     childTwo.id = 'progress-bar';
//     const childThree = document.createElement('div');
//     childThree.id = 'progress-bar-value';
//     loadingElement.appendChild(child);
//     loadingElement.appendChild(childTwo);
//     childTwo.appendChild(childThree);
//     document.body.appendChild(loadingElement);
//   }

//   requestCount++;
// };

// const hideLoading = () => {
//   requestCount--;
//   if (requestCount === 0 && loadingElement) {
//     document.body.removeChild(loadingElement);
//     loadingElement = null;
//   }
// };
// Request Interceptor for API Call
axiosInstance.interceptors.request.use(
  async (config) => {
    // const showLoader = config.params && config.params.showLoader;
    // if (!showLoader) {
    // showLoading();
    // }

    config.headers.Authorization = localStorage.getItem('accessToken')
      ? `Bearer ${localStorage.getItem('accessToken')}`
      : '';
    config.headers.accept = '*/*';
    // eslint-disable-next-line consistent-return
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
let isUnauthorizedToastShown = false;
// Response interceptor for API Calls
axiosInstance.interceptors.response.use(
  async (response) =>
    // hideLoading();
    response,
  async (error) => {
    // hideLoading();
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      const localStorageRefreshToken = localStorage.getItem('refreshToken');

      try {
        const response: any = await authApi?.refreshSession(
          localStorageRefreshToken
        );
        axios.defaults.headers.common.Authorization = `Bearer ${response?.data?.accessToken}`;
        return await axiosInstance(originalRequest);
      } catch (errors) {
        // Handle the refresh error here
        if (
          errors?.response?.status === 401 ||
          errors?.response?.status === 400
        ) {
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          window.location.href = '/login';
        }
        if (!isUnauthorizedToastShown) {
          toast.error('Unauthorized. Please log in.');
          isUnauthorizedToastShown = true;
        }
      }
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { deleteRequest, getRequest, patchRequest, postRequest, updateRequest };
