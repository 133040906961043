import { Box } from '@material-ui/core';
import React from 'react';

export const Priority = ({ priority }: { priority: string }) => {
  const priorityColor = (_priority) => {
    switch (_priority) {
      case 'Highest':
        return '#F16063';
      case 'High':
        return '#F16063';
      case 'Medium':
        return '#F7936F';
      case 'Minor':
        return '#66CB9F';
      default:
        return 'black';
    }
  };

  return (
    <Box
      title={priority}
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: "ellipsis"
      }}>
      <div
        style={{
          height: '10px',
          width: '10px',
          borderRadius: '50%',
          backgroundColor: priorityColor(priority),
          marginRight: '10px',
          fontWeight: 700,
        }}
      />
      {priority}
    </Box>
  );
};
