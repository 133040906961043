import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      width: '297px',
      height: '100%',
      backgroundColor: '#fff',
      padding: '0px 15px',
    },
    section1: {
      padding: '40px 0px',
      borderBottom: '1px solid #e8edf2',
      '& span': {
        marginRight: '11px',
      },
      '& h4': {
        margin: '0px',
        color: '#564545',
        textTransform: 'capitalize',
        fontSize: '16px',
        fontWeight: 600,
        textAlign: 'center',
      },
      '& p': {
        margin: '0px',
        textAlign: 'center',
        color: '#8f8f8f',
        fontSize: '14px',
        fontWeight: 300,
        letterSpacing: '3px',
      },
    },
    list: {
      '& .MuiTypography-root': {
        color: '#564545',
        fontSize: '14px',
        fontWeight: 400,
      },
      '& a': {
        textDecoration: 'none',
        textTransform: 'capitalize',
      },
      '& a.active .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
    nestedList: {
      paddingLeft: '25px',
    },
  })
);

// const sidebarData = [
//   {
//     id: 'intro',
//     label: 'introduction',
//     to: '/help',
//   },
//   {
//     id: 'dashboard',
//     label: 'dashboard',
//     to: '/help/dashboard',
//   },
//   {
//     id: 'project',
//     label: 'Project',
//     to: '/help/project',
//   },
//   {
//     id: 'contacts',
//     label: 'contacts',
//     to: '/help/contacts',
//   },
//   {
//     id: 'multi-lang-support',
//     label: 'multi-language support',
//     to: '/help/multi-lang-support',
//   },
//   {
//     label: 'cases',
//     children: [
//       {
//         id: 'cases',
//         label: 'cases',
//         to: '/help/cases',
//       },
//       {
//         id: 'view-cases',
//         label: 'viewing cases',
//         to: '/help/cases/view',
//       },
//       {
//         id: 'creating-cases',
//         label: 'creating cases',
//         to: '/help/cases/create',
//       },
//     ]
//   },
// ];

const HelpLayoutSidebar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const handleCollapse = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.section1}>
          <h4>
            <span>
              <img
                src="/static/icons/home-icon.svg"
                alt="home icon"
                width="14px"
                height="14px"
              />
            </span>
            Portal User Guide
          </h4>
          <p>12.52.1542</p>
        </div>
        <List
          component="nav"
          className={classes.list}
        >
          <NavLink
            to="/help"
          >
            <ListItem button>
              <ListItemText primary="Introduction" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/help/dashboard"
          >
            <ListItem button>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </NavLink>
          <ListItem
            button
            onClick={handleCollapse}
          >
            <ListItemText primary="Cases" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
              className={classes.nestedList}
            >
              <NavLink
                to="/help/cases"
              >
                <ListItem button>
                  <ListItemText primary="Cases" />
                </ListItem>
              </NavLink>
              <NavLink
                to="/help/cases/view"
              >
                <ListItem button>
                  <ListItemText primary="Viewing Cases" />
                </ListItem>
              </NavLink>
              <NavLink
                to="/help/cases/create"
              >
                <ListItem button>
                  <ListItemText primary="Creating Cases" />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>
          <NavLink
            to="/help/project"
          >
            <ListItem button>
              <ListItemText primary="Project" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/help/contacts"
          >
            <ListItem button>
              <ListItemText primary="Contacts" />
            </ListItem>
          </NavLink>
          {/* <NavLink
            to="/help/multi-lang-support"
          >
            <ListItem button>
              <ListItemText primary="Multi-language Support" />
            </ListItem>
          </NavLink> */}
        </List>
      </div>
    </>
  );
};

export default HelpLayoutSidebar;
