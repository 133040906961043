import { makeStyles } from '@material-ui/core/styles';

const useGenericStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'background.default',
    minHeight: '100%',
    padding: '30px',
  },
  headerHeading: {
    fontSize: 22,
    fontWeight: 600,
    color: '#27272E',
  },
  heading: {
    fontSize: 16,
    fontWeight: 600,
  },
  smallHeading: {
    fontSize: 14,
    fontWeight: 600,
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: '#425466',
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  button: {
    padding: '6px 16px',
    fontSize: 12,
    fontWeight: 600,
  },
  textField: {
    borderRadius: '5px',
    height: 40,
  },

  inputField: {
    marginTop: '10px',
    borderRadius: '6px', // Border radius
    height: '36px',
    boxShadow:
      '0px 0px 1px 0px rgba(50, 50, 71, 0.2), 0px 1px 2px 0px rgba(50, 50, 71, 0.08)',
    '& fieldset': {
      border: 'none', // Remove border
    },
    '&:hover fieldset': {
      border: 'none', // Remove border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Remove border when focused
    },
  },
  autoComplete: {
    marginTop: '10px',
    borderRadius: '6px',
    height: '36px',
    boxShadow:
      '0px 0px 1px 0px rgba(50, 50, 71, 0.2), 0px 1px 2px 0px rgba(50, 50, 71, 0.08)',
    '& .MuiOutlinedInput-root': {
      height: 36,
      paddingTop: 0,
      '& .MuiChip-root': {
        height: 28,
        '& span': {
          fontSize: '10px',
          paddingLeft: '6px',
          paddingRight: '6px',
          fontWeight: 500,
          color: '#231F20',
        },
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '18px',
      },
      '& input': {
        padding: 0,
      },
    },
  },
  placeholder: {
    '&::placeholder': {
      color: '#B5B5BD', // Placeholder color
      paddingLeft: '5px',

      fontSize: 15,
      fontWeight: 500,
    },
  },
  instructions: {
    fontSize: 12,
    fontWeight: 400,
  },
}));

export default useGenericStyles;
