import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrgInfo {
  orgInfo: any;
  projInfo: any;
  reRenderComponent: boolean,
}
const initialState: OrgInfo = {
  orgInfo: {},
  projInfo: {},
  reRenderComponent: false
};
const organizationDetails = createSlice({
  name: 'organizationDetailData',
  initialState,
  reducers: {
    organizationDetailData: (state:any, action: PayloadAction<any>) => {
      state.orgInfo = action.payload;
      return state;
    },
    projectDataByOrg: (state:any, action: PayloadAction<any>) => {
      state.projInfo = action.payload;
      return state;
    },
    reRenderComponents: (state:any) => {
      state.reRenderComponent = !state.reRenderComponent;
      return state;
    },

  }
});

export const { organizationDetailData, projectDataByOrg, reRenderComponents } = organizationDetails.actions;
export default organizationDetails.reducer;
