import { Box, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface Props {
  filters: any;
  handleRemoveFilter: (columnName: string) => void;
}

export const Filters = ({ filters, handleRemoveFilter }: Props) => (
  <Box
    sx={{
      margin: '10px 0',
      display: 'flex',
      gap: '10px',
    }}>
    {Object.keys(filters).map(
      (column) =>
        filters[column].length > 0 && (
          <Chip
            sx={{
              backgroundColor: '#EBF2FA',
              color: '#A6B7D4',
              fontFamily: 'Inter',
              fontSize: 12,
              fontWeight: 700,
              borderRadius: '6px',
              padding: '8px 12px',
              '.MuiChip-label': {
                padding: 0,
              },
              '.MuiChip-deleteIcon': {
                margin: 0,
                marginLeft: '10px',
              },
            }}
            key={column}
            label={`${column}:  ${filters[column]?.map(
              (filterObj) => filterObj.label
            )}`}
            onDelete={() => handleRemoveFilter(column)}
            deleteIcon={
              <CloseIcon
                sx={{
                  height: '12px',
                  width: '12px',
                  color: '#A6B7D4',
                }}
              />
            }
          />
        )
    )}
  </Box>
);
