import { Box, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import useGenericStyles from 'src/styles/useGenericStyles';

interface HeaderProps {
  title: string;
  children?: ReactNode | undefined;
}

export const Header = ({ title, children }: HeaderProps) => {
  const classes = useGenericStyles();
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        height: '75px',
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      }}>
      <Typography variant="h4" className={classes.headerHeading}>
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};
