import { Box, Typography } from '@material-ui/core';

export const NameWithColor = ({ name }: { name: string }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}>
    {/* <div
      style={{
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        backgroundColor: `#${`000000${Math.random()
          .toString(16)
          .slice(2, 8)
          .toUpperCase()}`.slice(-6)}`,
        marginRight: '10px',
      }}
    /> */}
    <Typography
      sx={{
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 600,
        color: '#27272E',
      }}>
      {name}
    </Typography>
  </Box>
);
