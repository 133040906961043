/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { casesApi } from 'src/api/casesApi';
import capitalize from 'src/utils/capitalize';
import { projectApi } from 'src/api/projectApi';
import DatePicker from 'src/components/DatePicker';
import { Filters } from 'src/components/Filters';
import { Header } from 'src/components/Header';
import CustomizedTable from 'src/components/tables/CustomizedTable';
import { Priority } from 'src/components/tables/renderers/Priority';
import { Status } from 'src/components/tables/renderers/Status';
import { CustomButton2 } from 'src/components/widgets/buttons/CustomButton2';
import SwitchButton from 'src/components/widgets/buttons/SwitchButton';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import { ClearIcon } from 'src/icons/Clear';
import useGenericStyles from 'src/styles/useGenericStyles';
import { TableProps } from 'src/types/components/tableList.types';
import setTitle from 'src/utils/setTitle';
import { isEmpty, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import getProjectTypeShort from '../projectTypes';

const CasesViewByProjects: FC<TableProps> = () => {
  // const { t } = useTranslation(['projects']);
  const classes = useGenericStyles();
  setTitle('ITSM | Cases By Projects');
  const allProjects = useSelector(
    (state: RootState) => state.projects.allProjects
  );
  const { projectID } = useParams();
  const [currentTab, setCurrentTab] = useState<string | boolean>(projectID);
  const theme = useTheme();

  const whiteLabel: any = JSON.parse(localStorage.getItem('white_label'));
  const isApprovalRequired = whiteLabel?.isApprovalRequired
    ? {
        field: 'isApproved',
        headerName: 'Approval Status',
        maxWidth: '150px',
        render: (columnValue, data) => (
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {!isEmpty(data?.caseType) &&
            !isUndefined(data?.caseType) &&
            data?.caseType?.name === 'Application Feature Request' ? (
              columnValue ? (
                'Approved'
              ) : (
                'Not Approved'
              )
            ) : (
              <>Approval Not Required</>
            )}
          </Box>
        ),
        filterable: true,
      }
    : '';

  // const navigate = useNavigate();
  // const baseUrl = '/projects';
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [deleteDialogueVisible, setDeleteDialogueVisible] = useState<boolean>(false);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const [casesByProj, setCasesByProj] = useState<any>([]);
  // const isRenderComponent = useSelector(
  //   (state: any) => state.orgInfo?.reRenderComponent
  // );
  // const [autoRefresh, setAutoRefresh] = useState<number>(0);
  // const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  // const toggleAdvancedSearch = () => {
  //   setShowAdvancedSearch(!showAdvancedSearch);
  // };

  // const [bulkIds, setBulkIds] = useState<string>('');
  // const handleAddBulkIds = (id: string) => {
  //   setBulkIds(id);
  // };
  // const handleRemoveBulkIds = (id: string) => {
  //   setBulkIds(id);
  // };
  const [hasAttachments, setHasAttachments] = useState(false);
  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesByProjPageNo ||
      0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('casesByProjPagePg'))
      ?.casesByProjPageLimit || 25
  );
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('casesByProjPagePg'))?.search || ''
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const handleSearch = (val) => {
    setSearch(val);
  };
  // const [newCaseIDs, setNewCaseIDs] = useState<any>([]);
  const [filters, setFilters] = useState<any>({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projectsAutocomplete, setprojectsAutocomplete] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [internalUsers, setAllInternalUsers] = useState([]);
  const [sort, setSort] = useState<any>({});
  const [fetchingData, setFetchingData] = useState(false);
  const [favourites, setFavourites] = useState<Array<any>>([]);
  const navigate = useNavigate();

  // const [favProjects, setFavProjects] = useState([]);
  // const [allPrj, setallPrj] = useState(second);

  // const favProjects = useSelector(
  //   (state: RootState) => state.projects.favProjects
  // );
  // const [urlIdProject, setUrlIdProject] = useState<any>({});

  // const [date, setDate] = useState(
  //   JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesDate || {
  //     to: null,
  //     from: null,
  //   }
  // );
  // const [caseType, setCaseType] = useState<any>(
  //   JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesCaseType || []
  // );
  /** sort data */
  // type SortDataTypes = {
  //   id: 'asc' | 'desc';
  //   caseName: 'asc' | 'desc';
  //   organization: 'asc' | 'desc';
  //   createdBy: 'asc' | 'desc';
  //   caseType: 'asc' | 'desc';
  //   project: 'asc' | 'desc';
  //   resolveTime: 'asc' | 'desc';
  //   firstResponseTime: 'asc' | 'desc';
  //   priority: 'asc' | 'desc';
  //   status: 'asc' | 'desc';
  // };
  // const [selectedSort, setSelectedSort] = useState({
  //   key:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSelectedSortKey || '',
  //   value:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSelectedSortKey || '',
  // });
  // const [sortData, setSortData] = useState<SortDataTypes>({
  //   id:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortId ||
  //     'asc',
  //   caseName:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortCaseName || 'asc',
  //   organization:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortOrganization || 'asc',
  //   createdBy:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortCreatedBy || 'asc',
  //   caseType:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortCaseType || 'asc',
  //   project:
  //     JSON.parse(localStorage.getItem('casesPagePg'))?.casesSortProject ||
  //     'asc',
  //   resolveTime:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortResolveTime || 'asc',
  //   firstResponseTime:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortFirstResponseTime || 'asc',
  //   priority:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesSortPriority || 'asc',
  //   status:
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesSortStatus ||
  //     'asc',
  // });

  const handleTabChange = (newTabValue: string) => {
    navigate(`/projects/${newTabValue}`);
    setCurrentTab(newTabValue);
  };

  const customColumns = [
    {
      field: 'caseKey',
      headerName: 'CASE#',
      maxWidth: '100px',
    },
    {
      field: 'summary',
      headerName: 'Title',
      width: '150px',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(data?.summary)}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(data?.summary)}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Reporter',
      // width: '130px',[]
      maxWidth: '150px',
      // render: (columnValue, data) => (
      //   <NameWithAvatar name={columnValue?.name ?? ''} rowDataObj={data} />
      // ),
      render: (columnValue) => (
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {columnValue?.name}
        </Typography>
      ),
      filterable: true,
    },
    {
      field: 'caseType',
      headerName: 'Type',
      maxWidth: '100px',
      render: (columnValue) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={capitalize(columnValue?.name)}>
          {getProjectTypeShort(columnValue?.name) ?? ''}
        </Box>
      ),
      filterable: true,
    },
    {
      field: 'priority',
      headerName: 'PRIORITY',
      maxWidth: '120px',
      render: (columnValue) => (
        <Priority priority={capitalize(columnValue?.name ?? '')} />
      ),
    },
    isApprovalRequired,
    {
      field: 'status',
      headerName: 'STATUS',
      maxWidth: '100px',
      render: (columnValue) => (
        <Status status={capitalize(columnValue?.name ?? '')} />
      ),
    },
  ];

  const getFilterOptions = (column) => {
    switch (column) {
      case 'createdBy':
        return internalUsers.map((userObj) => ({
          label: userObj.name,
          value: userObj.id,
        }));
      case 'caseType':
        return caseTypes.map((typeObj) => ({
          label: typeObj.name,
          value: typeObj.id,
        }));
      case 'project':
        return allProjects.map((projectObj) => ({
          label: projectObj.name,
          value: projectObj.id,
        }));
      case 'isApproved':
        return [
          {
            label: 'Approved',
            value: true,
          },
          {
            label: 'Not Approved',
            value: false,
          },
        ];
      // Add more cases for other columns
      default:
        return [];
    }
  };

  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };

  const handlePage = (temp: number) => {
    setPage(temp);
  };

  const handleSwitchChange = (event) => {
    setHasAttachments(event.target.checked);
  };

  /** advance filters */

  // const [priority, setPriority] = useState<any>(
  //   JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesPriority || []
  // );
  // const [status, setStatus] = useState<any>(
  //   JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesStatus || []
  // );

  // const [isEscalated, setIsEscalated] = useState(
  //   JSON.parse(localStorage.getItem('casesByProjPagePg'))?.casesIsEscalated ||
  //     false
  // );
  // const [isFirstResponseTimeViolated, setIsFirstResponseTimeViolated] =
  //   useState(
  //     JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //       ?.casesIsFirstResponseTimeViolated || false
  //   );
  // const [isResolveTimeViolated, setIsResolveTimeViolated] = useState(
  //   JSON.parse(localStorage.getItem('casesByProjPagePg'))
  //     ?.casesIsResolveTimeViolated || false
  // );
  // const advancedSearchStates = {
  //   date: setDate,
  //   caseType: setCaseType,
  //   priority: setPriority,
  //   status: setStatus,
  //   hasAttachments: setHasAttachments,
  //   isEscalated: setIsEscalated,
  //   isFirstResponseTimeViolated: setIsFirstResponseTimeViolated,
  //   isResolveTimeViolated: setIsResolveTimeViolated
  // };
  const formatDate = (date) => {
    if (!date) return null;
    const isoString = date.toISOString();
    return isoString.split('T')[0];
  };

  const handleSort = (field) => {
    setSort((prevSort) => ({
      field,
      order:
        prevSort.field === field && prevSort.order === 'asc' ? 'desc' : 'asc',
    }));
  };
  const getAllProjectsAutoComplete = async () => {
    try {
      const response = await projectApi.getAllAutocomplete();
      if (response.status === 200) {
        setprojectsAutocomplete(response.data);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching projects');
      }
    }
  };

  const getAllTypesAutoComplete = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      if (response.status === 200) {
        setCaseTypes(response.data);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching types');
      }
    }
  };

  const getAllInternalUsersAutoComplete = async () => {
    try {
      const res = await casesApi?.getAutoCompleteUser(true);
      setAllInternalUsers(res.data);
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching users');
      }
    }
  };

  const getFavProjects = async () => {
    // setFetchingData(true);
    try {
      const response = await projectApi.getAllFavouriteProjects();
      if (response?.status === 200) {
        setFavourites(response.data);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching projects');
      }
    }
  };

  const getCasesByProject = async () => {
    setFetchingData(true);
    try {
      // const fieldToColumnMap = {
      //   id: 'caseKeyTruncate',
      //   title: 'summary',
      //   reporter: 'createdBy.name',
      //   type: 'caseType.name',
      //   priority: 'priority.rank',
      //   status: 'status.rank',
      //   project: 'project.name',
      // };

      // const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const varr = {
        page,
        pageSize,
        search,
        caseType: filters.Type?.map((itm: any) => itm.value).toString() || null,
        // priority: priority?.map((itm) => itm.id).toString(),
        // status: status?.map((itm) => itm.id).toString(),
        hasAttachments,
        // isEscalated,
        // isFirstResponseTimeViolated,
        // isResolveTimeViolated,
        // to: date?.to,
        // from: date?.from,
        to: formatDate(endDate),
        from: formatDate(startDate),
        createdBy:
          filters.Reporter?.map((itm: any) => itm.value).toString() || null,
        projectId: projectID,
        // column: columnValue,
        // sort: selectedSort?.value === 'asc' ? '1' : '-1',
      };
      // if (selectedSort?.key === '' || selectedSort?.key === undefined) {
      //   delete varr.column;
      //   delete varr.sort;
      // }
      const keys = Object.keys(varr);
      const values = Object.values(varr);
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      let response = await casesApi.advanceSearch(queryString);
      response = response?.data;
      // const existingTicketIds = casesByProj?.map((ticket) => ticket.id);
      // Compare with existing data to find newly added tickets
      // const newCaseIDsRes = response?.data?.filter(
      //   (ticket) => !existingTicketIds.includes(ticket.id)
      // );

      // if (newCaseIDsRes.length > 0 && autoRefresh !== 0) {
      //   setNewCaseIDs(newCaseIDsRes.map((ticket) => ticket.id));
      // }
      setCasesByProj(response?.data);
      setTotalCount(response?.totalRecords);
      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
      toast.error(error);
      setCasesByProj([]);
      setTotalCount(0);
    }
  };

  // const getProjectFromUrlId = () => {
  //   const UrlIdProj = allProjects.find((project) => project.id === projectID);
  //   if (UrlIdProj) {
  //     setUrlIdProject({
  //       label: urlIdProject.id,
  //       value: urlIdProject.name,
  //     });
  //     setCurrentTab(urlIdProject.id);
  //   }
  // };

  const handleStartDateChange = (date) => {
    // const formattedDate = formatDate(date);
    setStartDate(date);
    // console.log('Start Date:', formattedDate); // Display the formatted date in console or use it as needed
  };

  const handleEndDateChange = (date) => {
    // const formattedDate = formatDate(date);
    setEndDate(date);
    // console.log('End Date:', formattedDate); // Display the formatted date in console or use it as needed
  };

  const handleClearFilters = () => {
    setFilters({});
    setSearch('');
    setHasAttachments(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleRemoveFilter = (column) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[column];
      return newFilters;
    });
  };

  const handleApplyFilter = (
    currentFilterColumn,
    filterValue,
    filterName,
    checked
  ) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (checked) {
        if (newFilters[currentFilterColumn]) {
          newFilters[currentFilterColumn].push({
            label: filterName,
            value: filterValue,
          });
        } else {
          newFilters[currentFilterColumn] = [
            {
              label: filterName,
              value: filterValue,
            },
          ];
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (newFilters[currentFilterColumn]) {
          newFilters[currentFilterColumn] = newFilters[
            currentFilterColumn
          ].filter((obj) => obj.value !== filterValue);

          // If no filters are left for the column, remove the column from filters
          if (newFilters[currentFilterColumn].length === 0) {
            delete newFilters[currentFilterColumn];
          }
        }
      }
      return newFilters;
    });
  };

  const CurrentProject = projectsAutocomplete.find(
    (project) => project.id === projectID
  );

  // const tabs = [
  //   { ... },

  //   {
  //     label: 'Security',
  //     value: 'security',
  //   },
  // ];
  // const deleteCaseById = async (id: String) => {
  //   try {
  //     const res = await casesApi.deleteCase(id);
  //     if (res?.status === 200) {
  //       toast.success('Deleted successfully');
  //       setBulkIds('');
  //       getCasesByProject();
  //     }
  //   } catch (error) {
  //     logger(error, 'error');
  //     if (error?.response?.status === 401) {
  //       toast.error('Unauthorized User');
  //     } else {
  //       toast.error('Internal Server Error');
  //     }
  //   }
  // };

  // const handleAutoRefresh = (event) => {
  //   const newDuration = parseInt(event.target.value, 10);
  //   setAutoRefresh(newDuration);
  // };
  useEffect(() => {
    getCasesByProject();

    // const casesByProjPagePg = {
    //   casesByProjPageLimit: pageSize,
    //   casesByProjPageNo: page,
    //   search,
    //   casesSortId: sortData?.id,
    //   casesSortCaseName: sortData?.caseName,
    //   casesSortOrganization: sortData?.organization,
    //   casesSortCreatedBy: sortData?.createdBy,
    //   casesSortCaseType: sortData?.caseType,
    //   casesSortProject: sortData?.project,
    //   casesSortPriority: sortData?.priority,
    //   casesSortStatus: sortData?.status,
    //   casesCaseType: caseType,
    //   casesPriority: priority,
    //   casesStatus: status,
    //   casesHasAttachments: hasAttachments,
    //   casesIsEscalated: isEscalated,
    //   casesIsFirstResponseTimeViolated: isFirstResponseTimeViolated,
    //   casesIsResolveTimeViolated: isResolveTimeViolated,
    //   casesDate: date,
    //   casesSelectedSortKey: selectedSort?.key,
    //   casesSelectedSortValue: selectedSort?.value
    // };
    // localStorage.setItem(
    //   'casesByProjPagePg',
    //   JSON.stringify(casesByProjPagePg)
    // );
    // let autoRefreshTime;
    // if (autoRefresh !== 0) {
    //   autoRefreshTime = setInterval(() => {
    //     getCasesByProject();
    //   }, autoRefresh * 1000);
    // }

    // return () => {
    //   clearTimeout(autoRefreshTime);
    // };
  }, [
    // isRenderComponent,
    page,
    pageSize,
    search,
    JSON.stringify(filters),
    // JSON.stringify(priority),
    // JSON.stringify(status),
    hasAttachments,
    // isEscalated,
    // isFirstResponseTimeViolated,
    // isResolveTimeViolated,
    startDate,
    endDate,
    // date?.from,
    // date?.to,
    // autoRefresh,
    // sortData?.id,
    // sortData?.caseName,
    // sortData?.organization,
    // sortData?.createdBy,
    // sortData?.caseType,
    // sortData?.project,
    // sortData?.resolveTime,
    // sortData?.firstResponseTime,
    // sortData?.priority,
    // sortData?.status,
    // selectedSort?.key,
    // selectedSort?.value,
    projectID,
  ]);

  useEffect(() => {
    getAllProjectsAutoComplete();
    getAllInternalUsersAutoComplete();
    getAllTypesAutoComplete();
    getFavProjects();
  }, []);

  useEffect(() => {
    setCurrentTab(projectID);
  }, [projectID]);

  return (
    <>
      <Header title="Cases By Project">
        <CustomButton2
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={() => navigate('/cases/add')}>
          <AddIcon
            sx={{
              marginRight: '5px',
            }}
          />
          Create case
        </CustomButton2>
      </Header>
      {favourites.length > 0 && (
        <Tabs
          className="tabs-fade-in"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
              height: 2,
            },
          }}
          scrollButtons="auto"
          value={currentTab}
          variant="standard"
          sx={{
            backgroundColor: 'white',
          }}>
          {favourites.map((tab) => (
            <Tab
              key={tab?._id}
              label={tab?.name}
              value={tab?._id}
              disableRipple
              style={{
                color: currentTab === tab?._id ? '#1A202C' : '#718096',
                fontFamily: 'Inter',
                fontSize: 14,
                fontWeight: 600,
                textTransform: 'none',
                width: 'auto',
                minWidth: '20px',
                marginLeft: '32px',
              }}
              onClick={() => handleTabChange(tab._id)}
            />
          ))}
        </Tabs>
      )}

      <Container maxWidth={false} className={classes.container}>
        <Box
          sx={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <SearchBar
            onSearch={handleSearch}
            value={search}
            placeholderData="Search ..."
          />
          <Stack
            direction="row"
            spacing={2}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: '22px',
                  alignSelf: 'center',
                }}
              />
            }
            alignItems="center">
            <Box display="flex" alignItems="center" gap="10px">
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 600,
                  color: '#8492A6',
                }}>
                {' '}
                CASES WITH ATTACHMENTS
              </Typography>
              <SwitchButton
                checked={hasAttachments}
                onChange={handleSwitchChange}
              />
            </Box>
            <DatePicker
              startDate={startDate}
              setStartDate={handleStartDateChange}
              endDate={endDate}
              setEndDate={handleEndDateChange}
            />
            <Button
              sx={{
                backgroundColor: 'white',
                borderRadius: '6px',
                display: 'flex',
                gap: '5px',
              }}
              className={classes.button}
              onClick={handleClearFilters}>
              <ClearIcon />
              <span
                style={{
                  marginLeft: '5px',
                }}>
                Clear All
              </span>
            </Button>
          </Stack>
        </Box>
        {Object.keys(filters).length > 0 && (
          <Filters filters={filters} handleRemoveFilter={handleRemoveFilter} />
        )}
        {/* <Filters filters={filters} handleRemoveFilter={handleRemoveFilter} /> */}

        <CustomizedTable
          heading={
            CurrentProject ? (
              `${CurrentProject?.name} Cases`
            ) : (
              <Box
                sx={{
                  color: '#8492A6',
                }}>
                <CircularProgress size={20} color="inherit" />
              </Box>
            )
          }
          loading={fetchingData}
          columns={customColumns}
          page={page}
          limit={pageSize}
          handleLimit={handlePageSize}
          handlePage={handlePage}
          data={casesByProj}
          totalRecords={totalCount}
          showCheckBoxes
          getFilterOptions={getFilterOptions}
          handleSort={handleSort}
          sort={sort}
          handleApplyFilter={handleApplyFilter}
          filters={filters}
          navigateUrl="/cases"
          navigateToKey
          selectRowData
          // actionButtonLabel="Bulk Approve"
          // actionButtonCallback={handleClickBulkApprove}
        />
        {/* <CaseListView
              isCheckbox={false}
              data={allData}
              sortData={sortData}
              toggleSortData={toggleSortData}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              setSortData={setSortData}
              redirectUrl="/cases"
              editRouteUrl="/cases"
              page={page}
              limit={pageSize}
              handleLimit={handlePageSize}
              handlePage={handlePage}
              handleSearch={handleSearch}
              count={totalCount}
              bulkIds={bulkIds}
              handleAddBulkIds={handleAddBulkIds}
              handleRemoveBulkIds={handleRemoveBulkIds}
              advancedSearchStates={advancedSearchStates}
              localStorageValue="casesPagePg"
              newCaseIDs={newCaseIDs}
            /> */}
      </Container>
    </>
    // <Box
    //   sx={{
    //     backgroundColor: 'background.default'
    //   }}
    //   className={classes.table}
    // >
    //   <Grid
    //     container
    //     alignItems="center"
    //   >
    //     <Grid
    //       item
    //       xs={12}
    //       md={5}
    //       sx={{
    //         display: 'flex',
    //         gap: 1
    //       }}
    //     >
    //       <Paper sx={{ display: 'none', gap: 1 }}>
    //         <Button
    //           variant="text"
    //           id="basic-button"
    //           aria-controls={open ? 'basic-menu' : undefined}
    //           aria-haspopup="true"
    //           aria-expanded={open ? 'true' : undefined}
    //           onClick={handleClick}
    //           sx={{
    //             px: 2,
    //             background: 'white',
    //             color: 'black',
    //             height: '40px'
    //           }}
    //         >
    //           Actions
    //         </Button>
    //         <Menu
    //           id="basic-menu"
    //           anchorEl={anchorEl}
    //           open={open}
    //           onClose={handleClose}
    //           MenuListProps={{
    //             'aria-labelledby': 'basic-button'
    //           }}
    //         >
    //           <MenuItem
    //             disabled={bulkIds.length === 0}
    //             onClick={() => {
    //               handleClose();
    //               setDeleteDialogueVisible(true);
    //             }}
    //           >
    //             Delete
    //           </MenuItem>
    //         </Menu>
    //       </Paper>
    //       <SearchBar
    //         onSearch={handleSearch}
    //         value={search}
    //         placeholderData={t('projects_cases_search_placeholder')}
    //         anyState={autoRefresh !== 0}
    //         advancedSearch={(
    //           <IconButton
    //             sx={{ p: 1 }}
    //             onClick={toggleAdvancedSearch}
    //             title={t('projects_cases_advanceSearch_label')}
    //             disabled={autoRefresh !== 0}
    //           >
    //             <TuneIcon />
    //           </IconButton>
    //         )}
    //       />
    //       <Paper>
    //         <Select
    //           value={autoRefresh}
    //           className={classes.borderlessSelect}
    //           onChange={handleAutoRefresh}
    //           sx={{ height: '40px', marginLeft: 1, fontWeight: 'normal' }}
    //         >
    //           <MenuItem
    //             selected
    //             value={0}
    //           >
    //             {t('projects_refreshDropdown_label')}
    //           </MenuItem>
    //           <MenuItem value={30}>
    //             30
    //             {' '}
    //             {t('projects_refreshDropDown_options_seconds')}
    //           </MenuItem>
    //           <MenuItem value={60}>
    //             1
    //             {' '}
    //             {t('projects_refreshDropDown_options_minute')}
    //           </MenuItem>
    //           <MenuItem value={300}>
    //             5
    //             {' '}
    //             {t('projects_refreshDropDown_options_minutes')}
    //           </MenuItem>
    //           <MenuItem value={900}>
    //             15
    //             {' '}
    //             {t('projects_refreshDropDown_options_minutes')}
    //           </MenuItem>
    //         </Select>
    //       </Paper>
    //       {showAdvancedSearch && (
    //         <AdvancedSearchModal
    //           _headingMain={t('projects_cases_advanceSearch_label')}
    //           _setShowAdvancedSearch={setShowAdvancedSearch}
    //           _handleSubmit={() => null}
    //           _handleReset={handleReset}
    //           _search={search}
    //           _setSearch={handleSearch}
    //           _caseType={caseType}
    //           _setCaseType={setCaseType}
    //           _priority={priority}
    //           _setPriority={setPriority}
    //           // _assignee={assignee}
    //           // _setAssignee={setAssignee}
    //           _status={status}
    //           _setStatus={setStatus}
    //           _hasAttachments={hasAttachments}
    //           _setHasAttachments={setHasAttachments}
    //           _isEscalated={isEscalated}
    //           _setIsEscalated={setIsEscalated}
    //           _isFirstResponseTimeViolated={isFirstResponseTimeViolated}
    //           _setIsFirstResponseTimeViolated={setIsFirstResponseTimeViolated}
    //           _isResolveTimeViolated={isResolveTimeViolated}
    //           _setIsResolveTimeViolated={setIsResolveTimeViolated}
    //           _date={date}
    //           _setDate={setDate}
    //           _setPage={setPage}
    //         />
    //       )}
    //     </Grid>
    //     <Grid
    //       item
    //       xs={12}
    //       md={7}
    //       style={{
    //         width: '100%',
    //         display: 'flex',
    //         flexDirection: 'row',
    //         alignItems: 'center',
    //         justifyContent: 'flex-end',
    //         gap: '8px'
    //       }}
    //     >
    //       <Button
    //         variant="contained"
    //         color="secondary"
    //         onClick={() => navigate(`/projects/${projectID}/cases/add`)}
    //         // onClick={(e) => addCases(true, e)}
    //       >
    //         <div style={{ display: 'flex', gap: '8px' }}>
    //           {t('projects_cases_createCaseButton_label')}
    //           <AddCircleIcon />
    //         </div>
    //       </Button>
    //     </Grid>
    //   </Grid>
    //   <CaseListView
    //     isCheckbox={false}
    //     data={casesByProj}
    //     sortData={sortData}
    //     toggleSortData={toggleSortData}
    //     selectedSort={selectedSort}
    //     setSelectedSort={setSelectedSort}
    //     setSortData={setSortData}
    //     handlePage={setPage}
    //     handleLimit={setPageSize}
    //     count={totalCount}
    //     page={page}
    //     handleSearch={handleSearch}
    //     limit={pageSize}
    //     bulkIds={bulkIds}
    //     handleAddBulkIds={handleAddBulkIds}
    //     handleRemoveBulkIds={handleRemoveBulkIds}
    //     redirectUrl={baseUrl}
    //     editRouteUrl={`${baseUrl}/${projectID}/cases`}
    //     advancedSearchStates={advancedSearchStates}
    //     localStorageValue="casesByProjPagePg"
    //     isProject={false}
    //     newCaseIDs={newCaseIDs}
    //   />
    //   <SingleDeleteConfirmation
    //     isOpen={deleteDialogueVisible}
    //     setDeleteDialogueVisible={setDeleteDialogueVisible}
    //     selectedItem={bulkIds}
    //     handleDelete={deleteCaseById}
    //     text={t('projects_cases_deleteConfirmation_label')}
    //   />
    // </Box>
  );
};

export default CasesViewByProjects;
