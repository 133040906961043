import {
  AppBarProps,
  Theme,
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Button,
} from '@material-ui/core';
import {
  createStyles,
  experimentalStyled,
  makeStyles,
} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import MenuIcon from '../../icons/Menu';
import Logo from '../Logo';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import logger from 'src/utils/logger';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 8px 24px 0px rgba(229, 228, 230, 0.40);',
    color: theme.palette.primary.main,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation(['navBar']);
  const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
      linksWrapper: {
        marginLeft: '78px',
        display: 'flex',
        gap: '55px',
        alignItems: 'center',
        '& .active': {
          color: theme.palette.primary.main,
          fontWeight: 400,
        },
      },
      link: {
        textDecoration: 'none',
        color: '#564545',
      },
    })
  );

  const classes = useStyles();
  logger(location, '', 'location');
  logger(location.pathname, '', 'location.pathname');
  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64, padding: '10px 30px' }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}>
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/dashboard">
          <Logo height={50} />
        </RouterLink>
        <Box className={classes.linksWrapper}>
          <RouterLink to="/dashboard" className={classes.link}>
            {t('navBar_dashboard')}
          </RouterLink>
          <RouterLink to="/projects" className={classes.link}>
            {t('navBar_projects')}
          </RouterLink>
          {user?.role?.some(
            (roleObj) => roleObj?.identifier === 'client_contact_admin'
          ) && (
            <RouterLink to="/contacts" className={classes.link}>
              {t('navBar_contacts')}
            </RouterLink>
          )}
          <RouterLink to="/cases" className={classes.link}>
            {t('navBar_cases')}
          </RouterLink>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            disabled={location.pathname.includes('/cases/add')}
            onClick={() => {
              navigate('/cases/add');
            }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              Create Case
              <AddCircleIcon />
            </div>
          </Button>
        </Box>

        <Box
          sx={{
            ml: 'auto',
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
          }}>
          <RouterLink to="/help">
            <IconButton aria-label="help button">
              <img
                src="/static/icons/question-mark.svg"
                alt="help icon"
                width="100%"
                height="100%"
              />
            </IconButton>
          </RouterLink>
          <NotificationsPopover />
          <LanguagePopover />
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
