import moment from 'moment-timezone';

// Define the global datetime format
export const datetimeFormat: string = 'MMM DD YYYY - HH:mm';

export const relativeTime = (timestamp:string) :string => moment.tz(timestamp, 'Europe/Amsterdam').fromNow();
export const absoluteTime = (timestamp:string, withSeconds:boolean = false) :string => moment(timestamp).format(`${datetimeFormat}${withSeconds ? ':ss' : ''}`);

const time = {
  relativeTime,
  absoluteTime
};
export default time;
