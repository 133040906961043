import { isUndefined } from 'lodash';
import axiosInstance, { getRequest } from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class ProjectApi {
  async getAllAutocomplete(search?: string, maxRecords?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const customHeader = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const payload = {
        search,
        maxRecords,
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/auto-complete/projects`,
        payload,
        successFunction,
        errorFunction,
        customHeader,
      };
      getRequest(data);
    });
  }

  async getAllFavouriteProjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };

      const data = {
        url: `${process.env.REACT_APP_CASES}/user/getAllUserFavoriteProjects`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async syncAllProjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const customHeader = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        url: `${process.env.REACT_APP_TICKETING_PROJECT_URL}/sync-projects`,
        successFunction,
        errorFunction,
        customHeader,
      };
      getRequest(data);
    });
  }

  async syncProjectCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(error);
      };
      const customHeader = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const data = {
        url: `${process.env.REACT_APP_TICKETING_PROJECT_URL}/sync-project-categories`,
        successFunction,
        errorFunction,
        customHeader,
      };
      getRequest(data);
    });
  }

  async getProjectDetailsById(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${process.env.REACT_APP_CASES}/project/${projectId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error: Error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getJiraProjects(platformId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `${process.env.REACT_APP_CASES}/auto-complete/projects/third-party?platformId=${platformId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  async getPlatforms(): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${process.env.REACT_APP_CASES}/auto-complete/platform`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  async getStakeSponserByProject(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `${process.env.REACT_APP_CASES}/auto-complete/projects/sponsor-stakeholder/${projectId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  async updateProjectById(projectId: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`${process.env.REACT_APP_CASES}/project/${projectId}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  async getUsersByProj(): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${process.env.REACT_APP_CASES}/auto-complete/users`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async addUserFavProject(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${process.env.REACT_APP_CASES}/user/addToFavorite/${projectId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async removeUserFavProject(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`${process.env.REACT_APP_CASES}/user/removeFromFavorite/${projectId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const projectApi = new ProjectApi();
