import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { isEmpty, isUndefined } from 'lodash';
import { KeycloakLogin } from 'src/types/keycloak_login';
import { User } from 'src/types/user';
import parse from 'src/utils/parse';
import { userApi } from './userApi';
import logger from 'src/utils/logger';

class AuthApi {
  async login({ email, password }): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/user/login`, {
          username: email,
          password,
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async reset(keycloack, password): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.REACT_APP_CASES}/user/password/reset/${keycloack}`,
          {
            password,
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error?.response?.data.message);
        });
    });
  }

  async forgetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/user/password/forget?email=${email}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async register(): Promise<void> {
    logger('register');
  }

  me(accessToken): Promise<User> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let loadingElement: HTMLElement | null = null;
        const showLoading = () => {
          loadingElement = document.createElement('div');
          loadingElement.id = 'loader-root';
          const child = document.createElement('div');
          child.id = 'inner';
          const childTwo = document.createElement('div');
          childTwo.id = 'progress-bar';
          const childThree = document.createElement('div');
          childThree.id = 'progress-bar-value';
          loadingElement.appendChild(child);
          loadingElement.appendChild(childTwo);
          childTwo.appendChild(childThree);
          document.body.appendChild(loadingElement);
        };

        const hideLoading = () => {
          document.body.removeChild(loadingElement);
          loadingElement = null;
        };

        // Decode access token
        const decoded: any = jwtDecode(accessToken);
        if (!decoded) {
          reject(new Error('Invalid authorization token'));
          return;
        }
        showLoading();
        const userByKeyCloak: any = await userApi.getByKeycloakId();
        if (
          !isEmpty(userByKeyCloak?.organization) &&
          userByKeyCloak?.organization?.logo?.length > 0 &&
          userByKeyCloak?.organization?.primaryColor?.length > 0 &&
          userByKeyCloak?.organization?.secondaryColor?.length > 0
        ) {
          const organization = userByKeyCloak?.organization;
          localStorage.setItem('white_label', JSON.stringify(organization));
        }
        hideLoading();

        if (userByKeyCloak) {
          resolve({
            userKeyCloakId: decoded?.sub,
            firstName: userByKeyCloak.firstName,
            lastName: userByKeyCloak.lastName,
            designation: userByKeyCloak.designation,
            phoneNumber: userByKeyCloak.phoneNumber,
            id: userByKeyCloak?.id,
            name: `${userByKeyCloak.firstName} ${userByKeyCloak.lastName}`,
            email: decoded.email,
            image: userByKeyCloak.image,
            organizationId: userByKeyCloak?.organizationId,
            permissions: userByKeyCloak?.permissions,
            userKafkaTopic: userByKeyCloak?.userKafkaTopic,
            notifications: userByKeyCloak?.notifications?.list,
            role: userByKeyCloak?.role,
            language: userByKeyCloak?.language,
            description: userByKeyCloak.description,
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async refreshSession(refreshToken): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/user/refresh-token`, {
          refreshToken,
        })
        .then((response) => {
          const res: any = parse(response);
          if (!isUndefined(res?.data?.refreshToken)) {
            localStorage.setItem('accessToken', res?.data?.accessToken);
            localStorage.setItem('refreshToken', res?.data?.refreshToken);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const authApi = new AuthApi();
