import axios from 'src/utils/axios';
import { getMethodProps } from 'src/types/axios.types';

const getRequest = ({
  url,
  loader,
  successFunction,
  errorFunction,
  customHeader,
}: getMethodProps) => {
  if (loader) {
    loader(true);
  }
  axios
    .get(url, customHeader)
    .then((res) => {
      if (successFunction) {
        successFunction(res);
      }
    })
    .catch((error) => {
      // const { response } = error;
      // if (response) {
      //   if (response.data.StatusCode === 401) {
      //     // unAuthorizedException(error, dispatch, postRequest, [url, payload, loader, successFunction, errorFunction])
      //     return;
      //   }
      // }
      if (errorFunction) {
        errorFunction(error);
      }
    })
    .finally(() => {
      if (loader) {
        loader(false);
      }
    });
};

export default getRequest;
