// import { AnyAction, ThunkAction, configureStore } from '@reduxjs/toolkit';
// import rootReducer from './rootReducer';
import { persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

// export type AppDispatch = typeof store.dispatch;

// export type RootState = ReturnType<typeof store.getState>;

// export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: [thunk],
//   // Additional configuration options if needed
// });
// const persistor = persistStore(store);
// export { store, persistor };

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
// ...

const store = configureStore({
  reducer: rootReducer,
});

const persistor = persistStore(store);
export { store, persistor };

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
