import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { rolesApi } from 'src/api/rolesApi';

const initialState = {
  allRoles: [],
  allRolesIds: [],
  loading: false,
  error: null as string | null,
};

interface AllRolesResponse {
  allObjects: Array<any>;
  allIds: Array<any>;
}
export const getAllRoles = createAsyncThunk('getAllRoles', async () => {
  const response = await rolesApi.getAllActiveRoles();
  if (response?.status === 200) {
    const returnObj: AllRolesResponse = {
      allObjects: response?.data,
      allIds: response?.data.map((role) => role.id),
    };
    return returnObj;
  }
  return [];
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // allProjects: (state: any, action: PayloadAction<any>) => {
    //   state.allProjects = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.loading = false;
          state.allRoles = [];
          state.allRolesIds = [];
        } else {
          const { allObjects, allIds } = action.payload as AllRolesResponse;
          state.loading = false;
          state.allRoles = allObjects;
          state.allRolesIds = allIds;
        }
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch projects';
      });
  },
});

export default rolesSlice.reducer;
