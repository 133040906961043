import { Box, Typography } from '@material-ui/core';
import React from 'react';

interface CustomLegendProps {
  color: string;
  value: number;
  name: string;
}

export const PieCustomLegend = ({ color, value, name }: CustomLegendProps) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: color,
          width: '8px',
          height: '8px',
        }}
      />

      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 500,
          marginLeft: '10px',
          color: '#27272E',
        }}>
        {value}
      </Typography>
    </Box>
    <Typography
      sx={{
        fontSize: 12,
        fontWeight: 500,
        textWrap: 'nowrap',
        color: '#7A7A9D',
      }}>
      {name}
    </Typography>
  </Box>
);
