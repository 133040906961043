// import axios from 'axios';
import axios from 'src/utils/axios';
import { postMethodProps } from 'src/types/axios.types';
// export const postRequest = (url, payload, loader, successFunction, errorFunction, customHeader = {}) => dispatch => {
const postRequest = ({
  url,
  payload,
  loader,
  successFunction,
  errorFunction,
  customHeader,
}: postMethodProps) => {
  if (loader) {
    loader(true);
  }
  axios
    .post(url, payload, customHeader)
    .then((res) => {
      if (successFunction) {
        successFunction(res);
      }
    })
    .catch((error) => {
      if (errorFunction) {
        errorFunction(error);
      }
    })
    .finally(() => {
      if (loader) {
        loader(false);
      }
    });
};

export default postRequest;
// const getCustomersList = (perPage = state.incrementPerPage, pageNumber = state.pageNumber, search = state.genericSearch, isLock = state.isLock, isFlagged = state.isFlagged, isTest = state.isTest) => {
//   dispatch(
//     postRequest(
//       "api/Admin/Customer/List",
//       {
//         firstName: "",
//         pageNumber: pageNumber,
//         itemsPerPage: perPage,
//         isAscending: true,
//         genericSearch: search,
//         isLocked: isLock,
//         isFlagged: isFlagged,
//         isTestUser: isTest,
//         cityID:cityFilter?.map(item=>{return item.value})
//       },
//       loader,
//       successFunction,
//       (err) => {
//         error400(err.response)
//       }
//     )
//   );
// }
