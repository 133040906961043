import { makeStyles, Box, Tabs, Tab } from '@material-ui/core';
import useGenericStyles from 'src/styles/useGenericStyles';
// import { Tabs, Tab, Box, makeStyles } from '@material-ui/core';

type TabType = {
  title: string;
  value: string;
};
interface ButtonTabsProps {
  tabs: Array<TabType>;
  selectedTab: string | null;
  setSelectedTab: (newValue: string | null) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& .MuiButtonBase-root': {
      minHeight: '32px',
    },
    '& .MuiTabs-scroller': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tabs: {
    // height: '32px',
    // backgroundColor: '#f5f5f5',
    // borderRadius: theme.shape.borderRadius,
    // boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  },
  tab: {
    // maxHeight: '32px',
    // minWidth: '100px',
    padding: '10px',
    // margin: theme.spacing(1),
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '10px',
    transition: '0.3s',
  },
  selectedTab: {
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 1px 5px 0px #0203030A, 0px 3px 1px 0px #02030305,0px 2px 2px 0px #02030308',
  },
}));

const ButtonTabs = ({ tabs, selectedTab, setSelectedTab }: ButtonTabsProps) => {
  const classes = useStyles();
  // const [value, setValue] = useState(null);
  const genericStyles = useGenericStyles();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        // indicatorColor="none"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        className={classes.tabs}
        centered>
        {tabs.map((tab) => (
          <Tab
            label={tab.title}
            value={tab.value}
            className={`${classes.tab} ${genericStyles.button} ${
              selectedTab === tab.value ? classes.selectedTab : ''
            }`}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default ButtonTabs;
