import { Box } from '@material-ui/core';
import React from 'react';

export const BillingType = ({ value }: { value: string }) =>
  value === 'Opex' ? (
    <Box
      sx={{
        backgroundColor: '#E8E8E8',
        color: '#828282',
        borderRadius: '6px',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
      }}>
      {value}
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: '#FFEDE3',
        color: '#EB5757',
        borderRadius: '6px',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
      }}>
      {value}
    </Box>
  );
