import { Avatar, Box, Typography } from '@material-ui/core';
import React from 'react';

export const NameWithAvatar = ({
  name,
  rowDataObj,
}: {
  name: string;
  rowDataObj: any;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}>
    <Avatar
      src={rowDataObj.avatar ?? ''}
      sx={{
        width: '24px',
        height: '24px',
        marginRight: '12px',
      }}
    />
    <Typography
      sx={{
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 600,
      }}>
      {name}
    </Typography>
  </Box>
);
