import { isUndefined } from 'lodash';
import { postRequest } from 'src/utils/axios';
import parse from 'src/utils/parse';

class TranslationApi {
  async translateText(payload:any):Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/translation`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async detectLang(text:any):Promise<any> {
    const payload = {

      text

    };
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/detect`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }
}

export const translationApi = new TranslationApi();
