import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles/global.css?v1';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <I18nextProvider i18n={i18n}>
                    <App />
                  </I18nextProvider>
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
