import React from 'react';
import setTitle from 'src/utils/setTitle';

const Dashboard = () => {
  setTitle('ITSM | Help - Dashboard');
  return (
    <>
      <h1>Dashboard</h1>
      <img alt="Dashboard screenshot" src="/static/help/dashboard.png" />
      <p>
        After logging into the portal you are shown the stats for your most
        important items. The dashboard is currently divided into 2 sections:
      </p>
      <br />
      <ol>
        <li>
          <h2>Cases by Status</h2>
          <img src="/static/help/widgetcases.png" alt="Cases summary widget" />
          <ol style={{ marginTop: 30 }}>
            <li>
              Shows you a graphical representation of the open, in progress and
              closed cases
            </li>
            <li>Shows the count of cases by status</li>
          </ol>
        </li>
        <li>
          <h2>Recent Cases</h2>
          <img
            src="/static/help/widgetrecentcases.png"
            alt="Recent Cases widget"
            style={{ border: '1px solid #ddd', padding: 15 }}
          />
          <ol style={{ marginTop: 30 }}>
            <li>Shows a list of all recent cases</li>
            <li>
              List cannot be sorted and is displayed based on time with the
              latest cases showing on top
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
};

export default Dashboard;
