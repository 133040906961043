import { Box, Avatar, Typography, CircularProgress } from '@material-ui/core';
import React from 'react';

interface CardProps {
  title: string;
  number: string;
  percentage: any | '';
  loading: boolean;
}

export const CasesOverviewCard = ({
  title,
  number,
  percentage,
  loading,
}: CardProps) => {
  const getIcon = (cardName: string) => {
    switch (cardName) {
      case 'To Do':
        return '/static/icons/openCases.svg';
      case 'Closed':
        return '/static/icons/closedCases.svg';
      case 'In Progress':
        return '/static/icons/inProgressCases.svg';
      case 'Overdue':
        return '/static/icons/overdueCases.svg';
      default:
        return '/static/icons/inProgressCases.svg';
    }
  };

  return (
    <Box
      sx={{
        minWidth: '200px',
        background: 'white',
        borderRadius: '16px',
        display: 'flex',
        padding: '20px',
        columnGap: '15px',
        boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
      }}>
      <Avatar
        src={getIcon(title)}
        sx={{
          borderRadius: '8px',
          height: '46px',
          width: '46px',
        }}
      />
      {loading ? (
        <CircularProgress
          sx={{
            color: '#7A7A9D',
          }}
        />
      ) : (
        <Box>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: 12,
              fontWeight: 700,
              color: '#7A7A9D',
              textWrap: 'nowrap'
            }}>
            {title}
          </Typography>
          <Typography
            sx={{
              color: percentage / 1 > 0 ? '#66CB9F' : '#F16063',
            }}>
            <span
              style={{
                fontFamily: 'Inter',
                fontSize: 20,
                fontWeight: 600,
                color: '#16192C',
              }}>
              {number}
            </span>
            {` ${percentage}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
