export const SortAtoZ = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C4.27614 2 4.5 2.22386 4.5 2.5L4.5 13.5C4.5 13.7761 4.27614 14 4 14C3.72386 14 3.5 13.7761 3.5 13.5L3.5 2.5C3.5 2.22386 3.72386 2 4 2Z"
      fill="#8492A6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35355 11.1464C6.54882 11.3417 6.54882 11.6583 6.35355 11.8536L4.35355 13.8536C4.15829 14.0488 3.84171 14.0488 3.64645 13.8536L1.64645 11.8536C1.45118 11.6583 1.45118 11.3417 1.64645 11.1464C1.84171 10.9512 2.15829 10.9512 2.35355 11.1464L4 12.7929L5.64645 11.1464C5.84171 10.9512 6.15829 10.9512 6.35355 11.1464Z"
      fill="#8492A6"
    />
    <path
      d="M9.66406 7L10.082 5.62891H11.8633L12.2812 7H13.4023L11.6211 1.66797H10.3867L8.59766 7H9.66406ZM11 2.6875L11.6523 4.84375H10.3008L10.9531 2.6875H11Z"
      fill="#8492A6"
    />
    <path
      d="M9.02734 14H12.9609V13.1328H10.3164V13.0781L12.8828 9.35938V8.66797H9.09766V9.53516H11.6055V9.58984L9.02734 13.3086V14Z"
      fill="#8492A6"
    />
  </svg>
);
