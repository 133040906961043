import React from 'react';
import setTitle from 'src/utils/setTitle';

const HelpViewCases = () => {
  setTitle('ITSM | Help - Cases');

  return (
    <>
      <h1>Viewing Cases</h1>
      <img src="/static/help/cases.png" alt="Cases View" />
      <p>
        To view a case, the user simply needs to navigate to the Cases Module
        and select the relevant case. Users will be able to view the case
        details, add comments or attachments if needed and see the current
        status of the case.
        <br />
        <br />
        In case a user is unable to find their required case, we have added a
        search functionality discussed below.
      </p>
      <h2>Search Functions</h2>
      <ol>
        <li>
          <p>
            <strong>Basic Search</strong>
          </p>
          <p>
            Simple search function to either search by case number or case title
          </p>
          <img
            src="/static/help/advancedsearch.png"
            alt="Advanecd Search Control"
          />
        </li>
        <li>
          <p>
            <strong>Advanced Search</strong>
          </p>
          <ul>
            <li>
              Thorough search mechanism to search across multiple parameters
            </li>
            <li>Dropdowns support multi-select for a comprehensive search</li>
          </ul>
          <img
            src="/static/help/advancedsearchmodal.png"
            alt="Advanced Search window"
            style={{ border: '1px solid #ddd', padding: 15, marginTop: 15 }}
          />
        </li>
      </ol>
      <h2>Comments</h2>
      <img
        src="/static/help/commentsandlogs.png"
        alt="Comments and Logs"
        style={{ border: '1px solid #ddd', padding: 15 }}
      />
      Comments serve as a complete journey for the case you are viewing.
      <ul>
        <li>Contains any comments made by users</li>
        <li>Shows the date and timestamps for the actions performed</li>
        <li>Attachments can be added via comments</li>
        <li>
          Change in status, assignee, and escalations can also be viewed in the
          comment sections
        </li>
      </ul>
      <h2>Attachments</h2>
      <p>
        Users can add attachments to cases such as a document or an image,
        depending on the type of information being provided. There are two ways
        to add an attachment:
      </p>
      <ul>
        <li>Attachments can be added at the time of case creation</li>
        <li>
          Attachments can be added via comments section when viewing a case
        </li>
      </ul>
      <h2>Auto Refresh Cycle</h2>
      <img src="/static/help/refreshdropdown.png" alt="Refresh Control" />
      <br />
      The auto refresh button allows users to configure the auto refresh timer
      for the case list.
    </>
  );
};

export default HelpViewCases;
