import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRight from 'src/icons/ChevronRight';

type LinkTypes = {
  name: string;
  to: string;
  color?: string;
};
type SimpleBreadCrumbTypes = {
  data: {
    title: string;
    links?: LinkTypes[];
    color?: string;
  };
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    heading: {
      textTransform: 'capitalize',
    },
    subHeading: {
      textTransform: 'capitalize',
    },
    breadCrumb: {
      '& .colorChange': {
        width: '15px',
        height: '15px',
        borderRadius: '4px',
        background: 'black',
        display: 'inline-flex',
        marginRight: '5px',
        position: 'relative',
        top: '2px',
      },
      '& .MuiBreadcrumbs-li:last-child': {
        userSelect: 'none',
        pointerEvents: 'none',
        textDecoration: 'none',
        '&:hover a': {
          textDecoration: 'none',
          cursor: 'default',
        },
      },
    },
  })
);

const SimpleBreadCrumb = ({ data }: SimpleBreadCrumbTypes) => {
  // const {data}=props;
  const classes = useStyles();
  return (
    <>
      <Typography
        color="textPrimary"
        variant="h3"
        className={classes.heading}
      >
        {data?.title}
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight fontSize="small" />}
        sx={{ mt: 1 }}
        className={classes.breadCrumb}
      >
        {data?.links?.map((link) => {
          if (link?.color) {
            return (

              <Link
                color="textPrimary"
                component={RouterLink}
                to={link?.to}
                variant="subtitle2"
                className={classes.subHeading}
                key={link.name}
              >
                <span
                  className="colorChange"
                  style={{ background: link?.color }}
                />
                {' '}
                {link?.name}
              </Link>
            );
          }

          return (
            <Link
              color="textPrimary"
              component={RouterLink}
              to={link?.to}
              variant="subtitle2"
              className={classes.subHeading}
              key={link.name}
            >
              {link?.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </>
  );
};

export default SimpleBreadCrumb;
