import { isUndefined, isEmpty, isNull } from 'lodash';

const capitalize = (text: string = '', isAddress = false, prepend = '', append = '') => {
  if (!isNull(text) && !isUndefined(text) && !isEmpty(text) && text !== 'null') {
    text = text?.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase());
  } else if (!isAddress) {
    text = 'N/A';
  }

  if (prepend?.length > 0) {
    text = prepend + text;
  }
  if (append?.length > 0) {
    text += append;
  }

  return !isNull(text) ? text : '';
};

export default capitalize;
