import React from 'react';
import setTitle from 'src/utils/setTitle';

const HelpCases = () => {
  setTitle('ITSM | Help - Cases');
  return (
    <>
      <h1>Cases</h1>
      <img src="/static/help/cases.png" alt="Cases View" />
      <p>
        The cases module is where all the action happens. It contains a list of
        all the cases that have been created for your projects. You can view all
        necessary information about the cases in the main page:
      </p>
      <ol>
        <li>
          <strong>Case No: </strong>
          Unique ID for the case, naming convention starts with case type
          followed by numeric value
        </li>
        <li>
          <strong>Title: </strong>
          Title explaining more information about the case
        </li>
        <li>
          <strong>Reporter: </strong>
          Name/ID of person who created the case
        </li>
        <li>
          <strong>Assignee: </strong>
          Name/ID of person who is working on the case
        </li>
        <li>
          <strong>Type: </strong>
          Refers to the type of case
        </li>
        <li>
          <strong>Priority: </strong>
          Severity of the request
        </li>
        <li>
          <strong>Status: </strong>
          The current state of the case
        </li>
      </ol>

      <h2>Case Types</h2>
      <ol>
        <li>
          <strong>General Support</strong>
          <p>
            Caters to your general requests regarding applications, services
          </p>
        </li>
        <li>
          <strong>Redhat Support</strong>
          <p>Cases related to Redhat products</p>
        </li>
        <li>
          <strong>Administrative</strong>
          <p>Cases regarding administrative tasks</p>
        </li>
        <li>
          <strong>Application Support</strong>
          <p>Cases for one of the IT22 application support</p>
        </li>
        <li>
          <strong>Application Bug</strong>
          <p>Cases related to bugs in IT22 applications</p>
        </li>
        <li>
          <strong>Application Feature Request</strong>
          <p>
            Cases regarding any feature you would like to see in IT22
            applications
          </p>
        </li>
      </ol>
    </>
  );
};

export default HelpCases;
