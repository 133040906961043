const FilterIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5307 0.666748H1.46936C1.3412 0.666638 1.21572 0.703473 1.10795 0.772842C1.00019 0.842211 0.9147 0.941173 0.86173 1.05788C0.808759 1.17458 0.790551 1.30408 0.809286 1.43087C0.82802 1.55765 0.882903 1.67635 0.967363 1.77275L4.50203 5.81142C4.60834 5.93307 4.66685 6.08919 4.6667 6.25075V9.50008C4.6667 9.55183 4.67874 9.60287 4.70189 9.64915C4.72503 9.69544 4.75863 9.7357 4.80003 9.76675L6.80003 11.2667C6.84955 11.3039 6.90844 11.3265 6.97009 11.3321C7.03175 11.3376 7.09373 11.3259 7.1491 11.2982C7.20447 11.2705 7.25103 11.228 7.28358 11.1753C7.31612 11.1227 7.33336 11.062 7.33336 11.0001V6.25075C7.3332 6.08919 7.39172 5.93307 7.49803 5.81142L11.0327 1.77208C11.41 1.34141 11.1034 0.666748 10.5307 0.666748Z"
      stroke="#8492A6"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
  </svg>
);

export default FilterIcon;
