import { combineReducers } from 'redux';
import hideMenuSlice from './slices/hideMenuSlice';
import userInfoSlice from './slices/userInfoSlice';
import organizationDetailsSlice from './slices/organizationDetailsSlice';
import caseDataSlice from './slices/caseDataSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import snackBarSlice from './slices/snackBarSlice';
import projectsSlice from './slices/projectsSlice';
import rolesSlice from './slices/rolesSlice';

const caseInfoPersistConfig = {
  key: 'caseInfo', // You can change the key as needed
  storage, // Use your preferred storage
};

const persistedCaseInfoReducer = persistReducer(
  caseInfoPersistConfig,
  caseDataSlice
);

const rootReducer = combineReducers({
  hideMenu: hideMenuSlice,
  userInfo: userInfoSlice,
  orgInfo: organizationDetailsSlice,
  caseInfo: persistedCaseInfoReducer,
  snackbar: snackBarSlice,
  projects: projectsSlice,
  roles: rolesSlice,
});

export default rootReducer;
