import React from 'react';
import setTitle from 'src/utils/setTitle';

const Introduction = () => {
  setTitle('ITSM | Help');
  return (
    <>
      <h1>Introduction</h1>
      <p>
        IT22 B.V. is excited to launch a one stop solution for our clients to
        communicate with us. With the ERP platform, you get a cloud application
        to log your requests for a swift turnaround from IT22.
        <br />
        <br />
        This guide will help you understand the functionality being offered via
        the platform as well as a complete walkthrough of how to use the
        application appropriately.
      </p>
    </>
  );
};

export default Introduction;
