import React from 'react';
import Modal from 'react-modal';
import CancelIcon from '@material-ui/icons/Cancel';

Modal.setAppElement('#root'); // Set the root element for accessibility

const SImpleModal = ({ isOpen, onClose, imageUrl } :any) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
    },
  };

  let primaryColor = '#EE3235';

  const whiteLabel: any = JSON.parse(localStorage.getItem('white_label'));
  primaryColor =
    whiteLabel?.primaryColor?.length > 0 ? whiteLabel?.primaryColor : '#EE3235';


  const closeButtonStyle: any = {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    cursor: 'pointer',
    color: primaryColor,
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
    >
      <div style={{ position: 'relative', maxHeight: '75vh' }}>
        <CancelIcon
          onClick={onClose}
          style={closeButtonStyle}
        />

        <img
          src={imageUrl}
          alt="Preview"
          style={{ maxWidth: '100%', maxHeight: '100%', marginBottom: '10px' }}
        />
      </div>
    </Modal>
  );
};

export default SImpleModal;
