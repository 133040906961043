import {
  Box,
  // Button,
  Container,
  // Grid,
  // Tab,
  // Tabs,
  // Menu,
  // MenuItem,
  // Paper,
} from '@material-ui/core';
// import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
// import { useTranslation } from 'react-i18next';
import { organizationApi } from 'src/api/organizationApi';
// import SingleDeleteConfirmation from 'src/components/widgets/Dialog/SingleDeleteConfirmation';
// import useSettings from 'src/hooks/useSettings';
import { TableProps } from 'src/types/components/tableList.types';
// import SearchBar from '../../widgets/searchBar/SearchBar';
// import TableProjects from '../../widgets/tables/TableProjects';
import setTitle from 'src/utils/setTitle';
import { Header } from 'src/components/Header';
import CustomizedTable from 'src/components/tables/CustomizedTable';
import { NameWithColor } from 'src/components/tables/renderers/NameWithColor';
import useGenericStyles from 'src/styles/useGenericStyles';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import ButtonTabs from 'src/components/tabs/ButtonTabs';
import { projectApi } from 'src/api/projectApi';
// import ButtonTabs from 'src/components/tabs/ButtonTabs';

// const useStyles = makeStyles<Theme>(() =>
//   createStyles({
//     borderlessSelect: {
//       '& fieldset': {
//         border: 'none',
//       },
//     },
//     btn: {
//       width: '36px',
//       height: '36px',
//       padding: 0,
//       minWidth: 'initial',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       borderRadius: '4px',
//       color: '#fff',
//       // marginRight: '8px',
//       // marginBottom: '7px',
//       '& .MuiButton-iconSizeMedium': {
//         margin: '0',
//       },
//       '& .MuiSvgIcon-root': {
//         fontSize: '16px',
//       },
//       '&:hover': {
//         cursor: 'pointer',
//       },
//     },
//     activeButton: {
//       background: 'rgba(7, 83, 0, 0.1)',
//       color: 'rgba(29, 122, 21, 1)',
//       width: '100px',
//     },
//     inActiveButton: {
//       background: 'rgba(255, 161, 161, 0.3)',
//       color: 'red',
//       width: '100px',
//     },
//     table: {
//       '& .MuiTableCell-head': {
//         padding: ' 10px 8px',
//       },
//     },
//     breadCrumbsContainer: {
//       height: '40px',
//       display: 'flex',
//       alignItems: 'center',
//     },
//     tabsAreaProject: {
//       margin: '16px 10px 0',
//       '& .MuiTabs-flexContainer': {
//         borderBottom: '1px solid #231F20',
//       },
//       '& .tabsArea': {
//         margin: '16px 0 0',
//         '& .MuiTab-root': {
//           height: '32px',
//           minHeight: 'inherit',
//           margin: '0',
//           minWidth: '105px',
//         },
//         '& hr': {
//           border: 0,
//           borderTop: '0.8px solid #E0E0E0',
//           margin: '21px 0 0',
//           borderWidth: '0.8px',
//         },
//         '& .MuiTabs-indicator ': {
//           display: 'none',
//         },
//         "& .Mui-selected[aria-selected='true']": {
//           background: '#FFF',
//           color: '#231F20',
//           border: '1px solid #231F20',
//           borderBottom: '1px solid #fff',
//           position: 'relative',
//           top: 1,
//         },
//       },
//     },
//   })
// );

const ProjectSection: FC<TableProps> = () => {
  // const classes = useStyles();
  setTitle('ITSM | Projects');
  const [count, setCount] = useState<number>(0);
  // const { settings } = useSettings();
  // const { t } = useTranslation(['tables', 'projects']);
  const [page, setPage] = useState<any>(0);
  const [limit, setLimit] = useState<any>(25);
  const [search, setSearch] = useState<string>('');
  // const [bulkIds, setBulkIds] = useState<string>('');
  // const baseUrl = '/projects';
  const [projectData, setProjectData] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [favourites, setFavourites] = useState<Array<any>>([]);
  const classes = useGenericStyles();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // type SortDataTypes = {
  //   name: 'asc' | 'desc';
  //   description: 'asc' | 'desc';
  //   domain: 'asc' | 'desc';
  //   website: 'asc' | 'desc';
  //   cases: 'asc' | 'desc';
  // };
  // const [sortData, setSortData] = useState<SortDataTypes>({
  //   name: JSON.parse(localStorage.getItem('projPagePg'))?.name || 'asc',
  //   description:
  //     JSON.parse(localStorage.getItem('projPagePg'))?.description || 'asc',
  //   domain: JSON.parse(localStorage.getItem('projPagePg'))?.domain || 'asc',
  //   website: JSON.parse(localStorage.getItem('projPagePg'))?.website || 'asc',
  //   cases: JSON.parse(localStorage.getItem('projPagePg'))?.cases || 'asc',
  // });
  const [column, setColumn] = useState<string>('');
  const [sortVal, setSortVal] = useState<string>('');
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const [deleteDialogueVisible, setDeleteDialogueVisible] =
  //   useState<boolean>(false);

  const [fetchingData, setFetchingData] = useState(false);

  const handleTabChange = (newValue: string | null) => {
    setSelectedTab(newValue);
    if (newValue === 'byCases') {
      setColumn('totalCases');
      setSortVal('desc');
    } else if (newValue === 'alphabetically') {
      setColumn('name');
      setSortVal('desc');
    } else {
      setColumn('');
      setSortVal('');
    }
  };

  const getAllProjects = async () => {
    setFetchingData(true);
    try {
      const response = await organizationApi.getAllProjects(
        page,
        limit,
        search,
        column,
        sortVal
      );
      if (response?.status === 200) {
        setProjectData(response?.data?.data);
        setCount(response?.data?.totalRecords);
        setFetchingData(false);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        setProjectData([]);
        setCount(0);
        toast.error(error?.response?.data?.message);
        setFetchingData(false);
      }
    }
  };

  const getFavProjects = async () => {
    // setFetchingData(true);
    try {
      const response = await projectApi.getAllFavouriteProjects();
      if (response?.status === 200) {
        // eslint-disable-next-line no-underscore-dangle
        const onlyIds = response.data.map((project) => project?._id);
        setFavourites(onlyIds);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching projects');
      }
    }
  };

  const handleSelectFavourite = async (id) => {
    if (favourites.includes(id)) {
      setFavourites((prevSelectedFavourites) =>
        prevSelectedFavourites.filter((rowId) => rowId !== id)
      );
      const response = await projectApi.removeUserFavProject(id);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error('Couldnt remove from favourites');
        setFavourites((prevSelectedFavourites) => [
          ...prevSelectedFavourites,
          id,
        ]);
      }
    } else {
      setFavourites((prevSelectedFavourites) => [
        ...prevSelectedFavourites,
        id,
      ]);
      const response = await projectApi.addUserFavProject(id);
      if (response.status === 201) {
        toast.success(response.data.message);
      } else {
        toast.error('Couldnt add to favourites');
        setFavourites((prevSelectedFavourites) =>
          prevSelectedFavourites.filter((rowId) => rowId !== id)
        );
      }
    }
  };

  // const deleteProjectById = async (id: String) => {
  //   try {
  //     const res = await organizationApi.deleteProjByOrg(id);
  //     if (res?.status === 200) {
  //       toast.success('Deleted successfully');
  //       getAllProjects();
  //       setBulkIds('');
  //     } else if (res?.status === 204) {
  //       toast.error('Project Not Exists');
  //     }
  //   } catch (error) {
  //     toast.error('Internal Server Error');
  //   }
  // };

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handleLimit = (temp: number) => {
    setLimit(temp);
  };
  // const handleAddBulkIds = (id: string) => {
  //   setBulkIds(id);
  // };

  // const handleRemoveBulkIds = (id: string) => {
  //   setBulkIds(id);
  // };

  const handleSearch = (val) => {
    setSearch(val);
  };

  // const handleProjectDelete = (id) => {
  //   deleteProjectById(id);
  // };
  // const toggleSortData = (fieldName: string) => {
  //   type SortStatusType = 'asc' | 'desc';
  //   let sortStatus: SortStatusType = 'asc';
  //   // eslint-disable-next-line default-case
  //   switch (fieldName) {
  //     case 'name':
  //       if (sortData.name === 'asc') {
  //         sortStatus = 'desc';
  //       }
  //       setSortData({
  //         name: sortStatus,
  //         description: 'asc',
  //         domain: 'asc',
  //         website: 'asc',
  //         cases: 'asc',
  //       });
  //       break;
  //     case 'description':
  //       if (sortData.description === 'asc') {
  //         sortStatus = 'desc';
  //       }
  //       setSortData({
  //         name: 'asc',
  //         description: sortStatus,
  //         domain: 'asc',
  //         website: 'asc',
  //         cases: 'asc',
  //       });
  //       break;
  //     case 'domain':
  //       if (sortData.domain === 'asc') {
  //         sortStatus = 'desc';
  //       }
  //       setSortData({
  //         name: 'asc',
  //         description: 'asc',
  //         domain: sortStatus,
  //         website: 'asc',
  //         cases: 'asc',
  //       });
  //       break;
  //     case 'website':
  //       if (sortData.website === 'asc') {
  //         sortStatus = 'desc';
  //       }
  //       setSortData({
  //         name: 'asc',
  //         description: 'asc',
  //         domain: 'asc',
  //         website: sortStatus,
  //         cases: 'asc',
  //       });
  //       break;
  //     case 'cases':
  //       if (sortData.cases === 'asc') {
  //         sortStatus = 'desc';
  //       }
  //       setSortData({
  //         name: 'asc',
  //         description: 'asc',
  //         domain: 'asc',
  //         website: 'asc',
  //         cases: sortStatus,
  //       });
  //       break;
  //   }
  // };
  // const getAllSortedData = async (fieldName: string, value: string) => {
  //   let columnValue = '';
  //   const fieldToColumnMap = {
  //     name: 'name',
  //     description: 'description',
  //     domain: 'domain',
  //     website: 'website',
  //     cases: 'totalCases',
  //   };

  //   columnValue = fieldToColumnMap[fieldName] || 'defaultSort';
  //   setColumn(columnValue);
  //   setSortVal(value);
  // };
  // const columns: Array<any> = [
  //   {
  //     id: 'name',
  //     name: t('tables:table_columnHeading_name'),
  //     value: 'name',
  //     isSort: true,
  //     toggleSortData,
  //     direction: sortData?.name,
  //   },
  //   {
  //     id: 'description',
  //     name: t('tables:table_columnHeading_description'),
  //     value: 'description',
  //     isSort: true,
  //     toggleSortData,
  //     direction: sortData?.description,
  //   },
  //   {
  //     id: 'domain',
  //     name: t('tables:table_columnHeading_domain'),
  //     value: 'domain',
  //     isSort: true,
  //     toggleSortData,
  //     direction: sortData?.domain,
  //   },
  //   {
  //     id: 'website',
  //     name: t('tables:table_columnHeading_website'),
  //     value: 'website',
  //     isSort: true,
  //     toggleSortData,
  //     direction: sortData?.website,
  //   },
  //   {
  //     id: 'cases',
  //     name: t('tables:table_columnHeading_cases'),
  //     value: 'totalCases',
  //     isSort: true,
  //     toggleSortData,
  //     direction: sortData?.cases,
  //   },
  // ];

  const tabs = [
    { title: 'No. of cases', value: 'byCases' },
    { title: 'Alphabetically', value: 'alphabetically' },
  ];
  const customColumns = [
    {
      field: 'name',
      headerName: 'PROJECT NAME',
      render: (columnValue) => <NameWithColor name={columnValue} />,
    },
    { field: 'website', headerName: 'WEBSITE' },
    {
      field: 'totalCases',
      headerName: 'TOTAL CASES',
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      render: (columnValue, data) => (
        <Box
          sx={{
            '& > p': {
              padding: 0,
              margin: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllProjects();
    //
    // const projPagePg = {
    // projPageLimit: limit,
    // ProjPageNo: page,
    // search,
    // name: sortData?.name,
    // description: sortData?.description,
    // domain: sortData?.domain,
    // website: sortData?.website,
    // cases: sortData?.cases,
    // column,
    // sort: sortVal,
    // };
    // localStorage.setItem('projPagePg', JSON.stringify(projPagePg));
  }, [
    page,
    limit,
    search,
    // sortData?.name,
    // sortData?.description,
    // sortData?.domain,
    // sortData?.website,
    // sortData?.cases,
    column,
    // sortVal,
  ]);

  useEffect(() => {
    getFavProjects();
  }, []);

  return (
    <>
      <Header title="Projects" />
      <Container className={classes.container} maxWidth={false}>
        {/* <Grid container> */}
        {/* <Grid item xs={12} md={4} sx={{ display: 'flex', gap: 1 }}> */}
        {/* <Paper sx={{ display: 'none' }}>
                <Button
                  variant="text"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    px: 2,
                    background: 'white',
                    color: 'black',
                    height: '40px',
                  }}>
                  {t('projects:projects_actionDropdown_label')}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  <MenuItem
                    disabled={bulkIds.length === 0}
                    onClick={() => {
                      handleClose();
                      setDeleteDialogueVisible(true);
                    }}>
                    {t('projects:projects_actionDropdown_delete_label')}
                  </MenuItem>
                </Menu>
              </Paper> */}
        {/* <SearchBar
                onSearch={handleSearch}
                value={search}
                placeholderData={t('projects:projects_search_placeholder')}
              /> */}

        {/* <Paper>
              <Select
                value={0}
                className={classes.borderlessSelect}
                // onChange={handleAutoRefresh}
                sx={{ height: '40px', marginLeft: 1, fontWeight: 'normal' }}
              >
                <MenuItem
                  selected
                  value={0}
                >
                  {t('projects:projects_refreshDropdown_label')}
                </MenuItem>
                <MenuItem value={30}>
                  30
                  {' '}
                  {t('projects:projects_refreshDropDown_options_seconds')}
                </MenuItem>
                <MenuItem value={60}>
                  1
                  {' '}
                  {t('projects:projects_refreshDropDown_options_minute')}
                </MenuItem>
                <MenuItem value={300}>
                  5
                  {' '}
                  {t('projects:projects_refreshDropDown_options_minutes')}
                </MenuItem>
                <MenuItem value={900}>
                  15
                  {' '}
                  {t('projects:projects_refreshDropDown_options_minutes')}
                </MenuItem>
              </Select>
            </Paper> */}
        {/* </Grid> */}
        {/* <Grid
            item
            xs={12}
            md={8}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          > */}
        {/* <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              className={classes.btn}
              disabled={!bulkIds.length}
              onClick={() => setDeleteDialogueVisible(true)}
            /> */}
        {/* </Grid> */}
        {/* </Grid> */}
        <Box
          sx={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <SearchBar
            onSearch={handleSearch}
            value={search}
            placeholderData="Search ..."
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}>
            <Box
              component="span"
              sx={{
                fontSize: 12,
                fontWeight: 700,
                color: '#7A7A9D',
              }}>
              Sort By:
            </Box>
            <ButtonTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={handleTabChange}
            />
          </Box>
        </Box>
        <CustomizedTable
          heading="Projects"
          data={projectData}
          columns={customColumns}
          page={page}
          limit={limit}
          handleLimit={handleLimit}
          handlePage={handlePage}
          totalRecords={count}
          loading={fetchingData}
          navigateUrl="/projects"
          showFavouriteButtons
          favourites={favourites}
          handleClickFavourite={handleSelectFavourite}
        />
        {/* <TableProjects
            data={projectData}
            handlePage={handlePage}
            handleLimit={handleLimit}
            handleSearch={handleSearch}
            count={count}
            page={page}
            limit={limit}
            bulkIds={bulkIds}
            handleAddBulkIds={handleAddBulkIds}
            handleRemoveBulkIds={handleRemoveBulkIds}
            allowedColumns={columns}
            redirectUrl={baseUrl}
            editRouteUrl={`${baseUrl}`}
            getAllSortedData={getAllSortedData}
            column={column}
            setColumn={setColumn}
            sortVal={sortVal}
            setSortVal={setSortVal}
            setSortData={setSortData}
            localStorageValue="projPagePg"
          /> */}
        {/* <SingleDeleteConfirmation
          isOpen={deleteDialogueVisible}
          setDeleteDialogueVisible={setDeleteDialogueVisible}
          selectedItem={bulkIds}
          handleDelete={handleProjectDelete}
          text="Do you want to delete this project?"
        /> */}
      </Container>
    </>
  );
};

export default ProjectSection;
