import styled from '@emotion/styled';
import {
  Box,
  CircularProgress,
  //   CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import CustomizedTable from 'src/components/tables/CustomizedTable';
import { BillingType } from 'src/components/tables/renderers/BillingType';
import { Priority } from 'src/components/tables/renderers/Priority';
import useGenericStyles from 'src/styles/useGenericStyles';
import capitalize from 'src/utils/capitalize';
import convertSecondsToTimeString from 'src/utils/convertSecondsToTimeString';
import { CustomButton2 } from '../buttons/CustomButton2';

type CustomDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  selectedRowsData: Array<any>;
  getAllData: () => void;
};

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    minWidth: '60vw',
    '& .MuiPaper-root': {
      minWidth: '100%',
      '& .MuiTableRow-head ': {
        '& .MuiTableCell-head': {
          borderTop: 'none',
        },
        '& :first-child': {
          borderTopLeftRadius: '16px',
        },
        '& :last-child': {
          borderTopRightRadius: '16px',
        },
      },
    },
  },
  '& .MuiDialogContent-root': {
    padding: '20px',
  },
  '& .MuiDialogActions-root': {
    padding: '20px',
  },
}));

const InfoCard = ({
  name,
  value,
  loading,
}: {
  name: string;
  value: any;
  loading: boolean;
}) => (
  <Box
    sx={{
      padding: '10px 20px',
      borderRadius: '10px',
      boxShadow:
        '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
      flexGrow: 1,
    }}>
    <Typography
      sx={{
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 700,
        color: '#7A7A9D',
      }}>
      {name}
    </Typography>
    {loading ? (
      <CircularProgress
        sx={{
          color: 'red',
        }}
        size="20px"
      />
    ) : (
      <Box
        sx={{
          fontFamily: 'Inter',
          fontSize: 20,
          fontWeight: 600,
          color: '#16192C',
        }}>
        {value}
      </Box>
    )}
  </Box>
);
export default function BulkApproveDialog({
  isOpen,
  handleClose,
  selectedRowsData,
  getAllData,
}: CustomDialogProps) {
  const [infoCards, setInfoCards] = React.useState({
    'Total Estimate': 'N/A',
    Projects: 'N/A',
    OpEx: 'N/A',
    CapEx: 'N/A',
  });
  const [filteredUnapprovedData, setFilteredUnapprovedData] = React.useState(
    []
  );
  const theme = useTheme();
  const [unApprovedCount, setUnApprovedCount] = React.useState(0);
  const [fetchingData, setFetchingData] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [radialBarOptions, setRadialBarOptions] = React.useState({
    series: [0],
    chart: {
      // type: 'radialBar',
      offsetY: -10,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '100%',
          margin: 1, // margin is in pixels
          // dropShadow: {
          //   enabled: true,
          //   top: 2,
          //   left: 0,
          //   color: '#999',
          //   opacity: 1,
          //   blur: 2,
          // },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontFamily: 'Inter',
            fontSize: '13px',
            fontWeight: 700,
            formatter(val) {
              return `${val.toFixed(0)}%`; // Display the value as a whole number percentage
            },
          },
        },
      },
    },
    fill: {
      type: 'solid',
      colors: [theme.palette.primary.main],
    },
  });

  const calculateData = (unapproved) => {
    const filteredData = selectedRowsData.filter(
      (rowObj) => rowObj?.isApproved === false
    );

    const totalETAinSeconds = filteredData.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue?.timeEstimate ?? 0,
      0
    );
    const timeinString = convertSecondsToTimeString(totalETAinSeconds);

    const totalOpex = filteredData.filter(
      (item) => item?.expense === 'Opex'
    ).length;

    const totalCapex = filteredData.filter(
      (item) => item?.expense === 'Capex'
    ).length;

    const totalProjects = new Set(
      filteredData.map((rowObj) => rowObj?.project?.id)
    );

    setFilteredUnapprovedData(filteredData);

    setInfoCards({
      'Total Estimate': timeinString || 'N/A',
      Projects: totalProjects.size?.toString() || 'N/A',
      OpEx: totalOpex?.toString() || 'N/A',
      CapEx: totalCapex?.toString() || 'N/A',
    });

    if (filteredData.length && unapproved) {
      setRadialBarOptions({
        ...radialBarOptions,
        series: [(filteredData.length / unapproved) * 100],
      });
    }
  };

  const classes = useGenericStyles();

  const getUnApprovedCount = async () => {
    setFetchingData(true);
    try {
      const response = await casesApi.advanceSearch('isApproved=false');
      if (response?.status === 200) {
        setUnApprovedCount(response?.data?.totalRecords);
        setFetchingData(false);
        calculateData(response?.data?.totalRecords);
      }
      if (response.status === 204) {
        setUnApprovedCount(0);
        setFetchingData(false);
      }

      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleApproveAllCases = async () => {
    setSubmitting(true);
    const payload = {
      idList: filteredUnapprovedData.map((caseObj) => caseObj.id),
    };
    try {
      const response = await casesApi.bulkApproveCases(payload);
      if (response?.status === 200) {
        toast.success(response.data.message || 'Approved Successfully');
        // setUnApprovedCount(response?.totalRecords);
        setSubmitting(false);
        // calculateData();
        handleClose();
        getAllData();
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const customColumns = [
    {
      field: 'billableAccount',
      headerName: 'Account',
      width: '150px',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(
            data?.billingAccountType?.name?.length > 0
              ? data?.billingAccountType?.name
              : 'N/A'
          )}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(
            data?.billingAccountType?.name?.length > 0
              ? data?.billingAccountType?.name
              : 'N/A'
          )}
        </Typography>
      ),
    },
    {
      field: 'caseKey',
      headerName: 'CASE#',
      width: '130px',
    },
    {
      field: 'summary',
      headerName: 'Title',
      width: '150px',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(data?.summary)}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(data?.summary)}
        </Typography>
      ),
    },
    {
      field: 'timeEstimate',
      headerName: 'Est',
      render: (columnValue) =>
        capitalize(columnValue ? convertSecondsToTimeString(columnValue) : ''),
      // filterable: true,
    },
    {
      field: 'expense',
      headerName: 'Expense',
      render: (columnValue) =>
        columnValue ? <BillingType value={columnValue} /> : 'N/A',
    },
    {
      field: 'project',
      headerName: 'Project',
      render: (columnValue) => (
        <span
          style={{
            fontSize: '12px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(columnValue?.name ?? '')}
        </span>
      ),
      width: '150px',
      filterable: true,
    },
    {
      field: 'priority',
      headerName: 'PRIORITY',
      render: (columnValue) => (
        <Priority priority={capitalize(columnValue?.name ?? '')} />
      ),
    },
    {
      field: 'sprint',
      headerName: 'Sprint',
      render: (columnValue) =>
        columnValue?.name?.length > 0 ? capitalize(columnValue?.name) : 'N/A',
    },
    {
      field: 'releaseVersion',
      headerName: 'Release Version',
      render: (columnValue, data) => (
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {data?.releaseVersion?.length > 0
            ? data?.releaseVersion[0]?.name
            : 'N/A'}
        </Box>
      ),
    },
  ];

  React.useEffect(() => {
    // calculateData();   // This call is moved after unapproved count api to have correct data in hand for time being
    getUnApprovedCount();
  }, []);

  return (
    <BootstrapDialog
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      open={isOpen}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Typography className={classes.smallHeading}>
          Bulk Approval of unapproved cases
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Stack sx={{ height: '12.896 rem' }} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Stack spacing="16px">
                <Box
                  sx={{
                    padding: '30.5px 20px',
                    borderRadius: '10px',
                    boxShadow:
                      '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
                    flexGrow: 1,
                  }}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#16192C',
                    }}>
                    {`${filteredUnapprovedData.length} Unapproved ${
                      filteredUnapprovedData.length > 1 ? 'Cases' : 'Case'
                    } selected`}
                  </Typography>
                  {fetchingData ? (
                    <CircularProgress
                      sx={{
                        color: 'red',
                        marginTop: '5px',
                      }}
                      size="20px"
                    />
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: '#425466',
                        }}>
                        {`out of ${unApprovedCount || 0} unapproved cases`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: '#425466',
                        }}>
                        {`${
                          unApprovedCount - filteredUnapprovedData.length || 0
                        } case(s) have missing Est Quotation`}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                  }}>
                  {Object.keys(infoCards).map((key) => (
                    <InfoCard
                      name={key}
                      value={infoCards[key]}
                      loading={fetchingData}
                    />
                  ))}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  padding: '10px 20px',
                  borderRadius: '10px',
                  boxShadow:
                    '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
                  height: '12.896 rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 700,
                      color: '#7A7A9D',
                    }}>
                    Selected / Total unapproved
                  </Typography>
                </Box>
                <ReactApexChart
                  sx={{ height: '12.896 rem' }}
                  options={radialBarOptions}
                  series={radialBarOptions.series}
                  type="radialBar"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: theme.palette.primary.main,
                        width: '8px',
                        height: '8px',
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        marginLeft: '10px',
                        color: '#7A7A9D',
                      }}>
                      Selected
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Box
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: '#A6B7D4',
                        width: '8px',
                        height: '8px',
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        marginLeft: '10px',
                        color: '#7A7A9D',
                      }}>
                      Total unapproved
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: '100%',
            }}>
            <CustomizedTable
              columns={customColumns}
              data={filteredUnapprovedData}
              showPagination={false}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}>
          <CustomButton2
            sx={{
              color: 'black',
              boxShadow:
                '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
            }}
            className={classes.button}
            onClick={handleClose}>
            Cancel
          </CustomButton2>
          <CustomButton2
            backgroundColor={theme.palette.primary.main}
            className={classes.button}
            disabled={filteredUnapprovedData.length < 1}
            onClick={handleApproveAllCases}>
            {submitting ? (
              <CircularProgress
                sx={{
                  color: '#FFFFFF',
                }}
                size="20px"
              />
            ) : (
              'Approve all selected cases'
            )}
          </CustomButton2>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
}
