import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authApi } from 'src/api/authApi';
import useAuth from 'src/hooks/useAuth';
import { REGEX } from 'src/utils/constants';
import * as Yup from 'yup';

// --------------------> START <--------------------
const PasswordResetAmplify: FC = () => {
  const itemsRef = useRef([]);
  const { resetIsFirstTime } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState({
    password: false,
    rePassword: false,
  });
  const handleNewPassword = () => {
    setShow({ ...show, password: !show.password });
  };
  const handleReEnterPassword = () => {
    setShow({ ...show, rePassword: !show.rePassword });
  };

  const useStyles = makeStyles<Theme>(() =>
    createStyles({
      formLogin: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiOutlinedInput-root': {
          borderRadius: '9px',
        },
        '& .MuiFormControl-root': {
          marginBottom: '18px',
        },
      },
      btn: {
        width: '180px',
        margin: '11px auto',
        display: 'block',
        borderRadius: '4px',
        height: '49px',
        border: 'none',
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, 6);
  }, []);

  return (
    <Formik
      initialValues={{
        password: '',
        rePassword: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().trim()
          .min(8, 'Minium 8 characters required')
          .max(20, 'Maximum 20 characters allowed')
          .matches(REGEX.password, 'At least one uppercase, one lowercase, one number & one special character allowed')
          .required('Password required'),
        rePassword: Yup.string().trim().required('Password required')
          // eslint-disable-next-line func-names
          .test('Password match', 'Password not match', function (value) {
            // eslint-disable-next-line react/no-this-in-sfc
            return value === this.parent.password;
          })
      })}
      onSubmit={async (
        values,
        { setSubmitting }
      ): Promise<void> => {
        try {
          setSubmitting(true);
          const accessToken = localStorage.getItem('accessTokenForReset');
          const handleRequest = async (id:string) => {
            const response: any = await authApi.reset(id, values?.password?.trim());
            if (response?.status === 200) {
              toast.success('Password Reset Successfully');
              localStorage.removeItem('accessTokenForReset');
            }
          };
          if (accessToken) {
            const decode: any = jwtDecode(accessToken);
            handleRequest(decode?.sub);
            await resetIsFirstTime();
            // const response: any = await authApi.reset(decode?.sub, values?.password?.trim());
            // if (response?.status === 200) {
            //   toast.success('Password Reset Successfully');
            //   localStorage.removeItem('accessTokenForReset');
            //   await resetIsFirstTime();
            // }
          } else if (searchParams.get('id')) {
            handleRequest(searchParams.get('id'));
            navigate('/login');
            // const response: any = await authApi.reset(searchParams.get('id'), values?.password?.trim());
            // if (response?.status === 200) {
            //   toast.success('Password Reset Successfully');
            //   localStorage.removeItem('accessTokenForReset');
            //   navigate('/login');
            // }
          } else {
            toast.error('Reset Password Link Expired');
          }
          setSubmitting(false);
        } catch (error) {
          if (Array.isArray(error)) {
            error.forEach((errorMessage) => {
              toast.error(errorMessage);
            });
          } else {
            toast.error(error);
          }
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        isSubmitting,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formLogin}
        >
          <TextField
            id="newPassword-password-textfield"
            type={show.password ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            label="New password"
            name="password"
            onBlur={handleBlur}
            error={Boolean(touched?.password && errors?.password)}
            helperText={touched?.password && errors?.password}
            // className={clsx(classes.margin, classes.textField)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleNewPassword}
                    onMouseDown={handleNewPassword}
                    edge="end"
                  >
                    {show.password ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="reEnterPassword-password-textfield"
            type={show.rePassword ? 'text' : 'password'}
            value={values.rePassword}
            onChange={handleChange('rePassword')}
            label="Re-enter password"
            name="rePassword"
            onBlur={handleBlur}
            error={Boolean(touched?.rePassword && errors?.rePassword)}
            helperText={touched?.rePassword && errors?.rePassword}
            // className={clsx(classes.margin, classes.textField)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleReEnterPassword}
                    onMouseDown={handleReEnterPassword}
                    edge="end"
                  >
                    {show.rePassword ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Button
            color="secondary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.btn}
          >
            Confirm
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default PasswordResetAmplify;
