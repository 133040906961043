import styled from '@emotion/styled';
import DateRangePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalenderIcon } from 'src/icons/Calender';

interface DatePickerProps {
  startDate: any;
  setStartDate: (string) => void;
  endDate: any;
  setEndDate: (string) => void;
}

const DateInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  // padding: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  &::placeholder {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: rgba(2, 3, 3, 1);
  }
`;
const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  background: white;
  max-width: 245px;
  box-shadow: 0px 1px 5px 0px rgba(2, 3, 3, 0.04),
    0px 3px 1px 0px rgba(2, 3, 3, 0.02), 0px 2px 2px 0px rgba(2, 3, 3, 0.03);
  .react-datepicker__close-icon::after {
    background-color: #ee3235; // Change color of the cross button
  }
`;

const RootContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: DatePickerProps) => (
  <RootContainer>
    <CalenderIcon />
    <DateRangeContainer>
      <DateRangePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        // wrapperClassName="customStyles"
        isClearable={startDate ?? false}
        startDate={startDate}
        dateFormat="yyyy-MM-dd"
        endDate={endDate}
        placeholderText="From"
        customInput={<DateInput />}
      />
      <span style={{ margin: '0 5px' }}>-</span>
      <DateRangePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        // wrapperClassName="customStyles"
        isClearable={endDate ?? false}
        startDate={startDate}
        dateFormat="yyyy-MM-dd"
        endDate={endDate}
        minDate={startDate}
        placeholderText="To"
        customInput={<DateInput />}
      />
    </DateRangeContainer>
  </RootContainer>
);
export default DatePicker;
