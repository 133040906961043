import React from 'react';
import setTitle from 'src/utils/setTitle';

const HelpCreateCases = () => {
  setTitle('ITSM | Help - Cases');

  return (
    <>
      <h1>Create Cases</h1>
      <p>
        A new case can be created by simply clicking the Create Case button.
        Let’s create a dummy case to see the complete process:
      </p>
      <ol>
        <li>
          After clicking Create Case, user will be prompted to select the
          Project where case needs to be created
          <img
            src="/static/help/createcase1.png"
            alt="Create case first step"
            style={{ marginTop: 15 }}
          />
        </li>
        {
          // TODO change picture
        }
        <li>
          Next step is to select the Case Type for the request
          <img
            src="/static/help/createcase2.png"
            alt=""
            style={{ marginTop: 15 }}
          />
        </li>
        <li>
          Add a Title to summarise your case and add a Description to explain
          your case in detail. Users can also add images or documents as
          attachments to include any additional information regarding the case
          <img src="/static/help/createcase3.png" alt="" />
        </li>
        <li>
          Review your case information and click on Create Case to complete the
          process.
          <img src="/static/help/createcase4.png" alt="" />
        </li>
      </ol>
    </>
  );
};

export default HelpCreateCases;
