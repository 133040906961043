const logger = (
  msg: any = 'Test Console',
  type: any = 'info',
  label: any = ''
) => {
  if (process.env.REACT_APP_ENVIRONTMENT === 'DEVELOPMENT') {
    switch (type) {
      case 'error':
        console.error(`${label}`, msg);
        break;
      case 'warn':
        console.warn(`${label}`, msg);
        break;
      default:
        console.log(`${label}`, msg);
        break;
    }
  }
};
export default logger;
