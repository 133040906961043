import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CaseData {
  caseID: string;
}
const initialState: CaseData = {
  caseID: ''
};
const caseDataSlice = createSlice({
  name: 'caseData',
  initialState,
  reducers: {
    caseID: (state:any, action: PayloadAction<any>) => {
      state.caseID = action.payload;
      return state;
    }
  }
});

export const { caseID } = caseDataSlice.actions;
export default caseDataSlice.reducer;
