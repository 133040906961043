import { Divider, InputAdornment, TextField } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
// import MenuIcon from '@material-ui/icons/Menu';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

interface SearchBarProps {
  onSearch?: (query: string) => void;
  placeholderData?: string;
  margins?: string | number;
  value?: string;
  advancedSearch?: React.ReactElement;
  anyState?: any;
}
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      display: 'flex',
      background: '#fff',
      alignItems: 'center',
      height: '40px',
      width: 'fit-content',
      boxShadow:
        '0px 3px 8px -1px rgba(50, 50, 71, 0.05),  0px 0px 1px 0px rgba(12, 26, 75, 0.24)',

      // boxShadow:
      //   ' rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
      borderRadius: '6px',
      // '& .MuiTextField-root': {
      //   width: 'fitContent',
      //   border: 0,
      // },
      '& .MuiOutlinedInput-root': {
        padding: '0 14px',
      },

      '& fieldset': {
        width: 'initial',
        border: 0,
        cursor: 'pointer',
      },
      '& .MuiInputAdornment-root': {
        cursor: 'pointer',
        marginRight: '5px',
      },
    },
  })
);
const SearchBar: FC<SearchBarProps> = ({
  onSearch,
  placeholderData,
  value,
  margins,
  advancedSearch,
  anyState,
}) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  placeholderData = placeholderData || 'Search';

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === '') {
      onSearch(query);
    }
    // Call the API with the search query
  };

  useEffect(() => {
    setSearchQuery(value);
  }, [value]);

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        placeholder={`${placeholderData}`}
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyUp={(e) => {
          if (e.keyCode === 13 && searchQuery.trim() !== '') {
            onSearch(searchQuery);
          }
        }}
        disabled={anyState || false}
        InputProps={{
          style: {
            height: '30px',
            fontSize: '14px',
            width: '300px',
            margin: margins,
          },
          startAdornment: (
            <InputAdornment
              position="end"
              onClick={() => {
                onSearch(searchQuery);
              }}>
              <SearchIcon
                sx={{
                  color: '#B5B5BD',
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {searchQuery !== '' && (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setSearchQuery('');
                    onSearch('');
                  }}>
                  <ClearIcon />
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
      {advancedSearch && (
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      )}
      {advancedSearch}
    </div>
  );
};

export default SearchBar;
