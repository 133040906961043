import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from 'src/store/slices/snackBarSlice';
import { useNavigate } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SimpleSnackBar = () => {
  const [basePath, setBasePath] = useState<string>('/');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showPopup, message, caseKey } = useSelector(
    (snackBar: any) => snackBar?.snackbar
  );

  const handleClose = () => {
    // Dispatch the closeSnackbar action
    dispatch(closeSnackbar());
  };
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${basePath}/cases/${caseKey}`);
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      // Handle the error or provide feedback to the user
    }
  };

  const handleViewTicket = () => {
    navigate(`cases/${caseKey}`);
  };
  useEffect(() => {
    const { origin } = window.location;
    setBasePath(`${origin}`);
  }, []);

  return (
    <Snackbar
      open={showPopup}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        severity="success"
        variant="filled"
        style={{ backgroundColor: '#393939', color: 'white' }}
        action={(
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          )}
      >
        <>
          <p style={{ margin: '0 0 10px', fontSize: '16px' }}>{`${message}`}</p>
          {' '}
          <div>
            <Tooltip
              title="Link copied"
              open={showTooltip}
              onClose={() => setShowTooltip(false)}
            >
              <span
                style={{ marginLeft: 'auto', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleCopyLink}
                aria-hidden="true"
              >

                Copy link

              </span>
            </Tooltip>
            <span
              style={{ marginLeft: '15px', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleViewTicket}
              aria-hidden="true"
            >
              View Case
            </span>
          </div>
        </>
      </Alert>
    </Snackbar>
  );
};

export default SimpleSnackBar;
