export default function getProjectTypeShort(projectType = '') {
  const types = {
    'General Support': 'GEN',
    'Redhat Support': 'RHS',
    'Application Support': 'APS',
    'Application Feature Request': 'AFR',
    'Application Bug': 'BUG',
    Administrative: 'FIN',
  };

  return types[projectType] || projectType;
}
