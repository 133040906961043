const CasesIcon = ({ color = '#A0AEC0' }: { color: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.6C0 2.04305 0.210714 1.5089 0.585787 1.11508C0.960859 0.721249 1.46957 0.5 2 0.5H7.33333C8.57101 0.5 9.758 1.01625 10.6332 1.93518C11.5083 2.8541 12 4.10044 12 5.4V12.4C12 12.957 11.7893 13.4911 11.4142 13.8849C11.0391 14.2788 10.5304 14.5 10 14.5H2C1.46957 14.5 0.960859 14.2788 0.585787 13.8849C0.210714 13.4911 0 12.957 0 12.4V2.6ZM6.66667 1.9H2C1.82319 1.9 1.65362 1.97375 1.5286 2.10503C1.40357 2.2363 1.33333 2.41435 1.33333 2.6V12.4C1.33333 12.5857 1.40357 12.7637 1.5286 12.895C1.65362 13.0263 1.82319 13.1 2 13.1H10C10.1768 13.1 10.3464 13.0263 10.4714 12.895C10.5964 12.7637 10.6667 12.5857 10.6667 12.4V5.4H6.66667V1.9ZM10.3893 4C10.175 3.48545 9.84612 3.03247 9.43032 2.67921C9.01452 2.32594 8.52398 2.08272 8 1.97V4H10.3893Z"
      fill={color}
    />
  </svg>
);

export default CasesIcon;
