import React from 'react';
import setTitle from 'src/utils/setTitle';

const Project = () => {
  setTitle('ITSM | Help - Project');

  return (
    <>
      <h1>Projects</h1>
      <img src="/static/help/projects.png" alt="" />
      <p>
        The projects module contains a list of all the projects within your
        organisation. The list shows you the following information:
      </p>
      <ul>
        <li>
          <strong>Name: </strong>
          <span>Project name</span>
        </li>
        <li>
          <strong>Description: </strong>
          <span>A brief description of the project</span>
        </li>
        <li>
          <strong>Domain: </strong>
          <span>Domain info</span>
        </li>
        <li>
          <strong>Website: </strong>
          <span>Project Website if any</span>
        </li>
        <li>
          <strong>Cases: </strong>
          <span>Total number of cases within the project</span>
        </li>
      </ul>
      <br />
      <h2>Project Cases</h2>
      <p>
        Once a user selects their respective project, they are taken to the list
        of cases belonging to that particular project.
      </p>
      <img src="/static/help/projectcases.png" alt="" />
      <p>
        Users can view, update or add new cases for the project here and use the
        search functions to find a particular case or a list of cases as
        required.
      </p>
    </>
  );
};

export default Project;
