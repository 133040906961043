import type { FC } from 'react';

interface LogoProps {
  height?: number | string;
  width?: number | string;
}

const Logo: FC<LogoProps> = ({ width, height }: LogoProps) => {
  const whiteLabel: any = JSON.parse(localStorage.getItem('white_label'));

  return (
    <img
      width={width}
      height={height}
      style={{ objectFit: 'contain', maxHeight: '110px' }}
      src={`${whiteLabel?.logo?.length > 0 ? whiteLabel?.logo : '/static/logo.png'
        }`}
      alt="Logo"
    />
  );
};

export default Logo;
