import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import { Filters } from 'src/components/Filters';
import { Header } from 'src/components/Header';
import CustomizedTable from 'src/components/tables/CustomizedTable';
import { BillingType } from 'src/components/tables/renderers/BillingType';
// import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import useGenericStyles from 'src/styles/useGenericStyles';
import capitalize from 'src/utils/capitalize';
import convertSecondsToTimeString from 'src/utils/convertSecondsToTimeString';
import setTitle from 'src/utils/setTitle';
import { pluck } from 'underscore';

function ViewReporting() {
  setTitle('ITSM | Reporting');
  const theme = useTheme();
  // states
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [sprint, setSprint] = useState<any>({});
  const [sprints, setSprints] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  // const bulkApproveDialog = useDialog();
  const [page, setPage] = useState<any>(0);
  const [pageSize, setPageSize] = useState<any>(25);
  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };
  // const [search, setSearch] = useState<any>('');
  const [fetchingData, setFetchingData] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [sort, setSort] = useState({ field: 'name', order: 'desc' });
  // const handleSearch = (val) => {
  //   setSearch(val);
  // };
  const [isExporting, setIsExporting] = useState(false);
  // const [selectedRowObjects, setSelectedRowObjects] = useState([]);

  // hooks
  const classes = useGenericStyles();

  const getAllBillingAccounts = async () => {
    try {
      const response = await casesApi.getAllBillingAccounts();
      if (response.status === 200) {
        setBillingAccounts(response.data);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An error occured while fetching types');
      }
    }
  };

  const getSprintsByOrganization = async () => {
    try {
      let response = await casesApi.getSprintsByOrganization();
      if (response.status === 200) {
        response = response?.data;
        const uniqueSprintNames = uniqBy(
          response,
          (item: any) => item?.startDate && item?.endDate
        );
        if (uniqueSprintNames?.length > 0) {
          setSprint(uniqueSprintNames[0]);
        }
        setSprints(uniqueSprintNames);
      }
    } catch (error) {
      setSprints([]);
    }
  };

  const handleSprintChange = (e) => {
    const res = sprints?.filter((item) => item?.name === e.target.value);
    if (res?.length > 0) {
      setSprint(res[0]);
    }
  };

  const getAllData = async () => {
    if (billingAccounts?.length > 0) {
      setFetchingData(true);
      try {
        const objs = {
          page,
          pageSize,
          // search,
          billingAccountId: pluck(billingAccounts, 'id')?.join(','),
          // caseType: filters.Type?.map((itm: any) => itm.value).toString() || null,
          // caseType: caseType?.map((itm) => itm.id).toString(),
          // priority: priority?.map((itm) => itm.id).toString(),
          // assignee: filters.Reporter?.value || null,
          // status: status?.map((itm) => itm.id).toString(),
          // projectId:
          //   filters.Project?.map((itm: any) => itm.value).toString() || null,
          // hasAttachments,
          // createdBy:
          //   filters.Reporter?.map((itm: any) => itm.value).toString() || null,
          // isApproved:
          //   filters['Approval Status']?.length === 1
          //     ? filters['Approval Status']?.map((itm: any) => itm.value)[0]
          //     : null,
          to: sprint?.endDate?.substring(0, 10),
          from: sprint?.startDate?.substring(0, 10),
          sprintId: sprint?.id
          // column: columnValue,  // dont remove comment, might need later
          // sort: selectedSort?.value === 'asc' ? '1' : '-1',
        };
        const keys = Object.keys(objs);
        const values = Object.values(objs);

        // Create an array to store the non-empty key-value pairs
        const queryParams = [];
        for (let i = 0; i < values.length; i++) {
          if (
            values[i] !== '' &&
            values[i] !== null &&
            values[i] !== undefined
          ) {
            // if (keys[i] === 'from' || keys[i] === 'to') {
            //   queryParams.push(`${keys[i]}=${values[i]}`);
            // } else {
            queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
            // }
          }
        }
        const queryString = queryParams.join('&');
        let response = await casesApi.getBillableStatistics(queryString);
        if (response?.status === 200) {
          response = response?.data;
          setAllData(response?.data);
          setTotalCount(response?.totalRecords);
          // setFetchingData(false);
        }
        if (response.status === 204) {
          setAllData([]);
          setTotalCount(0);
        }

        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
        if (error?.response?.status !== 401) {
          toast.error(error?.response?.data?.message);
          setAllData([]);
          setTotalCount(0);
        }
      }
    }
  };

  const customColumns = [
    {
      field: 'billableAccount',
      headerName: 'Account',
      width: '100px',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(data?.billableAccount?.name)}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(data?.billableAccount?.name)}
        </Typography>
      ),
    },
    {
      field: 'project',
      headerName: 'Project',
      width: '110px',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(data?.project?.name)}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(data?.project?.name)}
        </Typography>
      ),
    },
    {
      field: 'caseKey',
      headerName: 'Case Key',
      width: '70px',
    },
    {
      field: 'summary',
      headerName: 'Case Title',
      width: '350px',
      render: (columnValue, data) => (
        <Typography
          title={capitalize(data?.summary)}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalize(data?.summary)}
        </Typography>
      ),
    },
    {
      field: 'timeEstimate',
      headerName: 'Estimate',
      width: '60px',
      render: (columnValue) =>
        capitalize(columnValue ? convertSecondsToTimeString(columnValue) : ''),
    },
    {
      field: 'remainingTime',
      headerName: 'Remaining',
      width: '60px',
      render: (columnValue) =>
        capitalize(convertSecondsToTimeString(columnValue))
    },
    {
      field: 'loggedTime',
      headerName: 'Logged',
      width: '60px',
      render: (columnValue) =>
        capitalize(columnValue ? convertSecondsToTimeString(columnValue) : ''),
    },
    {
      field: 'category',
      headerName: 'Category',
      width: '70px',
      render: (columnValue) =>
        columnValue ? <BillingType value={columnValue} /> : 'N/A',
    },
    {
      field: 'sprint',
      headerName: 'Sprint',
      width: '50px',
      render: (columnValue) =>
        columnValue?.name?.length > 0 ? capitalize(columnValue?.name) : 'N/A',
    },
    {
      field: 'releaseVersion',
      headerName: 'Version',
      width: '70px',
      render: (columnValue) =>
        columnValue?.name?.length > 0 ? capitalize(columnValue?.name) : 'N/A',
    },
  ];

  const handleSort = (field) => {
    setSort((prevSort) => ({
      field,
      order:
        prevSort.field === field && prevSort.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleApplyFilter = (
    currentFilterColumn,
    filterValue,
    filterName,
    checked
  ) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (checked) {
        if (newFilters[currentFilterColumn]) {
          newFilters[currentFilterColumn].push({
            label: filterName,
            value: filterValue,
          });
        } else {
          newFilters[currentFilterColumn] = [
            {
              label: filterName,
              value: filterValue,
            },
          ];
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (newFilters[currentFilterColumn]) {
          newFilters[currentFilterColumn] = newFilters[
            currentFilterColumn
          ].filter((obj) => obj.value !== filterValue);

          // If no filters are left for the column, remove the column from filters
          if (newFilters[currentFilterColumn].length === 0) {
            delete newFilters[currentFilterColumn];
          }
        }
      }
      return newFilters;
    });
  };

  const handleRemoveFilter = (column) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[column];
      return newFilters;
    });
  };

  // const handleClickBulkApprove = (selectedRowsObjsFromTable: any) => {
  //   bulkApproveDialog.handleOpen();
  //   setSelectedRowObjects(selectedRowsObjsFromTable);
  // };
  useEffect(() => {
    if (sprints?.length === 0) {
      getSprintsByOrganization();
    }
    if (sprints?.length > 0) {
      getAllData();
    }
  }, [
    page,
    pageSize,
    JSON.stringify(filters),
    billingAccounts,
    // search,
    sprint,
  ]);

  useEffect(() => {
    // getAllProjectsAutoComplete();
    getAllBillingAccounts();
  }, []);

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const objs = {
        billingAccountId: pluck(billingAccounts, 'id')?.join(','),
        to: sprint?.endDate?.substring(0, 10),
        from: sprint?.startDate?.substring(0, 10),
        sprintId: sprint?.id,
      };
      const keys = Object.keys(objs);
      const values = Object.values(objs);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      const response = await casesApi?.billableStatisticsExport(queryString);
      if (response?.status === 200) {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${sprint?.name} ${moment(new Date()).format("YYYY-MM-DD")}.xlsx`; // Adjust the file name and extension as needed
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }
      setIsExporting(false);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error('Please select a date range to proceed');
        setTotalCount(0);
      }

      setIsExporting(false);
    }
  };

  return (
    <>
      <Header title="Reporting" />
      <Container maxWidth={false} className={classes.container}>
        <Box
          sx={{
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          {/* <SearchBar
            onSearch={handleSearch}
            value={search}
            placeholderData="Search ..."
          /> */}
          <Stack
            direction="row"
            spacing={2}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: '22px',
                  alignSelf: 'center',
                }}
              />
            }
            alignItems="center">
            <Box display="flex" alignItems="center" gap="10px">
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  borderRadius: '6px',
                  display: 'flex',
                  gap: '5px',
                  border: '1px solid transparent',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: 'white',
                  },
                }}
                disabled={isExporting}
                className={classes.button}
                onClick={handleExport}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width="12"
                  height="12">
                  <path
                    fillRule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  style={{
                    marginLeft: '5px',
                  }}>
                  Export
                </span>
              </Button>
            </Box>
            <TextField
              InputProps={{
                classes: {
                  root: classes.textField,
                },
              }}
              size="small"
              fullWidth
              label="Sprint"
              placeholder="Sprint"
              name="sprint"
              required
              onChange={handleSprintChange}
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={sprint?.name || ''}
              defaultValue={sprint?.name || ''}>
              {sprints?.map((item) => (
                <option key={item?.name} value={item?.name}>
                  {item?.name}
                </option>
              ))}
            </TextField>
            {/* <DatePicker
              startDate={startDate}
              setStartDate={handleStartDateChange}
              endDate={endDate}
              setEndDate={handleEndDateChange}
            /> */}
          </Stack>
        </Box>
        {Object.keys(filters).length > 0 && (
          <Filters filters={filters} handleRemoveFilter={handleRemoveFilter} />
        )}
        <CustomizedTable
          heading="Hours Report"
          loading={fetchingData}
          columns={customColumns}
          page={page}
          limit={pageSize}
          handleLimit={handlePageSize}
          handlePage={handlePage}
          data={allData}
          totalRecords={totalCount}
          // showCheckBoxes
          handleSort={handleSort}
          sort={sort}
          handleApplyFilter={handleApplyFilter}
          filters={filters}
          navigateUrl="/cases"
          navigateToKey
          selectRowData
        // actionButtonLabel="Bulk Approve"
        // actionButtonCallback={handleClickBulkApprove}
        />
      </Container>
    </>
  );
}

export default ViewReporting;
