import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { casesApi } from 'src/api/casesApi';
import { projectApi } from 'src/api/projectApi';

const initialState = {
  allProjects: [],
  favProjects: [],
  loading: false,
  error: null as string | null,
};

export const getAllProjects = createAsyncThunk('getAllProjects', async () => {
  const response = await casesApi.getAllProjects();
  if (response?.status === 200) {
    return response.data;
  }

  return [];
});

export const getAllFavouriteProjects = createAsyncThunk(
  'getAllFavouriteProjects',
  async () => {
      const response = await projectApi.getAllFavouriteProjects();
      if (response?.status === 200) {
        return response.data;
      } 
      return []
    }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // allProjects: (state: any, action: PayloadAction<any>) => {
    //   state.allProjects = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.allProjects = action.payload;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch projects';
      });
    builder
      .addCase(getAllFavouriteProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFavouriteProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.favProjects = action.payload;
      })
      .addCase(getAllFavouriteProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch projects';
      });
  },
});

// export const { allProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
