import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import {
  Badge,
  Box,
  List,
  Popover,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BellIcon from '../../icons/Bell';
import { UserActivity } from '../widgets/display-blocks/user-activity';
import parseHTML from 'html-react-parser';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useAuth from 'src/hooks/useAuth';
import logger from 'src/utils/logger';
// import { useNavigate } from 'react-router';
// import { casesApi } from 'src/api/casesApi';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    popoverRoot: {
      '& .MuiPaper-root': {
        width: '526px',
        overflow: 'hidden',
      },
      '& .rootNotifications .top:hover': {
        textDecoration: 'underline',
      },
    },
    notificationsRoot: {
      display: 'flex',
      gap: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid #efefef',
      padding: '16px 21px 0 21px',

      '& .MuiTypography-h3': {
        flexGrow: 1,
        fontSize: '20px',
        fontWeight: 700,
        color: '#000',
      },

      '& .MuiTypography-body1': {
        color: '#696969',
        fontSize: '12px',
        fontWeight: 300,
      },

      '& .switchRoot': {
        display: 'flex',
        flexDirection: 'column',

        '& .MuiSwitch-root': {
          '& .Mui-checked': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    listRoot: {
      padding: '8px',
      height: '400px',
      overflowY: 'auto',
      '& .MuiListItemAvatar-root': {
        minWidth: '40px',
      },
      '& .MuiListItem-root+.MuiListItem-root': {
        marginTop: '5px',
      },
      '& .MuiAvatar-root': {
        width: '36px',
        height: '36px',
      },

      '& .MuiListItem-root': {
        alignItems: 'flex-start',
        paddingLeft: '21px',
        paddingRight: '21px',
      },
      '& .active': {
        backgroundColor: '#EFEFEF',
      },
      '& .MuiListItemText-root': {
        marginTop: '0px',
        marginBottom: '0px',
        '& .MuiListItemText-primary': {
          fontSize: '14px',
          color: '#231f20',
        },
        '& .MuiListItemText-secondary': {
          fontSize: '14px',
          fontWeight: 400,
          color: '#696969',
          display: 'flex',
          alignItems: 'center',
        },
      },

      '& .message': {
        marginTop: '0px',
        marginRight: '40px',
        marginBottom: '0px',
        marginLeft: '40px',
        border: '1px solid #c8c8c8',
        borderRadius: '2px',
        padding: '8px 12px',
        color: '#696969',
        fontSize: '14px',
        fontWeight: 400,
      },
    },
    box2: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '9px 21px',
      '& a': {
        margin: '0',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  })
);
const NotificationsPopover: FC = () => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useAuth();
  // const navigate = useNavigate();
  const [notifications, setNotifications] = useState(
    [...user?.notifications].reverse()
  );
  const [notificationsCounter, setNotificationsCounter] = useState<any>(() => {
    const storedNotificationValue = localStorage.getItem('notificationCounter');
    return storedNotificationValue ? Number(storedNotificationValue) : 0;
  });
  const [showUnread, setShowUnread] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
    setNotificationsCounter(0);
    localStorage.setItem('notificationCounter', '0');
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const appendRecievedNotification = (notificationRecieved) => {
    setNotifications((prevMessages) => [notificationRecieved, ...prevMessages]);
  };

  const socket = io(`${process.env.REACT_APP_NOTIFICATIONS}`, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  });
  const markMessageAsRead = (messageId) => {
    const updatedMessages = notifications?.map((message) => {
      if (message.id === messageId) {
        return { ...message, isRead: true };
      }
      return message;
    });

    // Update the state to reflect the change in status
    setNotifications(updatedMessages);
    // Emit an event to the server to inform it of the change
    socket.emit('markasRead', [`${messageId}`]);
  };
  useEffect(() => {
    socket.on('connect', () => {
      logger('Connected to Socket.IO server');
    });
    socket.on(user?.userKafkaTopic, (notificationRecieved) => {
      appendRecievedNotification(notificationRecieved);
      setNotificationsCounter((pre) => Number(pre + 1));
    });
    socket.on('error', (error) => {
      console.error('Socket.IO error:', error);
    });
    socket.on('disconnect', () => {
      logger('Disconnected from Socket.IO server');
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    localStorage.setItem('notificationCounter', `${notificationsCounter}`);
  }, [notificationsCounter]);
  // eslint-disable-next-line no-nested-ternary
  const filteredNotifications = !showUnread
    ? notifications
    : notifications.filter((message) => !message.isRead);
  return (
    <>
      <Tooltip title="Notifications">
        <Badge
          color="secondary"
          badgeContent={notificationsCounter}
          ref={anchorRef}
          onClick={handleOpen}
          style={{
            cursor: 'pointer',
          }}>
          <BellIcon fontSize="small" />
        </Badge>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        style={{ marginTop: '45px' }}
        onClose={handleClose}
        open={open}
        className={classes.popoverRoot}>
        <Box className={classes.notificationsRoot}>
          <Typography color="textPrimary" variant="h3">
            Notifications
          </Typography>
          <Typography>Only show unread</Typography>
          <Box className="switchRoot">
            <Switch
              size="small"
              checked={showUnread}
              onChange={() => {
                setShowUnread(!showUnread);
              }}
            />
          </Box>
        </Box>
        <Box className={classes.box2}>
          <span>Latest</span>
          <a
            href=""
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: '#231F20',
            }}
            onClick={(e) => {
              e.preventDefault();
              if (notifications) {
                const updatedMessages =
                  notifications?.map((message) => ({
                    ...message,
                    isRead: true,
                  })) ?? [];
                const getNotificationsId =
                  notifications?.map((message) => message?.id) ?? [];
                socket.emit('markasRead', getNotificationsId);
                setNotifications(updatedMessages);
              }
            }}>
            <strong>Mark all as read</strong>
          </a>
        </Box>
        <List disablePadding className={classes.listRoot}>
          {filteredNotifications?.map((notification: any) => (
            <>
              {/* {logger(notification, 'notification')} */}
              <UserActivity
                key={notification?.message?.id}
                customClasses={
                  notification?.isRead
                    ? 'rootNotifications'
                    : 'active rootNotifications'
                }
                onClick={(e) => {
                  if (e) {
                    markMessageAsRead(notification?.id);
                    if (notification?.caseNo !== null) {
                      window.open(
                        `/erp/cases/${notification?.caseNo}`,
                        '_blank'
                      );
                    }
                  }
                }}
                sx={{ cursor: 'pointer' }}
                // image={{ src: '/static/icons/avatar.png', alt: notification?.message?.createdBy?.name }}
                meta={{
                  description: notification?.message?.description,
                  createdBy: notification?.message?.createdBy.name,
                  createdAt: notification?.message?.createdAt,
                }}
                type={notification?.type
                  ?.trim()
                  ?.toLowerCase()
                  .replaceAll(' ', '-')}
                details={
                  notification?.message?.changeFrom !== '' &&
                  notification?.message?.changeTo !== '' && (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <span style={{ fontSize: '12px' }}>
                        {notification?.message?.changeFrom === null ||
                        notification?.message?.changeFrom === undefined
                          ? 'none'
                          : parseHTML(notification?.message?.changeFrom)}
                      </span>
                      <span
                        style={{
                          flex: '0 0 40px',
                          textAlign: 'center',
                          margin: '-2px 0 0',
                        }}>
                        <ArrowRightAltIcon />
                      </span>
                      <span style={{ fontSize: '12px' }}>
                        {notification?.message?.changeTo === null ||
                        notification?.message?.changeTo === undefined
                          ? 'none'
                          : parseHTML(notification?.message?.changeTo)}
                      </span>
                    </div>
                  )
                }
              />
            </>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
