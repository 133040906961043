import { Box } from '@material-ui/core';
import React from 'react';

export const Status = ({ status }: { status: string }) => {
  const color = (_status) => {
    switch (_status) {
      case 'Closed':
        return '#66CB9F';
      case 'Done':
        return '#66CB9F';
      case 'To Do':
        return '#F7936F';
      case 'Waiting':
        return '#F16063';
      case 'In Progress':
        return '#09A0BF';
      default:
        return 'gray';
    }
  };

  const bgColor = (_status) => {
    switch (_status) {
      case 'Closed':
        return '#DEFFEE'
      case 'Done':
        return '#DEFFEE';
      case 'To Do':
        return '#FFEDE3';
      case 'Waiting':
        return '#FFE6E4';
      case 'In Progress':
        return '#E5FDFF';
      default:
        return 'lightGray';
    }
  };

  return (
    <Box
      title={status}
      sx={{
        padding: ' 4px 8px',
        backgroundColor: bgColor(status),
        color: color(status),
        width: 'auto',
        borderRadius: '6px',
        textAlign: 'center',
        textOverflow: "ellipsis",
        overflow: "hidden"
      }}>
      {status}
    </Box>
  );
};
