import type { User, UserOverviewProps } from 'src/types/user';
import axios, { postRequest } from 'src/utils/axios';
import pureAxios from 'axios';
import parse from 'src/utils/parse';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import i18next from 'i18next';

class UserApi {
  async create(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      };
      const errorFunction = (err) => {
        logger(err, 'error');
        reject(err);
      };
      const data = {
        url: `${process.env.REACT_APP_INTERNAL_USER_URL}/users`,
        payload: user,
        successFunction,
        errorFunction,
      };
      // Create User
      const prop = 'submit';
      delete user[prop];
      postRequest(data);
    });
  }

  async edit(userId, user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      // Update User
      let prop = 'submit';
      delete user[prop];
      if (user?.password?.trim()?.length === 0) {
        prop = 'password';
        delete user[prop];
      }
      axios
        .put(
          `${process.env.REACT_APP_CASES}/user/${userId}`,
          user
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }

  async updateSetting(data: any): Promise<User> {
    return new Promise((resolve, reject) => {
      // Update Alarm Settings
      axios
        .patch(`${process.env.REACT_APP_INTERNAL_USER_URL}/alarmSetting`, data)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }

  async globalSearch(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Update Global Search
      const search = localStorage.getItem('search');
      const payload: any = {
        Filter: {
          userSortList: {
            created_at: 'DESC',
          },
          clientSortList: {
            created_at: 'DESC',
          },
          serviceClientSortList: {
            'client.created_at': 'DESC',
          },
          presetSortList: {
            'preset.created_at': 'DESC',
          },
          projectSortList: {
            'project.created_at': 'ASC',
          },
          page: 0,
          limit: 20,
        },
        search,
      };
      axios
        .post(
          `${process.env.REACT_APP_INTERNAL_USER_URL}/global_search`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }

  async getAll(
    page: number,
    limit: number,
    currentTab: string,
    search: string,
    roleIdList: any,
    sortColumn: any,
    sortDirection: boolean,
    clientId: number
  ): Promise<UserOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All
      let sortList = {};
      if (sortColumn.name) {
        sortList = {
          first_name: sortDirection ? 'ASC' : 'DESC',
          last_name: sortDirection ? 'ASC' : 'DESC',
          email: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          city: sortDirection ? 'ASC' : 'DESC',
          country: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.role_id) {
        sortList = {
          role_id: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.is_activated) {
        sortList = {
          is_activated: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.created_at) {
        sortList = {
          created_at: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
      };

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      let payload: any;

      const formattedRoleIdList = roleIdList?.filter((role) => role !== -1);

      if (search?.length > 0) {
        payload = {
          Filter: filter,
          search,
          client_id: clientId,
          roleIdList: formattedRoleIdList,
        };
      } else if (formattedRoleIdList?.length > 0) {
        payload = {
          Filter: filter,
          client_id: clientId,
          roleIdList: formattedRoleIdList,
        };
      } else {
        payload = {
          Filter: filter,
          client_id: clientId,
        };
      }

      if (!isUndefined(currentTab)) {
        if (+currentTab !== -1) {
          filter.active = !!currentTab;
        }
      }
      axios
        .post(`${process.env.REACT_APP_INTERNAL_USER_URL}/users/get`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getAllAdmin(
    page: number,
    limit: number,
    currentTab: number,
    search: string,
    roleIdList: any,
    sortColumn: any,
    sortDirection: boolean
  ): Promise<UserOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All Admin
      let sortList = {};
      if (sortColumn.name) {
        sortList = {
          first_name: sortDirection ? 'ASC' : 'DESC',
          last_name: sortDirection ? 'ASC' : 'DESC',
          email: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          city: sortDirection ? 'ASC' : 'DESC',
          country: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.role_id) {
        sortList = {
          role_id: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.is_activated) {
        sortList = {
          is_activated: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.created_at) {
        sortList = {
          created_at: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
      };

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      let payload: any;

      if (search?.length > 0) {
        payload = {
          Filter: filter,
          search,
          is_admin_module: true,
          roleIdList,
        };
      } else if (roleIdList) {
        payload = {
          Filter: filter,
          roleIdList,
          is_admin_module: true,
        };
      } else {
        payload = {
          Filter: filter,
          is_admin_module: true,
        };
      }

      if (!isUndefined(currentTab)) {
        if (currentTab !== -1) {
          filter.active = !!currentTab;
        }
      }
      axios
        .post(`${process.env.REACT_APP_INTERNAL_USER_URL}/users/get`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getById(userId: string): Promise<User> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios
        .get(`${process.env.REACT_APP_CASES}/user/${userId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getByKeycloakId(): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/user/profile`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data: any = response?.data;
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getUserPermissions(token: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get User Permissions
      if (token?.length > 0) {
        const successFunction = (response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        };
        const errorFunction = (error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        };
        const customHeader = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const data = {
          url: `${process.env.REACT_APP_INTERNAL_USER_URL}/getPermissions`,
          payload: {},
          successFunction,
          errorFunction,
          customHeader,
        };
        postRequest(data);
      } else {
        const successFunction = (response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        };
        const errorFunction = (error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        };
        const data = {
          url: `${process.env.REACT_APP_INTERNAL_USER_URL}/getPermissions`,
          successFunction,
          errorFunction,
        };
        postRequest(data);
      }
    });
  }

  async getKeycloakIdByEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Email By Keycloak Id
      pureAxios
        .get(
          `${process.env.REACT_APP_INTERNAL_USER_URL}/getKeycloakIdByEmail/${email}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getServiceAccountToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Service Account
      pureAxios
        .get(
          `${process.env.REACT_APP_INTERNAL_USER_URL}/getServiceAccountToken`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  // async getAllUserAutoComplete(): // clientId: number,
  // search: string,
  // returnUserWithoutClient: boolean = false
  // Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     Get All Users by Autocomplete
  //     const payload = {
  //       return_user_without_client: returnUserWithoutClient,
  //       client_id: clientId,
  //       search,
  //     };

  //     let prop = '';

  //     if (returnUserWithoutClient) {
  //       prop = 'client_id';
  //       delete payload[prop];
  //     }

  //     if (!returnUserWithoutClient) {
  //       prop = 'return_user_without_client';
  //       delete payload[prop];
  //     }

  //     if (search.trim()?.length === 0) {
  //       prop = 'search';
  //       delete payload[prop];
  //     }

  //     const successFunction = (response) => {
  //       response = parse(response);
  //       if (!isUndefined(response?.data)) {
  //         const res = response?.data;
  //         resolve(res);
  //       } else {
  //         reject(new Error(i18next.t('invalid_server_response')));
  //       }
  //     };
  //     const errorFunction = (error) => {
  //       logger(error, 'error');
  //       reject(new Error(i18next.t('internal_server_error')));
  //     };
  //     const data = {
  //       url: `${process.env.REACT_APP_INTERNAL_USER_URL}/getAllUserAutoComplete`,
  //       payload,
  //       successFunction,
  //       errorFunction,
  //     };
  //     postRequest(data);
  //     // axios.post(`${process.env.REACT_APP_INTERNAL_USER_URL}/getAllUserAutoComplete`, data).then((response) => {
  //     //   response = parse(response);
  //     //   if (!isUndefined(response?.data)) {
  //     //     const res:any = response?.data;
  //     //     resolve(res);
  //     //   } else {
  //     //     reject(new Error(i18next.t('invalid_server_response')));
  //     //   }
  //     // }).catch((error) => {
  //     //   logger(error, 'error');
  //     //   reject(new Error(i18next.t('internal_server_error')));
  //     // });
  //   });
  // }

  async deActivate(userId: string, isActive: number): Promise<User> {
    return new Promise((resolve, reject) => {
      // De-active User
      axios
        .patch(`${process.env.REACT_APP_INTERNAL_USER_URL}/delete/${userId}`, {
          is_activated: !isActive,
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async bulkDeActivate(userIds: string[]): Promise<User> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Users
      const payload = {
        list: userIds,
      };
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      };
      const data = {
        url: `${process.env.REACT_APP_INTERNAL_USER_URL}/users/bulk-deactivate`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async bulkDelete(userIds: string[]): Promise<User> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Users
      const payload = {
        list: userIds,
      };
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      };
      const data = {
        url: `${process.env.REACT_APP_INTERNAL_USER_URL}/users/bulk-deactivate`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async bulkDeleteThreeScaleUsers(userIds: string[]): Promise<User> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Users
      const payload = {
        list: userIds,
      };
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      };
      const data = {
        url: `${process.env.REACT_APP_CLIENTS_3SCALE_URL}/accountDelete`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async sendEmail(emailProperties: any): Promise<User[]> {
    // Send Email
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      };
      const data = {
        url: `${process.env.REACT_APP_EMAILS_URL}`,
        payload: emailProperties,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async resetPassword(
    keycloakUserId: string,
    newPassword: string
  ): Promise<any[]> {
    // Reset Password
    return new Promise((resolve, reject) => {
      const payload = {
        keycloak_user_id: keycloakUserId,
        new_password: newPassword,
        resetPassword: newPassword,
      };
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_INTERNAL_USER_URL}/reset_password`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async uploadFile(
    event: string,
    userType: string,
    previousImage: string = ''
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', event);
      formData.append('type', userType);
      logger(previousImage);
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      };
      const errorFunction = (error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      };
      const data = {
        url: `${process.env.REACT_APP_IMAGE_UPLOAD_URL}/upload`,
        payload: formData,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async updateProfileImageByKeyCloakId(
    keyCloakId: string,
    profileImage: string
  ): Promise<any> {
    // Update Profile Image By Keycloak Id
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.REACT_APP_INTERNAL_USER_URL}/updateProfileImageByKeyCloakId/${keyCloakId}`,
          {
            profile_image: profileImage,
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            const res = response?.data;
            resolve(res);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async uploadAttachments(formData: any): Promise<any> {
    // Update Profile Image By Keycloak Id
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}/upload`, formData, {})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error uploading image:', error);
          reject(error);
        });
    });
  }

  async getUsersByOrganizationId(
    organizationId: string,
    page: number,
    pageSize: number,
    name: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/user`, {
          params: { organizationId, page, pageSize, name },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response.data)) {
            resolve(response);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async UpdateUserById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/user/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response;
            resolve(res);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }

  async updateProfile(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/user/update`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const res = response;
            resolve(res);
          }
        })
        .catch((err) => {
          logger(err, 'error');
          reject(err);
        });
    });
  }

  async createUser(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/user`, user)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async updateStatus(id: string, isActive: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/user/${id}/${isActive}`)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteUser(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_CASES}/user/${id}`)
        .then((response) => {
          response = parse(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const userApi = new UserApi();
