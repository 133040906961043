import {
  Box,
  Card,
  CardContent,
  Grid,
  // LinearProgress,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from '../../components/Logo';
import { LoginJWT } from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { useTranslation } from 'react-i18next';
import { PasswordResetAmplify } from 'src/components/authentication/password-reset';

const useStyles = makeStyles<Theme>((theme: Theme) => createStyles({
  wrapper: {
    backgroundColor: '#E8EDF2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    border: 0,
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      paddingTop: '2.5rem',
    },
  },
  firstSectionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    background: 'url("/static/login_bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      background: 'none',
      minHeight: '20vh',
    },
  },
  card: {
    backgroundColor: 'white',
    border: 0,
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      paddingTop: '4.5rem',
    },
  },
  desktopLogo: {
    display: 'block',
    background: 'white',
    borderRadius: '50%',
    padding: '10px 10px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileLogo: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  title: {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: 'black',
    },
  },
  subTitle: {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  leftHeroWrapper: {
    display: 'flex',
  },
  leftHeroImage: {
    maxWidth: '40%',
    background: 'white',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  fullPageHero: {
    maxWidth: '100%',
  },
  imagesWrapper: {
    display: 'flex',
  },
  leftSideLogo: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '50vw',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  textWhite: {
    color: 'white',
  },
  logo: {
    width: 150,
    marginBottom: 24,
  },
}));
const Login: FC = () => {
  const { isAuthenticated, isFirstTime } = useAuth() as any;
  const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState<boolean>(false);
  const { t } = useTranslation(['toasts', 'login']);
  const classes = useStyles();
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const currentTime = Date.now() / 1000;
    const decoded: any = jwtDecode(accessToken);
    return decoded.exp > currentTime;
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  useEffect(() => {
    if (isValidToken && localStorage.getItem('accessToken')?.length > 0) {
      setIsAuthenticatedLocal(isAuthenticated);
    } else if (!isValidToken) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.reload();
    } else {
      setIsAuthenticatedLocal(true);
    }
  }, [isAuthenticatedLocal]);

  return (
    <>
      <Helmet>
        <title>{t('login:login_title')}</title>
      </Helmet>
      <Box className={classes.wrapper}>
        {isAuthenticatedLocal && (
          <Grid
            container
            sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Card className={classes.card}>
              <CardContent
                sx={{
                  p: '70px 50px',
                  backgroundColor: 'transparent',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '564px',
                }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 3,
                  }}>
                  <Grid
                    container
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 3,
                    }}>
                    <Logo width={80} />
                    <Typography
                      color="textPrimary"
                      sx={{ fontSize: '1.5em !important' }}
                      // gutterBottom
                      variant="h3">
                      {t('login:login_log_into_your_account')}
                    </Typography>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 3,
                  }}>
                  {isFirstTime ? <PasswordResetAmplify /> : <LoginJWT />}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Login;
