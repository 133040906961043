import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserInfo {
  userInfos: any;
}
const initialState: UserInfo = {
  userInfos: {}
};
const userInfo = createSlice({
  name: 'userInfos',
  initialState,
  reducers: {
    userInfos: (state:any, action: PayloadAction<any>) => {
      state.userInfos = action.payload;
      return state;
    }
  }
});

export const { userInfos } = userInfo.actions;
export default userInfo.reducer;
