import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';

interface ApproveDialogProps {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
}

const ApproveDialog: React.FC<ApproveDialogProps> = ({
  open,
  onClose,
  onApprove,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to approve this case?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onApprove} color="primary">
        Approve
      </Button>
    </DialogActions>
  </Dialog>
);
export default ApproveDialog;
