const DashboardIcon = ({ color = '#A0AEC0' }: { color: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 12.4999V5.99992H2.5V12.4999C2.5 12.7761 2.72386 12.9999 3 12.9999H12C12.2761 12.9999 12.5 12.7761 12.5 12.4999V5.99992H13.5V12.4999C13.5 13.3284 12.8284 13.9999 12 13.9999H3C2.17157 13.9999 1.5 13.3284 1.5 12.4999Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 1.49992V4.99992L10.5 2.99992V1.49992C10.5 1.22378 10.7239 0.999925 11 0.999925H12C12.2761 0.999925 12.5 1.22378 12.5 1.49992Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79289 0.499925C7.18342 0.109399 7.81658 0.109401 8.20711 0.499925L14.8536 7.14637C15.0488 7.34163 15.0488 7.65822 14.8536 7.85348C14.6583 8.04874 14.3417 8.04874 14.1464 7.85348L7.5 1.20703L0.853553 7.85348C0.658291 8.04874 0.341709 8.04874 0.146447 7.85348C-0.0488155 7.65822 -0.0488155 7.34163 0.146447 7.14637L6.79289 0.499925Z"
      fill={color}
    />
  </svg>
);

export default DashboardIcon;
