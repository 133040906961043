import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    showPopup: false,
    message: '',
    caseKey: '',
    linkToCopy: '',
  },
  reducers: {
    openSnackbar: (state:any, action: PayloadAction<any>) => {
      state.showPopup = true;
      state.message = action.payload.message;
      state.caseKey = action.payload.caseKey || '';
      state.linkToCopy = action.payload.linkToCopy || '';
    },
    closeSnackbar: (state:any) => {
      state.showPopup = false;
      state.message = '';
      state.caseKey = null;
      state.linkToCopy = '';
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackBarSlice.actions;

export default snackBarSlice.reducer;
