import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    btn: {
      width: '160px',
      height: 30,
      borderRadius: '4px',
      textTransform: 'capitalize',
      border: `2px solid ${theme.palette.text.secondary}`,
      transition: 'all 0.3s linear',
    },
    btnSecondary: {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.secondary.main}`,

      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    btnPrimary: {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.secondary.main}`,

      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    btnDisabled: {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
      borderColor: theme.palette.action.disabled,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: theme.palette.action.disabledBackground,
        borderColor: theme.palette.action.disabled,
      },
    },
  })
);

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  value?: string | number;
  color?: string;
  customClass?: string;
  onClick?: any;
  disabled?: boolean;
}

const CustomButton = ({
  value = 'Button',
  color,
  onClick = () => {},
  type,
  customClass,
  children,
  disabled = false,
}: Props) => {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.btn} ${
        color === 'secondary' ? classes.btnSecondary : classes.btnPrimary
      } ${disabled ? classes.btnDisabled : ''} ${customClass || ''}`}
      type={type}
      onClick={onClick}
      disabled={disabled}>
      {value || children || ''}
    </Button>
  );
};

export default CustomButton;
