const convertSecondsToTimeString = (seconds) => {
  if (seconds === 0 || seconds === null) return '0m';

  const isNegative = seconds < 0;
  const absoluteSeconds = Math.abs(seconds);

  const hours = Math.floor(absoluteSeconds / 3600);
  const minutes = Math.floor((absoluteSeconds % 3600) / 60);

  const hoursPart = hours > 0 ? `${hours}h ` : '';
  const minutesPart = minutes > 0 || hours === 0 ? `${minutes}m` : '';

  const result = `${isNegative ? '-' : ''}${hoursPart}${minutesPart}`;

  return result.trim();
};

export default convertSecondsToTimeString;
